import { ExternalPost } from 'graphql/generated/graphql';
import {
  IPostAction,
  IUserDetails,
  UserDetailsLocations,
  IComment,
  IPrice,
  ImageComposition,
  TextBackground,
  ICreatePostInitialValues,
  IPostMessageDetails,
  EventSources,
  ProductPageSources,
  IFeedBubble,
} from 'types';

export * from './header/types';

export * from './footer/types';

export * from './comments/types';

export interface ISubPost {
  id: string;
  actionId: string;
  totalLikes: number;
  totalComments: number;
  isLiked: boolean;
  isCommentsExpanded?: boolean;
  selfComments?: Array<IComment>;
  lastComment?: IComment;
  comments: { list: Array<IComment> | null; hasMore: boolean };
  likes: { list: Array<IUserDetails> | null; hasMore: boolean };
}

export interface IPost {
  actionId: string;
  action: IPostAction | null;
  isFollowing: boolean;
  userDetails: IUserDetails;
  text: string;
  images: string[];
  imageComposition: ImageComposition;
  mojoDetails: { thumbnailUrl: string; videoUrl: string } | null;
  isLiked: boolean;
  isReposted: boolean;
  totalComments: number;
  totalLikes: number;
  totalReposts: number;
  id: string;
  repostDetails: IPost | null;
  shopId: string | null;
  productDetails: IPostProductDetails | null;
  lastComment: IComment | null;
  selfComments: IComment[] | null;
  isCommentsExpanded: boolean;
  isEditable: boolean;
  lastEdited: number | null;
  likes: { list: IUserDetails[] | null; hasMore: boolean };
  comments: { list: IComment[] | null; hasMore: boolean };
  totalFollowers?: number;
  totalFollowing?: number;
  backgroundIndex?: TextBackground;
  taggedUsers: ITaggedUser[];
  shouldDisplayHideFromActivity: boolean;
  isShoppingPost: boolean;
  shouldHideDate: boolean;
  externalPost: ExternalPost | null;
}

export interface IPostProductDetails {
  id: string;
  title: string;
  image: string;
  price: IPrice;
}

export interface ITaggedUser {
  userId: string;
  username: string;
}

export interface IPostProps {
  post: IPost;
  index?: number;
  source: EventSources | null;
  type: UserDetailsLocations;
  textMaxChars: number;
  shouldDisplayComments: boolean;
  shouldDisplayInputByDefault: boolean;
  shouldHidePremium: boolean;
  shouldHideFollowButton: boolean;
  shouldDisplayImages: boolean;
  loggedInAvatar: string | null;
  loggedInUserId: string | null;
  isClickable: boolean;
  isSpecificPost: boolean;
  isPostInPopup: boolean;
  title?: string;
  updateProductPageSource(input: { source: ProductPageSources }): void;
  showPopup(input: {
    id: string;
    createPostInitialValues?: ICreatePostInitialValues;
    postDetails?: IPostMessageDetails;
    postId?: string;
    mojoId?: string;
    actionId?: string;
    source?: string;
  }): void;
  onDeletePost(): void;
  onPostImpression(input: { id: string }): void;
  onCommentDelete(commentId: string, replyId?: string): void;
  onFollowToggle(): void;
  onReply(commentId: string, text: string, taggedUsers: ITaggedUser[], images: Array<string>): void;
  onCommentsLoad(): void;
  onCommentSubmit(comment: string, taggedUsers: ITaggedUser[], attachedImages: Array<string>): void;
  onLikeComment(commentId: string, isLiked: boolean): void;
  onLikeClick(): void;
  onRepliesLoad(commentId: string): void;
  onLikesClick(): void;
  onRepostsClick(): void;
  onShowRepostsClick(): void;
  onImageClick(index: number): void;
  onPostHide(input: { id: string }): void;
  onPostReport(): void;
  onPostHideFromActivity(input: { id: string }): void;
  onCommentLikesClick(commentId: string, replyId?: string): void;
  onToggleRepliesClick(commentId: string): void;
  onFilterChange?: (filter: IFeedBubble) => void;
}

export interface IDeletePostPopup {
  onDelete(): void;
}

export interface IRepostOptionsPopup {
  isReposted: boolean;
  postId: string;
  actionId: string;
  repostDetails: IPost | null;
  loggedInUsername: string;
  showPopup(input: { id: string; createPostInitialValues: { repostDetails: IPost } }): void;
  hide(): void;
  onRepostClick(input: {
    postId: string;
    actionId: string;
    isReposted: boolean;
    loggedInUsername: string;
  }): void;
}

export enum RepostOptions {
  Repost,
  RepostWithThoughts,
  UndoRepost,
}

export interface IFollowButton {
  isFollowing: boolean;
  isFullWidth?: boolean;
  type?: 'primary' | 'secondary';
  onFollowToggle(e?: any): void;
}
