import { FC } from 'react';
import './FollowChannelSkeleton.scss';
import { Skeleton } from 'tedooo-web-design-system/ui';

const FollowChannelSkeleton: FC = () => {
  return (
    <div className="follow-channel-skeleton">
      <div className="bottom-section">
        <Skeleton avatar={{ shape: 'circle', size: 38 }} title={{ width: 0 }} rows={0} />
        <Skeleton avatar={false} title={false} rows={2} />
      </div>
    </div>
  );
};

export default FollowChannelSkeleton;
