import './premiumTag.scss';
import { FC } from 'react';
import classNames from 'classnames';

import PremiumIcon from 'photos/premium.png';
import { Image } from '../helpers';
import { translations } from 'translations/en';
import { BadgeSize } from 'components/post/header/userDetails/badges/UserDetailsBadges';

interface IPremiumTag {
  size: BadgeSize;
}

const PremiumTag: FC<IPremiumTag> = ({ size }) => {
  return (
    <div className={classNames('premium-tag-container', size)}>
      <Image src={PremiumIcon} className="icon" />
      <span className="text">{translations.COMMON.PRO}</span>
    </div>
  );
};

export default PremiumTag;
