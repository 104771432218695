import { gql } from '@apollo/client';

export const GET_BASKET_QUERY = gql`
  query GetCart {
    getCart {
      shops {
        shopId
        shopName
        checkoutId
        hasInstantCheckout
        user {
          id
          username
          fullName
          avatar
        }
        products {
          cartItemId
          productId
          status
          title
          quantity
          image
          originalPrice
          price {
            price
            currencyCode
            currency
          }
          convertedFrom {
            price
            currencyCode
            currency
          }
          customization {
            text
            images
          }
        }
      }
    }
  }
`;

export const GET_TOTAL_BASKET_ITEMS_QUERY = gql`
  query GetShippingRateForCheckout {
    getCartCount {
      totalItems
    }
  }
`;

export const GET_SHIPPING_RATE_FOR_BASKET_QUERY = gql`
  query getShippingRateForCheckout($checkoutId: String!) {
    getShippingRateForCheckout(checkoutId: $checkoutId) {
      price {
        price
        currency
        currencyCode
      }
    }
  }
`;

export const GET_BASKET_SHIPPING_RATES_QUERY = gql`
  query GetInstantCheckoutForCheckoutId($checkoutId: String!) {
    getInstantCheckoutForCheckoutId(checkoutId: $checkoutId) {
      rates {
        rateId
        tags
        image
        name
        price {
          price
          currencyCode
          currency
        }
        arrivalDate
      }
    }
  }
`;

export const ADD_TO_BASKET_MUTATION = gql`
  mutation AddToCart($input: AddToCartRequest!) {
    addToCart(request: $input) {
      id
    }
  }
`;

export const REMOVE_FROM_BASKET_MUTATION = gql`
  mutation RemoveFromCart($cartItemId: String!) {
    removeFromCart(cartItemId: $cartItemId) {
      success
    }
  }
`;

export const UPDATE_ITEM_CUSTOMIZATION_NOTE_MUTATION = gql`
  mutation EditCustomizationByCartItemId(
    $cartItemId: String!
    $customization: CustomizationInput!
  ) {
    editCustomizationByCartItemId(cartItemId: $cartItemId, customization: $customization) {
      success
    }
  }
`;

export const REMOVE_UNAVAILABLE_ITEMS_MUTATION = gql`
  mutation RemoveUnavailableFromCart($shopId: String!, $addToWishlist: Boolean!) {
    removeUnavailableFromCart(shopId: $shopId, addToWishlist: $addToWishlist) {
      success
    }
  }
`;

export const GENERATE_CHECKOUT_ID_MUTATION = gql`
  mutation GenerateCheckoutIdForProduct(
    $productId: String!
    $quantity: Int!
    $customization: CustomizationInput
  ) {
    generateCheckoutIdForProduct(
      productId: $productId
      quantity: $quantity
      customization: $customization
    ) {
      id
    }
  }
`;
