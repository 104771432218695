import './feedBubblesCarousel.scss';
import { FC } from 'react';
import classNames from 'classnames';

import { MultiItemsCarousel } from 'components/carousels';
import { FeedBubblesCarouselSettings } from './FeedBubblesCarouselSettings';
import { IFeedBubble } from 'store/feed/types';
import FeedBubblesCarouselSkeleton from './feedBubblesCarouselSkeleton/FeedBubblesCarouselSkeleton';

export interface IFeedBubblesCarousel {
  selectedFeedBubble: IFeedBubble;
  feedBubbles: IFeedBubble[] | null;
  onFeedBubbleClick(input: IFeedBubble): void;
  isLoading: Boolean;
}

const FeedBubblesCarousel: FC<IFeedBubblesCarousel> = ({
  feedBubbles,
  onFeedBubbleClick,
  selectedFeedBubble,
  isLoading,
}) => {
  const renderContent = () => {
    if (!feedBubbles) {
      return <FeedBubblesCarouselSkeleton />;
    }

    return (
      <MultiItemsCarousel settings={FeedBubblesCarouselSettings} responsiveArrows>
        {feedBubbles.map((bubble) => {
          return (
            <div
              className={classNames('bubble-container', {
                'is-selected': bubble.id === selectedFeedBubble.id,
                'is-loading': isLoading,
              })}
              onClick={() => onFeedBubbleClick({ id: bubble.id, text: bubble.text })}
              key={bubble.id}
            >
              {bubble.text}
            </div>
          );
        })}
      </MultiItemsCarousel>
    );
  };

  return <div className="feed-bubbles-carousel-section">{renderContent()}</div>;
};

export default FeedBubblesCarousel;
