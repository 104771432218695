import {
  DiscoverListsId,
  IFeedApi,
  IShopBaseInfoResponse,
  IShopShortInfoResponse,
  IUserInfoResponse,
  Method,
} from 'types';
import { fetcher } from 'api';
import { GET_MAIN_FEED_QUERY, GET_FILTERED_FEED_PAGE_QUERY, GET_FEED_QUERY } from './FeedQueries';
import {
  DiscoverPageResult,
  FilteredFeedResponse,
  Query,
  TedoooPageResponse,
} from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';

export class FeedReducer implements IFeedApi {
  async getPostsByListsId(
    listId: DiscoverListsId,
    paginationToken: string | null,
    token?: string,
  ): Promise<DiscoverPageResult> {
    const variables = {
      paginationToken,
      listId,
    };

    const res = await graphQlRequest(token).query<Query>({
      query: GET_FEED_QUERY,
      variables,
    });

    return res.data.getDiscoverPageData;
  }

  async getPosts(paginationToken: string | null, token?: string): Promise<TedoooPageResponse> {
    const variables = {
      paginationToken,
      screenWidth: 1920,
    };

    const res = await graphQlRequest(token).query<Query>({
      query: GET_MAIN_FEED_QUERY,
      variables,
    });

    return res.data.getFeedPage;
  }

  async getFilteredPosts(
    paginationToken: string | null,
    token?: string,
  ): Promise<FilteredFeedResponse> {
    const variables = {
      paginationToken,
      screenWidth: 1920,
    };

    const res = await graphQlRequest(token).query<Query>({
      query: GET_FILTERED_FEED_PAGE_QUERY,
      variables,
    });

    return res.data.getFilteredFeedPage2;
  }

  getStories(token: string): Promise<any> {
    return fetcher('feed/extended', Method.GET, token);
  }

  getShops(token: string): Promise<IShopShortInfoResponse[]> {
    return fetcher('myshops/me', Method.GET, token);
  }

  getShopProfile(shopId: string, token: string): Promise<IShopBaseInfoResponse> {
    return fetcher(`v2/shops/base/${shopId}`, Method.GET, token);
  }

  getUserProfile(userId: string, token: string): Promise<IUserInfoResponse> {
    return fetcher(`v2/userprofile/${userId}`, Method.GET, token);
  }
}
