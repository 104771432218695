import {
  IFeedResponseItemApi,
  IPost,
  IProductLabel,
  IShopResponseApi,
  IShopsResponseApi,
} from 'types';
import { CurrencyCode } from 'constant/currencies';

export interface IFilterState {
  minRate: number;
  countries: Array<string>;
  companyTypes: Array<string>;
  sortBy: { type: string; direction: Directions };
  search: string;
  tab: Tabs;
}

export enum PageOptions {
  history = 'history',
  search = 'search',
  following = 'following',
  myBusinesses = 'myBusinesses',
  notes = 'notes',
  similarShops = 'similarshops',
  user = 'user',
}

export interface ISearchResultState {
  userId: string | null;
  peek: IPeekInSearchResults | null;
  postsTotalCount: number;
  groups: { list: IGroupInSearchResults[] | null; totalCount: number; offset: number };
  users: { list: IUserInSearchResults[] | null; totalCount: number; offset: number };
  products: { list: IProductsInSearchResults[] | null; totalCount: number; offset: number };
  shops: { list: IShopInSearchResults[] | null; totalCount: number; offset: number };
  hasMore: boolean;
  isTabLoading: boolean;
  productIndexToScroll: number;
  filters: IFilterState;
}

export interface IPeekInSearchResults {
  shops: IShopInSearchResults[];
  groups: IGroupInSearchResults[];
  posts: IPost[];
  products: IProductsInSearchResults[];
  users: IUserInSearchResults[];
}

export interface IShopInSearchResults {
  rating: number;
  avatar: string;
  username: string;
  category: string;
  description: string;
  foundedCountry: string;
  images: Array<string>;
  name: string;
  isPremium: boolean;
  shopNumber: string;
  totalReviews: number;
  type: number;
  id: string;
  userId: string;
  isFollowing: boolean;
  isMarkedAsCrystalShop: boolean;
  isVisionSeller: boolean;
}

export interface IGroupItemDetails {
  id: string;
  name: string;
  avatar?: string | null;
  description?: string;
  participants: number;
}

export interface IGroupInSearchResults {
  id: string;
  name: string;
  avatar?: string | null;
  description?: string;
  participants: number;
  isMember: boolean;
}

export interface IUserInSearchResults {
  id: string;
  fullName: string;
  avatar: string;
  shopName: string | null;
  username: string;
  isPremium: boolean;
  isFollowing: boolean;
  isVisionSeller: boolean;
}

export interface IProductsInSearchResults {
  id: string;
  currencyCode: CurrencyCode;
  image: string;
  price: number;
  originalPrice: number;
  title: string;
  totalLikes: number;
  isLiked: boolean;
  isDigital: boolean;
  labels: IProductLabel[];
  userId: string;
}

export interface IShopSearchResultResponse extends IShopResponseApi {
  images: string[];
  id: string;
  avatar: string;
  username: string;
  rating: number;
  following: boolean;
}

export interface IGroupsSearchResultResponse {
  _id: string;
  groupName: string;
  avatar?: string;
  description?: string;
  participants: number;
  pending?: boolean;
  isMember?: boolean;
}

export interface IProductsSearchResultResponse {
  id: string;
  currency: string;
  currencyCode: CurrencyCode;
  images: string[];
  price: number;
  isDigital: boolean;
  originalPrice?: number;
  title: string;
  description: string;
  isCustomizable: boolean;
  totalLikes: number;
  userId: string;
  didLike: boolean;
  labels?: {
    backgroundColor: string;
    textColor: string;
    texts: { text: string; style: string }[];
  }[];
}

export interface IUserSearchResultResponse {
  id: string;
  username: string;
  fullName: string;
  avatar?: string;
  shopName?: string;
  premium: boolean;
  isFollowing: boolean;
  isVisionSeller: boolean;
}

export interface ISearchResultApi {
  getStores(
    token: string,
    offset: number,
    filters: IFilterState,
    page: PageOptions,
    searchType?: SearchTypes,
  ): Promise<IShopsResponseApi>;
  getProducts(offset: number, search: string, token?: string): Promise<any>;
  getPosts(token: string, offset: number, search: string): Promise<any>;
  getGroups(token: string, offset: number, search: string): Promise<any>;
  getGroups(token: string, offset: number, search: string): Promise<any>;
  requestJoinGroup(groupId: string, token: string): Promise<void>;
  getSimilarShops(
    token: string,
    offset: number,
    filters: IFilterState,
    shopId: string,
  ): Promise<IShopsResponseApi>;
  getAllSearchResults(filters: IFilterState, token?: string): Promise<IGetAllSearchResultsResponse>;
  superAdminDeleteProduct(productId: string, token: string): Promise<void>;
  superAdminMarkAsCrystalShop(token: string, shopId: string): Promise<void>;
}

export interface IGetAllSearchResultsResponse {
  shops: IShopSearchResultResponse[];
  groups: IGroupsSearchResultResponse[];
  products: IProductsSearchResultResponse[];
  posts: IFeedResponseItemApi[];
  users: IUserSearchResultResponse[];
}

export enum Tabs {
  All = 'top',
  Items = 'items',
  Shops = 'shops',
  Users = 'users',
  Posts = 'posts',
  Groups = 'groups',
}

export enum Sort {
  Recommended,
  Rating,
  Reviewed,
}

export enum SearchTypes {
  Shops,
  Items,
  Posts,
  Groups,
}

export enum Directions {
  ASC,
  DESC,
}
