import './visionSellerBadge.scss';
import { FC } from 'react';
import classNames from 'classnames';
import { MagicWandIcon } from 'tedooo-web-design-system/icons';

import { translations } from 'translations/en';
import { BadgeSize } from 'components/post/header/userDetails/badges/UserDetailsBadges';

interface IUserDetailsBadges {
  size: BadgeSize;
}

const VisionSellerBadge: FC<IUserDetailsBadges> = ({ size }) => {
  return (
    <div className={classNames('vision-seller-badge-container', size)}>
      <MagicWandIcon className="icon" />
      <span className="text">{translations.COMMON.VISION_SELLER}</span>
    </div>
  );
};

export default VisionSellerBadge;
