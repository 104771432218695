import { createAsyncThunk } from '@reduxjs/toolkit';

import { BasketApi } from './BasketApi';
import {
  DiscoverListsId,
  IAddToBasketDetails,
  ICartItem,
  ICheckoutItem,
  ICustomizationRequest,
  IStore2,
} from 'types';
import { ProductsApi } from 'store/products/ProductsApi';

const api = new BasketApi();

const productsApi = new ProductsApi();

export const getBasket = createAsyncThunk('async/getBasket', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token = '' } = state.user;

  return api.getBasket(token);
});

export const getSavedItemsForBasket = createAsyncThunk(
  'async/getSavedItemsForBasket',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return productsApi.getAllProducts(DiscoverListsId.Favorites, undefined, token);
  },
);

export const onAddItemToBasket = createAsyncThunk(
  'async/onAddItemToBasket',
  async (input: { details: IAddToBasketDetails }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id, quantity, customizationRequest } = input.details;

    return api.addItemToBasket(token, id, quantity, customizationRequest);
  },
);

export const onRemoveItemFromBasket = createAsyncThunk(
  'async/onRemoveItemFromBasket',
  async (
    input: { item: ICartItem; checkoutId: string; shouldFavorite: boolean },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { cartItemId } = input.item;

    return api.removeItemFromBasket(token, cartItemId);
  },
);

export const onUpdateItemRequest = createAsyncThunk(
  'async/onUpdateItemRequest',
  async (
    input: { cartItemId: string; customizationRequest: ICustomizationRequest },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { cartItemId, customizationRequest } = input;

    return api.updateCustomizationRequest(token, cartItemId, customizationRequest);
  },
);

export const getBasketSupportedItems = createAsyncThunk(
  'async/getBasketSupportedItems',
  async (input: { ids: string[] }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { ids } = input;

    return api.getBasketSupportedItems(token, ids);
  },
);

export const getShopBasketShippingRate = createAsyncThunk(
  'async/getShopBasketShippingRate',
  async (input: { checkoutId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { checkoutId } = input;

    return api.getShopBasketShippingRate(token, checkoutId);
  },
);

export const getBasketShippingRates = createAsyncThunk(
  'async/getShopBasketShippingRates',
  async (input: { checkoutId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { checkoutId } = input;

    return api.getBasketShippingRates(token, checkoutId);
  },
);

export const getTotalBasketItems = createAsyncThunk(
  'async/getTotalBasketItems',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getTotalBasketItems(token);
  },
);

export const removeUnavailableItems = createAsyncThunk(
  'async/removeUnavailableItems',
  async (input: { shopId: string; shouldAddToFavorites: boolean }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { shouldAddToFavorites, shopId } = input;

    return api.removeUnavailableItems(token, shopId, shouldAddToFavorites);
  },
);

export const getCheckoutIdForProduct = createAsyncThunk(
  'async/getCheckoutIdForProduct',
  async (
    input: {
      item: ICheckoutItem;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id, quantity, customizationRequest } = input.item;

    return api.getCheckoutIdForProduct(token, id, quantity, customizationRequest);
  },
);
