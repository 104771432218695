import { FC, useState } from 'react';
import { MoreHorizontal } from 'tedooo-web-design-system/icons';
import classNames from 'classnames';

import TedoooLogo from 'photos/tedooologosquare.png';
import {
  IUserDetailsProps,
  mapUserDetailsLocationToAvatarSize,
  mapUserDetailsLocationToPremiumTagSize,
  MenuOptions,
  OptionsMenuList,
} from 'types';
import { Avatar } from 'components/avatar/Avatar';
import { Image, Span } from 'components/helpers';
import './userDetails.scss';
import FollowButton from 'components/post/followButton/FollowButton';
import DropdownOptionsMenu from 'components/dropdownOptionsMenu/DropdownOptionsMenu';
import UserDetailsBadges from './badges/UserDetailsBadges';
import PostSubText from './PostSubText';
import { translations } from 'translations/en';

const UserDetails: FC<IUserDetailsProps> = ({
  avatar,
  username,
  isPremium,
  fullName,
  userId,
  shopName,
  date,
  shouldDisplayOptionMenu,
  isFollowing,
  onOptionMenuClick,
  type,
  onFollowToggle,
  isPostOwner,
  shopId,
  shopImage,
  shouldHideFollowButton,
  shouldDisplayHideFromActivity,
  onUserClick,
  isEditablePost,
  isPostEdited,
  shouldHideDate,
  isVisionSeller,
  externalPost,
  onFilterChange,
}) => {
  const [shouldDisplayFollowButton] = useState(
    !isFollowing && !isPostOwner && !shouldHideFollowButton,
  );

  let avatarSize = mapUserDetailsLocationToAvatarSize[type];
  if (shopId) {
    avatarSize = 26;
  }

  const premiumTagSize = mapUserDetailsLocationToPremiumTagSize[type];

  const onTitleClick = (e: any) => {
    e.stopPropagation();

    if (externalPost) return;

    onUserClick(userId);
  };

  const onFollowClick = (e: any) => {
    e.stopPropagation();
    onFollowToggle();
  };

  const onProfileImagesClick = (e: any) => {
    e.stopPropagation();
  };

  const onDropdownClick = (e: any) => {
    e.stopPropagation();
  };

  const title = externalPost ? translations.FEED.POST.TRENDING_POST : shopId ? shopName : fullName;

  const dropdownOptions = OptionsMenuList.filter(
    (option) =>
      (option.key !== MenuOptions.Edit || (isEditablePost && isPostOwner)) &&
      (option.key !== MenuOptions.Hide || !isPostOwner) &&
      (option.key !== MenuOptions.Delete || isPostOwner) &&
      (option.key !== MenuOptions.HideFromActivity || shouldDisplayHideFromActivity) &&
      (option.key !== MenuOptions.Report || !isPostOwner),
  );

  return (
    <div className="post-header-container">
      <div className="user-details-and-badges">
        <div className="user-details-container2">
          {!!shopId && (
            <div className="images-container" onClick={onProfileImagesClick}>
              <Image src={shopImage || TedoooLogo} className="shop-image" />
              <Avatar src={avatar} size={avatarSize} />
            </div>
          )}
          {!shopId && <Avatar src={avatar} size={44} />}

          <div className="text-container">
            <div className="text-header">
              <div className="title-container">
                <Span
                  className={classNames('title-text', {
                    'dissable-link': externalPost,
                  })}
                  text={title}
                  onClick={onTitleClick}
                />
                {shouldDisplayFollowButton && (
                  <>
                    <div className="dot" />
                    <FollowButton isFollowing={isFollowing} onFollowToggle={onFollowClick} />
                  </>
                )}
              </div>
            </div>

            <PostSubText
              username={username}
              fullName={fullName}
              shopId={shopId}
              isPostEdited={isPostEdited}
              shouldHideDate={shouldHideDate}
              date={date}
              externalPost={externalPost}
              onFilterChange={onFilterChange}
            />
          </div>
        </div>
        <UserDetailsBadges
          size={premiumTagSize}
          isVisionSeller={isVisionSeller}
          isPremium={isPremium}
        />
      </div>

      <div className="user-details-button" onClick={onDropdownClick}>
        {shouldDisplayOptionMenu && (
          <DropdownOptionsMenu onOptionClick={onOptionMenuClick} options={dropdownOptions}>
            <MoreHorizontal className="dropdown-button" />
          </DropdownOptionsMenu>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
