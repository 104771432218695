import { FC, useCallback } from 'react';
import './comments.scss';
import classNames from 'classnames';

import { EmptyStateOptions, IComment, IComments } from 'types';
import CommentWrapper from './commentWrapper/CommentWrapper';
import { Span } from 'components/helpers';
import { useTedoooSelector } from 'store/Store';
import { UserSelector } from 'store/user/UserSelector';
import { isEmptyArray } from 'utils';
import { EmptyState } from '../../emptyState';

const Comments: FC<IComments> = ({
  comments,
  isExpanded,
  onReply,
  hasMore,
  onLoadMore,
  onRepliesLoad,
  onCommentDelete,
  onUserClick,
  onViewMoreCommentsClick,
  totalComments,
  postOwnerId,
  shouldDisplayEmptyState,
  onCommentLike,
  onCommentLikesClick,
  onToggleRepliesClick,
}) => {
  const shouldDisplayMoreButton = hasMore && isExpanded;

  const loggedInUserAvatar = useTedoooSelector(UserSelector.defaultAvatarSelector);

  const loggedInUserId = useTedoooSelector(UserSelector.loggedUserIdSelector);

  const totalCommentsRemainingToLoad = totalComments - comments.length;

  const shouldShowViewMore = !isExpanded && totalCommentsRemainingToLoad > 0;

  const onRepliesClick = (commentId: string, totalReplies: number, replies: IComment[]) => {
    if (totalReplies > replies.length) {
      onRepliesLoad(commentId);
    } else {
      onToggleRepliesClick(commentId);
    }
  };

  const itemRenderer = useCallback(
    (comment: IComment, index: number) => {
      const isPostOwner = comment.userId === postOwnerId;

      return (
        <CommentWrapper
          taggedUsers={comment.taggedUsers}
          postOwnerId={postOwnerId}
          onUserClick={onUserClick}
          loggedInUserId={loggedInUserId}
          loggedInUserAvatar={loggedInUserAvatar}
          isDeleted={comment.isDeleted}
          onCommentDelete={onCommentDelete}
          userId={comment.userId}
          onRepliesClick={() => onRepliesClick(comment.id, comment.totalReplies, comment.replies)}
          onReply={onReply}
          image={comment.image}
          id={comment.id}
          key={`${comment.id}-${index}`}
          replies={comment.replies}
          isRepliesExpanded={comment.isRepliesExpanded}
          avatar={comment.avatar}
          fullName={comment.fullName}
          username={comment.username}
          isPremium={comment.isPremium}
          totalLikes={comment.totalLikes}
          likes={comment.likes}
          isLiked={comment.isLiked}
          date={comment.date}
          text={comment.text}
          totalReplies={comment.totalReplies}
          isPostOwner={isPostOwner}
          onCommentsLike={onCommentLike}
          onCommentLikesClick={onCommentLikesClick}
          isVisionSeller={comment.isVisionSeller}
        />
      );
    },
    [comments],
  );

  if (isEmptyArray(comments) && shouldDisplayEmptyState) {
    return <EmptyState type={EmptyStateOptions.Comments} />;
  }

  return (
    <div className={classNames('comments-container', { 'no-comments': isEmptyArray(comments) })}>
      {shouldShowViewMore && (
        <Span
          text={`View ${totalCommentsRemainingToLoad} previous comments`}
          className="view-comments-text"
          onClick={onViewMoreCommentsClick}
        />
      )}
      {comments.map((comment, index) => itemRenderer(comment, index))}
      {shouldDisplayMoreButton && (
        <Span
          onClick={onLoadMore}
          className="load-more"
          text={`View ${totalCommentsRemainingToLoad} more comments`}
        />
      )}
    </div>
  );
};

export default Comments;
