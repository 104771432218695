import './feed.scss';
import { connect } from 'react-redux';
import { FC, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  DiscoverListsId,
  EmptyStateOptions,
  FeedTypes,
  IFeed,
  IStore2,
  EventSources,
  IFeedBubble,
} from 'types';
import { FILTER_PARAMS, ROUTES } from 'constant';
import { FeedSelector } from 'store/feed/FeedSelectors';
import { EmptyState } from 'components/emptyState';
import { UserSelector } from 'store/user/UserSelector';
import {
  getSuggestedShops as getSuggested,
  onFollowUserToggle,
} from 'store/profile/ProfileActions';
import { PopupSlicer } from 'store/popup/PopupActions';
import StickyLayout from 'components/stickyLayout/StickyLayout';
import { FeedSlicer } from 'store/feed/FeedSlicer';
import Tip from 'components/tip/Tip';
import { CreateShopSlicer } from 'store/createShop/CreateShopSlicer';
import { CreateShopSelector } from 'store/createShop/CreateShopSelector';
import { onProductLikeToggle } from 'store/specificProduct/SpecificProductActions';
import { getAllProducts } from 'store/products/ProductsActions';
import ProfileSideBar from './profileSideBar/ProfileSideBar';
import { getShopOfTheDay as getShopOfTheDayAction } from 'store/discover/DiscoverActions';
import { ConfigSelector } from 'store/config/ConfigSelector';
import Posts from './Posts';
import NonSignInSidebar from './nonSignInSidebar/NonSignInSidebar';
import { getMainFeed as getFeed } from 'store/feed/FeedActions';
import { ALL } from './feedBubblesCarousel/helper';

const Feed: FC<IFeed> = ({
  avatar,
  fullName,
  token,
  profile,
  shopOfTheDay,
  premiumStatus,
  shouldShowVisions,
  getSuggestedShops,
  suggestedShops,
  savedProducts,
  loggedInUserId,
  totalCompletedDraftSteps,
  shouldShowDraft,
  onFollow,
  showPopup,
  getPosts,
  isCreatePostLoading,
  postsDetails,
  tip,
  isPremium,
  updateFeedType,
  getSavedProducts,
  onHideDraft,
  onSaveProduct,
  getShopOfTheDay,
  resetFeed,
  shouldDisplayLegacyPremium,
}) => {
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const [searchParams] = useSearchParams();

  const selectedFilterId = searchParams.get(FILTER_PARAMS.FILTER) || ALL;

  const selectedFilterText = searchParams.get(FILTER_PARAMS.BUBBLE_FILTER_TEXT) || '';

  const selectedBubbleFromLink: IFeedBubble = {
    id: selectedFilterId,
    text: selectedFilterText,
  };

  useEffect(() => {
    resetFeed();

    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, [selectedFilterId]);

  const discussionToken = searchParams.get(FILTER_PARAMS.DISCUSSION_TOKEN)?.trim();

  const extraInformation = profile?.extraInformation || fullName || '';

  const getMainFeed = (paginationToken?: string) => {
    getPosts({
      token: token ?? undefined,
      selectedFeedBubble: selectedBubbleFromLink,
      discussionToken: paginationToken,
    });
  };

  const getMainFeedWithoutToken = () => {
    if (!token) {
      getPosts({
        selectedFeedBubble: selectedBubbleFromLink,
      });
    }
  };

  useEffect(() => {
    if (!postsDetails.list && token !== null) {
      getMainFeed(discussionToken);
    }
  }, [postsDetails.list, token]);

  useEffect(() => {
    updateFeedType({ type: FeedTypes.Feed });

    if (token) {
      if (!suggestedShops) {
        getSuggestedShops(loggedInUserId);
      }
      if (!savedProducts) {
        getSavedProducts({ type: DiscoverListsId.Favorites, paginationToken: null });
      }
      if (!shopOfTheDay) {
        getShopOfTheDay();
      }
    } else if (!postsDetails.list && !token) {
      getMainFeedWithoutToken();
    }

    return () => {
      resetFeed();
    };
  }, []);

  const emptyState = <EmptyState type={EmptyStateOptions.Feed} />;

  const onHideDraftFromFeed = () => {
    if (loggedInUserId) {
      onHideDraft({ userId: loggedInUserId });
    }
  };

  const onUserClick = () => {
    if (loggedInUserId) {
      navigate(`/${ROUTES.USER}/${loggedInUserId}`);
    }
  };

  return (
    <div className="page feed-layout" id="feed-container" ref={ref}>
      <StickyLayout topOffset={24} parentScrollerId="content">
        <StickyLayout.Content>
          {tip && <Tip tip={tip} />}
          <Posts
            posts={postsDetails.list}
            hasMore={postsDetails.hasMore}
            shouldDisplayFeedBubbles={!!token}
            isLoading={postsDetails.isLoading}
            mojoSource={EventSources.Feed}
            getFeed={getMainFeed}
            scrollerId="content"
            selectedChatId={null}
            emptyState={emptyState}
            isCreatePostLoading={isCreatePostLoading}
            selectedFeedBubble={selectedBubbleFromLink}
          />
        </StickyLayout.Content>
        <StickyLayout.SideMenu>
          {profile && (
            <ProfileSideBar
              onFollow={onFollow}
              onUserClick={onUserClick}
              avatar={avatar}
              extraInformation={extraInformation}
              savedProducts={savedProducts}
              shopOfTheDay={shopOfTheDay}
              shouldShowVisions={shouldShowVisions}
              coverPhoto={profile.coverPhoto}
              name={profile.name}
              totalFollowers={profile.totalFollowers}
              totalFollowing={profile.totalFollowing}
              shouldShowDraft={shouldShowDraft}
              totalCompletedDraftSteps={totalCompletedDraftSteps}
              suggestedShops={suggestedShops}
              isPremium={isPremium}
              premiumStatus={premiumStatus}
              showPopup={showPopup}
              onSaveProduct={onSaveProduct}
              onHideDraftFromFeed={onHideDraftFromFeed}
              shouldDisplayLegacyPremium={shouldDisplayLegacyPremium}
            />
          )}
          {!token && <NonSignInSidebar />}
        </StickyLayout.SideMenu>
      </StickyLayout>
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const premiumDetails = UserSelector.getPremiumDetailsSelector(state);

  const { premiumStatus } = premiumDetails;

  const { loggedInUserShopId, fullName, savedProducts, isPremium } = state.user;

  const { suggestedShops } = state.profile;

  const {
    createPostDetails: { isLoading: isCreatePostLoading },
    mainFeed,
  } = state.feed;

  const { shopOfTheDay } = state.discover;

  const shouldShowDraft = !!state.createShop.draftDetails?.shouldDisplayInFeed;

  const totalCompletedDraftSteps = CreateShopSelector.totalDraftStepsCompletedSelector(state);

  const profile = FeedSelector.selectProfileSelector(state);

  const token = UserSelector.tokenSelector(state);

  const loggedInUserId = UserSelector.loggedUserIdSelector(state);

  const avatar = UserSelector.defaultAvatarSelector(state);

  const postsDetails = mainFeed;

  const tip = FeedSelector.feedTipSelector(state);

  const shouldShowVisions = ConfigSelector.shouldShowVisionsSelector(state);

  const shouldDisplayLegacyPremium = UserSelector.shouldDisplayLegacyPremiumSelector(state);

  return {
    avatar,
    profile,
    loggedInUserId,
    token,
    loggedInUserShopId,
    suggestedShops,
    fullName,
    isCreatePostLoading,
    shouldShowVisions,
    postsDetails,
    tip,
    isPremium,
    savedProducts,
    shouldShowDraft,
    totalCompletedDraftSteps,
    premiumStatus,
    shopOfTheDay,
    shouldDisplayLegacyPremium,
  };
};

const mapDispatchToProps = {
  getSuggestedShops: getSuggested,
  getSavedProducts: getAllProducts,
  updateFeedType: FeedSlicer.actions.updateSelectedFeedType,
  showPopup: PopupSlicer.actions.show,
  onHideDraft: CreateShopSlicer.actions.hideDraftFromFeed,
  getPosts: getFeed,
  onFollow: onFollowUserToggle,
  resetFeed: FeedSlicer.actions.resetMainFeed,
  onSaveProduct: onProductLikeToggle,
  getShopOfTheDay: getShopOfTheDayAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
