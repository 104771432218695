import { FC } from 'react';
import './userDetails.scss';

import { translations } from 'translations/en';
import { Span } from 'components/helpers';
import { IExternalPostSubText } from 'types';
import { SEARCH_PARAMS } from 'constant';

const ExternalPostSubText: FC<IExternalPostSubText> = ({
  fullName,
  isPostEdited,
  externalPost,
  formattedDate,
  onFilterChange,
}) => {
  const plainText = `By ${fullName} in `;

  const nicheName = externalPost.niche?.name || null;

  const handleClick = (e) => {
    e.stopPropagation();
    if (onFilterChange && nicheName && externalPost.niche?.link) {
      const { searchParams } = new URL(externalPost.niche.link);

      if (searchParams) {
        const discussionToken = searchParams.get(
          SEARCH_PARAMS.EXTERNAL_POST_LINK_PARAMS.DISCUSSION_TOKEN,
        );

        const filter = searchParams.get(SEARCH_PARAMS.EXTERNAL_POST_LINK_PARAMS.FILTER) || '';

        const title = searchParams.get(SEARCH_PARAMS.EXTERNAL_POST_LINK_PARAMS.TITLE) || '';

        onFilterChange({ id: filter, text: title }, discussionToken);
      }
    }
  };

  return (
    <div className="sub-text">
      <Span className="default-cursor" text={`${plainText}`} onClick={(e) => e.stopPropagation()} />
      <Span className="undelined" text={`${nicheName}${formattedDate}`} onClick={handleClick} />

      {isPostEdited && <span className="text">{translations.FEED.POST.EDITED}</span>}
    </div>
  );
};

export default ExternalPostSubText;
