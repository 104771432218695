import { gql } from '@apollo/client';

export const GET_ONBOARDING_CHANNELS = gql`
  query getChannelSuggestions($interests: [String!]) {
    getChannelSuggestions(interests: $interests) {
      channels {
        id
        name
        subscriberCount
        description
        image
        isPopular
        preSelect
      }
    }
  }
`;
