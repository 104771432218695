import { createSelector } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import {
  IComment,
  IFeedProfile,
  IFeedState,
  IFeedStore,
  ImageComposition,
  IPost,
  IStore2,
  ITip,
  IToken,
  IUserDetails,
  TipScreenTypes,
} from 'types';
import { getCommentById, getSelectedFeed } from '../post/helper';
import { SEARCH_PARAMS } from 'constant';
import { parsePostAction } from './helper';

const mainFeedSelector = (state: IStore2) => state.feed.mainFeed;

const chatFeedSelector = (state: IStore2) => state.feed.chatFeed;

const userFeedSelector = (state: IStore2) => state.feed.userFeed;

const shopFeedSelector = (state: IStore2) => state.feed.shopFeed;

const searchFeedSelector = (state: IStore2) => state.feed.searchFeed;

const selectedFeedTypeSelector = (state: IStore2) => state.feed.selectedFeedType;

const selectedBubbleSelector = (state: IStore2) => state.feed.mainFeed.selectedFeedBubble;

const feedSelector = (state: IStore2) => state.feed;

const userSelector = (state: IStore2) => state.user;

const profileOwnerUsernameSelector = (state: IStore2) => state.profile.username;

const shopOwnerUsernameSelector = (state: IStore2) => state.shop.details?.userDetails.username;

const profilePostsSelector = createSelector(
  [userFeedSelector, profileOwnerUsernameSelector],
  (posts, profileOwnerUsername): IFeedStore => {
    if (!posts.list || !profileOwnerUsername) {
      return posts;
    }

    const list = posts.list.map((post) => {
      const postAction = parsePostAction(post.action, profileOwnerUsername || '');

      return {
        ...post,
        action: postAction,
      };
    });

    return {
      list,
      hasMore: posts.hasMore,
      isLoading: posts.isLoading,
      paginationToken: posts.paginationToken,
      selectedFeedBubble: posts.selectedFeedBubble,
    };
  },
);

const shopPostsSelector = createSelector(
  [shopFeedSelector, shopOwnerUsernameSelector],
  (posts, shopOwnerUsername): IFeedStore => {
    if (!posts.list || !shopOwnerUsername) {
      return posts;
    }

    const list = posts.list.map((post) => {
      const postAction = parsePostAction(post.action, shopOwnerUsername || '');

      return {
        ...post,
        action: postAction,
      };
    });

    return {
      list,
      hasMore: posts.hasMore,
      isLoading: posts.isLoading,
      paginationToken: posts.paginationToken,
      selectedFeedBubble: posts.selectedFeedBubble,
    };
  },
);

const profileSelector = (state: IStore2) => state.feed.profile;

const selectedPostIdSelector = (state: IStore2) => state.feed.selectedPostId;

const selectedSubPostIdSelector = (state: IStore2) => state.feed.selectedSubPostId;

const selectedCommentIdSelector = (state: IStore2) => state.feed.selectedCommentId;

const selectedReplyIdSelector = (state: IStore2) => state.feed.selectedReplyId;

const shopsSelector = (state: IStore2) => state.feed.createPostDetails.shops;

const mapIdToSubPostsSelector = (state: IStore2) => state.feed.mapPostIdToSubPosts;

const feedBubblesSelector = (state: IStore2) => state.feed.feedBubbles;

const commentIdSelector = (state: IStore2) => state.popup.commentId;

const replyIdSelector = (state: IStore2) => state.popup.replyId;

const tipsSelector = (state: IStore2) => state.config.tips;

const createPostValuesSelector = (state: IStore2) => state.popup.createPostInitialValues;

const mainPostSelector = createSelector(
  [feedSelector, selectedPostIdSelector],
  (feed, selectedPostId): IPost | undefined => {
    const selectedFeed = getSelectedFeed(feed);

    if (!selectedFeed.list) {
      return undefined;
    }

    return selectedFeed.list.find((post: IPost) => post.id === selectedPostId);
  },
);

const selectedPostSelector = createSelector(
  [feedSelector, selectedPostIdSelector, selectedSubPostIdSelector, mapIdToSubPostsSelector],
  (feed, selectedPostId, selectedSubPostId, mapIdToSubPosts): IPost | undefined => {
    const selectedFeed = getSelectedFeed(feed);

    if (!selectedFeed.list) {
      return undefined;
    }

    const mainPost = selectedFeed.list.find((post) => post.id === selectedPostId);

    if (selectedSubPostId && selectedPostId && mainPost) {
      const {
        userDetails,
        text,
        productDetails,
        isFollowing,
        selfComments,
        shopId,
        images,
        mojoDetails,
        imageComposition,
        lastComment,
        isEditable,
        lastEdited,
        repostDetails,
        actionId,
        taggedUsers,
        shouldDisplayHideFromActivity,
        externalPost,
      } = mainPost;

      const posts = mapIdToSubPosts[selectedPostId];

      const subPost = posts?.find((item) => item.id === selectedSubPostId);

      if (!subPost) {
        return undefined;
      }

      return {
        actionId,
        taggedUsers,
        imageComposition,
        shopId,
        userDetails,
        isFollowing,
        text,
        repostDetails,
        selfComments,
        images,
        mojoDetails,
        lastComment,
        productDetails,
        isCommentsExpanded: true,
        isReposted: false,
        totalReposts: 0,
        isEditable,
        lastEdited,
        action: null,
        id: subPost.id,
        isLiked: subPost.isLiked,
        likes: subPost.likes,
        totalLikes: subPost.totalLikes,
        totalComments: subPost.totalComments,
        comments: subPost.comments,
        shouldDisplayHideFromActivity,
        isShoppingPost: false,
        shouldHideDate: false,
        externalPost,
      };
    }

    return mainPost;
  },
);

const getSelectedPost = (state: IFeedState) => {
  const { selectedSubPostId, mapPostIdToSubPosts, selectedPostId } = state;

  if (selectedSubPostId && selectedPostId) {
    const posts = mapPostIdToSubPosts[selectedPostId];

    return posts?.find((item) => item.id === selectedSubPostId);
  }

  const feed = getSelectedFeed(state);

  return feed.list?.find((item) => item.id === selectedPostId);
};

const selectedCommentSelector = createSelector(
  [feedSelector, selectedCommentIdSelector, selectedReplyIdSelector],
  (feed, commentId, replyId): IComment | undefined => {
    const currentFeed = getSelectedFeed(feed);

    if (!currentFeed.list || !commentId) {
      return undefined;
    }

    const post: any = getSelectedPost(feed);
    let comment: IComment | undefined;
    if (post.isCommentsExpanded === false) {
      comment =
        post?.lastComment || post.selfComments?.find((item: IComment) => item.id === commentId);
    } else {
      comment = post?.comments.list?.find((item: IComment) => item.id === commentId);
    }
    // Reply is selected - searching for the reply
    if (replyId && comment) {
      comment = comment.replies.find((reply) => reply.id === replyId);
    }

    return comment;
  },
);

const selectedPostSubPostsSelector = createSelector(
  [selectedPostIdSelector, mapIdToSubPostsSelector],
  (selectedPostId, mapIdToSubPosts) => {
    if (!selectedPostId) {
      return null;
    }

    return mapIdToSubPosts[selectedPostId];
  },
);

const selectProfileSelector = createSelector(
  [profileSelector, userSelector],
  (profile, user): IFeedProfile | null => {
    if (window.location.search && !window.location.search.includes(SEARCH_PARAMS.POPUP_KEY)) {
      if (!profile) {
        return null;
      }

      const { name, extraInformation, coverPhoto, totalFollowers, avatar } = profile;

      return {
        name,
        totalFollowers,
        avatar,
        extraInformation,
        coverPhoto,
        bio: null,
        totalFollowing: null,
        totalProfileViews: null,
      };
    }

    const { token, avatar } = user;

    if (!token) {
      return null;
    }

    try {
      const parsedToken = jwt_decode<IToken>(token);

      const { username: name } = parsedToken;

      const { shopDetails, totalFollowers, totalFollowing, totalProfileViews, bio } = user;

      const coverPhoto = shopDetails?.coverPhoto || null;

      const extraInformation = shopDetails?.name || '';

      return {
        name,
        bio,
        totalFollowing,
        totalProfileViews,
        totalFollowers,
        avatar,
        extraInformation,
        coverPhoto,
      };
    } catch (e) {
      return null;
    }
  },
);

const getLikeListSelector = createSelector(
  [selectedPostSelector, commentIdSelector, replyIdSelector],
  (post, commentId, replyId): IUserDetails[] | null => {
    if (!post) return null;

    let selectedComment;
    if (commentId) {
      selectedComment = getCommentById(post, commentId, replyId);
    }

    if (selectedComment) return selectedComment.likes.list;

    return post.likes.list;
  },
);

const getTotalLikesSelector = createSelector(
  [selectedPostSelector, commentIdSelector, replyIdSelector],
  (post, commentId, replyId): number => {
    if (!post) return 0;

    let selectedComment;
    if (commentId) {
      selectedComment = getCommentById(post, commentId, replyId);
    }

    if (selectedComment) return selectedComment.totalLikes;

    return post.totalLikes;
  },
);

const hasMoreLikesSelector = createSelector(
  [selectedPostSelector, commentIdSelector, replyIdSelector],
  (post, commentId, replyId): boolean => {
    if (!post) return false;
    let selectedComment;
    if (commentId) {
      selectedComment = getCommentById(post, commentId, replyId);
    }

    return selectedComment?.likes.hasMore || post.likes.hasMore;
  },
);

const feedTipSelector = createSelector([tipsSelector], (tips): ITip | null => {
  return tips.find((tip) => tip.displayedRoutes.includes(TipScreenTypes.Feed)) || null;
});

const createPostInitialValuesSelector = createSelector([createPostValuesSelector], (values) => {
  const backgroundIndex = values?.backgroundIndex !== undefined ? values.backgroundIndex : null;

  return {
    id: values?.id || null,
    text: values?.text || '',
    images: values?.images || null,
    product: values?.product || null,
    backgroundIndex,
    imageComposition: values?.imageComposition || ImageComposition.zero,
    repostDetails: values?.repostDetails || null,
    shopId: values?.shopId || null,
    taggedUsers: values?.taggedUsers || [],
    visionId: values?.visionId || null,
  };
});

export const FeedSelector = {
  selectedPostSelector,
  mainPostSelector,
  selectedPostSubPostsSelector,
  selectedCommentSelector,
  selectedPostIdSelector,
  shopsSelector,
  selectProfileSelector,
  getTotalLikesSelector,
  getLikeListSelector,
  hasMoreLikesSelector,
  feedTipSelector,
  createPostInitialValuesSelector,
  feedBubblesSelector,
  mainFeedSelector,
  profilePostsSelector,
  chatFeedSelector,
  shopPostsSelector,
  searchFeedSelector,
  selectedFeedTypeSelector,
  selectedBubbleSelector,
};
