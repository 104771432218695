/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Long: { input: any; output: any; }
  TedoooID: { input: any; output: any; }
  join__FieldSet: { input: any; output: any; }
  link__Import: { input: any; output: any; }
};

export type AbGroup = {
  __typename?: 'AbGroup';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AcceptedVisionOffer = {
  __typename?: 'AcceptedVisionOffer';
  category: Scalars['String']['output'];
  date: Scalars['Long']['output'];
  imageUrl: Scalars['String']['output'];
  mockupImage?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  orderId: Scalars['String']['output'];
  partnerInfo: VisionUser;
  shippingAddress: Scalars['String']['output'];
};

export type AcceptedVisionOfferPaginatedResponse = {
  __typename?: 'AcceptedVisionOfferPaginatedResponse';
  offers: Array<AcceptedVisionOffer>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type Achievement = {
  __typename?: 'Achievement';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  isRare: Scalars['Boolean']['output'];
  status: AchievementStatus;
  title: Scalars['String']['output'];
};

export type AchievementAchievedPopupDetails = {
  __typename?: 'AchievementAchievedPopupDetails';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AchievementGroup = {
  __typename?: 'AchievementGroup';
  achievements: Array<Achievement>;
  id: Scalars['String']['output'];
  isExpandable: Scalars['Boolean']['output'];
  subtext?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<AchievementGroupTag>;
  title: Scalars['String']['output'];
};

export type AchievementGroupTag = {
  __typename?: 'AchievementGroupTag';
  image?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type AchievementStatus = AchievementStatusAchieved | AchievementStatusInProgress | AchievementStatusNotStarted;

export type AchievementStatusAchieved = {
  __typename?: 'AchievementStatusAchieved';
  achievedAt: Scalars['Long']['output'];
};

export type AchievementStatusInProgress = {
  __typename?: 'AchievementStatusInProgress';
  max: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type AchievementStatusNotStarted = {
  __typename?: 'AchievementStatusNotStarted';
  none: Scalars['Boolean']['output'];
};

export type Ad1 = {
  __typename?: 'Ad1';
  backgroundColor: Scalars['String']['output'];
  button?: Maybe<ButtonStyle>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  ratio: Scalars['Float']['output'];
  sectionDetails: Positioning;
};

export type AdHorizontalListItem = GenericAd | GenericAdPro;

export type AdHorizontalListItem2 = GenericAdShopOfTheWeek | TedoooPageAd;

export type AddToCartRequest = {
  customization?: InputMaybe<CustomizationInput>;
  productId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type AiRealImagePair = {
  __typename?: 'AiRealImagePair';
  aiImage: Scalars['String']['output'];
  realImage: Scalars['String']['output'];
};

export enum AppConfigDrawer {
  Discover = 'Discover',
  Favorites = 'Favorites',
  Mojos = 'Mojos',
  Terms = 'Terms',
  UploadCsv = 'UploadCsv'
}

export type AppConfigExtras = {
  __typename?: 'AppConfigExtras';
  shouldShowVisionSellerRequestBanner: Scalars['Boolean']['output'];
  visionRequestStatus: VisionRequestStatus;
};

export type AppConfigResponse = {
  __typename?: 'AppConfigResponse';
  abGroups: Array<AbGroup>;
  achievements: UserAchievementStatus;
  cacheUpdates: CacheUpdates;
  config: AppConfigExtras;
  didLinkPayment: Scalars['Boolean']['output'];
  didLinkShippo: Scalars['Boolean']['output'];
  domainWhitelist: Array<Scalars['String']['output']>;
  drawer?: Maybe<AppConfigDrawer>;
  featureFlags: Array<FeatureFlag>;
  hasShippingProfile: Scalars['Boolean']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  popupDetails?: Maybe<PopupDetails>;
  portfolioItemCount?: Maybe<Scalars['Int']['output']>;
  tips: Array<AppConfigTip>;
  unseenRequestCount: Scalars['Int']['output'];
};

export type AppConfigTip = {
  __typename?: 'AppConfigTip';
  button?: Maybe<TipButton>;
  items: Array<DisplayItem>;
  key: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  screens: Array<ScreenType>;
  tipType: TipType;
};

export enum ApproveVisionSellerStatus {
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type AutocompleteFeature = {
  __typename?: 'AutocompleteFeature';
  icon?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type AutocompleteHorizontalList = {
  __typename?: 'AutocompleteHorizontalList';
  items: Array<MorePageHorizontalListItem>;
  title: Scalars['String']['output'];
};

export type AutocompleteItem = {
  __typename?: 'AutocompleteItem';
  id: Scalars['String']['output'];
  item: AutocompleteItemInner;
};

export type AutocompleteItemInner = AutocompleteFeature | AutocompleteHorizontalList | AutocompleteStaticItem | AutocompleteTextSuggestion | User;

export type AutocompleteResponse = {
  __typename?: 'AutocompleteResponse';
  items: Array<AutocompleteItem>;
  /** @deprecated this field will be removed in the future, use items instead */
  suggestions: Array<Scalars['String']['output']>;
};

export type AutocompleteResponseV2 = {
  __typename?: 'AutocompleteResponseV2';
  items: Array<AutocompleteItem>;
};

export type AutocompleteStaticItem = {
  __typename?: 'AutocompleteStaticItem';
  extra?: Maybe<Scalars['String']['output']>;
  option: AutocompleteStaticOption;
};

export enum AutocompleteStaticOption {
  RecentSearches = 'RECENT_SEARCHES',
  TrendingSearches = 'TRENDING_SEARCHES'
}

export type AutocompleteTextSuggestion = {
  __typename?: 'AutocompleteTextSuggestion';
  text: Scalars['String']['output'];
};

export enum BadgeVariant {
  Danger = 'DANGER',
  Main = 'MAIN'
}

export type BatchMockupDetailsInput = {
  details?: InputMaybe<MockupDetailsInput>;
  visionId: Scalars['String']['input'];
};

export enum ButtonOrigin {
  End = 'END',
  Mid = 'MID',
  Start = 'START'
}

export type ButtonStyle = {
  __typename?: 'ButtonStyle';
  link: Scalars['String']['output'];
  minWidth?: Maybe<Scalars['Float']['output']>;
  text: Scalars['String']['output'];
  variant: ButtonVariant;
  x: Position;
  y: Position;
};

export enum ButtonVariant {
  Primary = 'primary',
  PrimaryDanger = 'primaryDanger',
  PrimaryWhite = 'primaryWhite',
  Secondary = 'secondary',
  SecondaryDanger = 'secondaryDanger',
  SecondaryGray = 'secondaryGray',
  Tertiary = 'tertiary',
  TertiaryDanger = 'tertiaryDanger',
  TertiaryGray = 'tertiaryGray'
}

export type BuyerRequestsTabCountResponse = {
  __typename?: 'BuyerRequestsTabCountResponse';
  acceptedCount: Scalars['Int']['output'];
  pendingCount: Scalars['Int']['output'];
};

export type CacheUpdates = {
  __typename?: 'CacheUpdates';
  morePageMinTime?: Maybe<Scalars['Long']['output']>;
};

export type CanShipToAddressResponse = {
  __typename?: 'CanShipToAddressResponse';
  canShip: Scalars['Boolean']['output'];
};

export type CartCountResponse = {
  __typename?: 'CartCountResponse';
  totalItems: Scalars['Int']['output'];
};

export type CartItem = {
  __typename?: 'CartItem';
  cartItemId: Scalars['String']['output'];
  convertedFrom?: Maybe<Price>;
  customization?: Maybe<Customization>;
  image: Scalars['String']['output'];
  originalPrice?: Maybe<Scalars['Long']['output']>;
  price: Price;
  productId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  status: CartItemStatus;
  title: Scalars['String']['output'];
};

export enum CartItemStatus {
  Available = 'Available',
  SoldOut = 'SoldOut',
  Unavailable = 'Unavailable'
}

export type CartResponse = {
  __typename?: 'CartResponse';
  shops: Array<CartShop>;
};

export type CartShop = {
  __typename?: 'CartShop';
  checkoutId: Scalars['String']['output'];
  hasInstantCheckout: Scalars['Boolean']['output'];
  products: Array<CartItem>;
  shopId: Scalars['String']['output'];
  shopName: Scalars['String']['output'];
  user: CartUser;
};

export type CartUser = {
  __typename?: 'CartUser';
  avatar?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPremium: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type CategoryItem = {
  __typename?: 'CategoryItem';
  bigImage: Scalars['String']['output'];
  name: Scalars['String']['output'];
  smallImage: Scalars['String']['output'];
  subCategories: Array<Scalars['String']['output']>;
  titleColor: Scalars['String']['output'];
};

export type ChannelList = {
  __typename?: 'ChannelList';
  channels: Array<ChannelListItem>;
  header?: Maybe<Scalars['String']['output']>;
  inset: HorizontalPadding;
  interactId: Scalars['String']['output'];
  isClosable: Scalars['Boolean']['output'];
};

export type ChannelListItem = {
  __typename?: 'ChannelListItem';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  isPopular: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  subscriberCount: Scalars['Int']['output'];
  preSelect: Scalars['Boolean']['output'];
};

export type ChatChannel = {
  __typename?: 'ChatChannel';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  isPopular: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  subscriberCount: Scalars['Int']['output'];
};

export type ChatUserInformation = {
  __typename?: 'ChatUserInformation';
  isPremium: Scalars['Boolean']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  totalTransactions: Scalars['Int']['output'];
};

export type CheckCouponCodeResponseError = {
  __typename?: 'CheckCouponCodeResponseError';
  code: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
};

export type CheckCouponCodeResponseSuccess = {
  __typename?: 'CheckCouponCodeResponseSuccess';
  discountAmount: Scalars['Long']['output'];
  discountType: DiscountType;
  newPrice: Scalars['Long']['output'];
};

export type CheckCustomOfferAcceptanceResponse = {
  __typename?: 'CheckCustomOfferAcceptanceResponse';
  acceptsCustomOffers: Scalars['Boolean']['output'];
};

export type CheckLinkStatusResponse = {
  __typename?: 'CheckLinkStatusResponse';
  status: Scalars['Boolean']['output'];
};

export type CheckReferralCodeResponse = {
  __typename?: 'CheckReferralCodeResponse';
  rewards: Array<ReferralCodeReward>;
};

export type CheckVerificationStatusResponse = {
  __typename?: 'CheckVerificationStatusResponse';
  verificationStatus: VerificationStatus;
};

export type CheckoutCouponCodeResponse = CheckCouponCodeResponseError | CheckCouponCodeResponseSuccess;

export enum CheckoutIdStatus {
  PaidPaypal = 'PaidPaypal',
  PaidStripe = 'PaidStripe',
  WaitingForPayment = 'WaitingForPayment'
}

export type CheckoutIdStatusResponse = {
  __typename?: 'CheckoutIdStatusResponse';
  status: CheckoutIdStatus;
};

export type CheckoutResponse = {
  __typename?: 'CheckoutResponse';
  payments: Array<GetPaymentCheckoutResponse>;
  rates?: Maybe<Array<ShippingRate>>;
};

export type CommentItem = {
  __typename?: 'CommentItem';
  avatar?: Maybe<Scalars['String']['output']>;
  date: Scalars['Long']['output'];
  didLike: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isPro: Scalars['Boolean']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  likes: Scalars['Int']['output'];
  replies: Scalars['Int']['output'];
  tags: Array<CommentItemTag>;
  text?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type CommentItemTag = {
  __typename?: 'CommentItemTag';
  id: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type CountriesType = {
  __typename?: 'CountriesType';
  countries: Array<Scalars['String']['output']>;
  countriesType: CountriesTypeType;
};

export enum CountriesTypeType {
  All = 'All',
  Specific = 'Specific'
}

export type CouponCode = {
  __typename?: 'CouponCode';
  amount: DiscountAmount;
  endTime?: Maybe<Scalars['Long']['output']>;
  id: Scalars['String']['output'];
  minimumCartPrice?: Maybe<Scalars['Long']['output']>;
  name: Scalars['String']['output'];
  startTime: Scalars['Long']['output'];
  totalUsages: Scalars['Int']['output'];
};

export type CouponCodeInput = {
  amount: DiscountAmountInput;
  endTime?: InputMaybe<Scalars['Long']['input']>;
  minimumCartPrice?: InputMaybe<Scalars['Long']['input']>;
  name: Scalars['String']['input'];
  startTime: Scalars['Long']['input'];
};

export type CreatePaymentIntentResult = {
  __typename?: 'CreatePaymentIntentResult';
  clientId: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  customerToken: Scalars['String']['output'];
  merchantDisplayName: Scalars['String']['output'];
  paymentId: Scalars['String']['output'];
  paymentIntentSecret: Scalars['String']['output'];
  price: Price;
};

export type CreatePaypalCheckoutForVisionResponse = {
  __typename?: 'CreatePaypalCheckoutForVisionResponse';
  link: Scalars['String']['output'];
};

export type CreateProductSectionResponse = {
  __typename?: 'CreateProductSectionResponse';
  sectionId: Scalars['String']['output'];
};

export type CustomGridProduct = {
  __typename?: 'CustomGridProduct';
  id: Scalars['String']['output'];
  items: Array<CustomGridProductItem>;
  listInfo?: Maybe<ListData>;
  ratio: Scalars['Float']['output'];
  sectionDetails: Positioning;
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
};

export type CustomGridProductItem = {
  __typename?: 'CustomGridProductItem';
  endPoint: Point;
  item: DiscoverPageProduct;
  padding: Padding;
  startPoint: Point;
};

export type CustomOffer = {
  __typename?: 'CustomOffer';
  checkoutId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dimensions?: Maybe<Dimensions>;
  image?: Maybe<Scalars['String']['output']>;
  isDigital: Scalars['Boolean']['output'];
  offerNumber: Scalars['String']['output'];
  partnerInfo: PartnerInfo;
  price: Price;
  returnsPolicy: ReturnsPolicy;
  shippingProfileId?: Maybe<Scalars['String']['output']>;
  tariff?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Customization = {
  __typename?: 'Customization';
  images: Array<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type CustomizationInput = {
  images: Array<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export type DecodeProductCsvProgressResponse = {
  __typename?: 'DecodeProductCsvProgressResponse';
  progress: Scalars['Int']['output'];
  successUrls: Array<Scalars['String']['output']>;
};

export type DecodeProductCsvResponse = {
  __typename?: 'DecodeProductCsvResponse';
  products: Array<DecodeProductCsvResponseProduct>;
  requestId?: Maybe<Scalars['String']['output']>;
};

export type DecodeProductCsvResponseProduct = {
  __typename?: 'DecodeProductCsvResponseProduct';
  description?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  price?: Maybe<Price>;
  quantity?: Maybe<Scalars['Int']['output']>;
  tags: Array<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Dimensions = {
  __typename?: 'Dimensions';
  height: Scalars['Int']['output'];
  length: Scalars['Int']['output'];
  metric: Scalars['Boolean']['output'];
  weightBig: Scalars['Int']['output'];
  weightSmall: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

export type DimensionsInput = {
  height: Scalars['Int']['input'];
  length: Scalars['Int']['input'];
  metric: Scalars['Boolean']['input'];
  weightBig: Scalars['Int']['input'];
  weightSmall: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type DiscountAmount = {
  __typename?: 'DiscountAmount';
  discountType: DiscountType;
  value: Scalars['Long']['output'];
};

export type DiscountAmountInput = {
  discountType: DiscountType;
  value: Scalars['Long']['input'];
};

export type DiscountShareDetails = {
  __typename?: 'DiscountShareDetails';
  discount: ShopDiscount;
  products: Array<DiscountShareDetailsProduct>;
  shopCurrencyCode: Scalars['String']['output'];
  shopCurrencySymbol: Scalars['String']['output'];
  shopName: Scalars['String']['output'];
};

export type DiscountShareDetailsProduct = {
  __typename?: 'DiscountShareDetailsProduct';
  image: Scalars['String']['output'];
  originalPrice: Scalars['Long']['output'];
};

export enum DiscountType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

export type DiscoverPageCategoriesItem = {
  __typename?: 'DiscoverPageCategoriesItem';
  categories: Array<CategoryItem>;
  id: Scalars['String']['output'];
  listInfo: ListData;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  title: Array<StyledText>;
};

export type DiscoverPageGenericItem = {
  __typename?: 'DiscoverPageGenericItem';
  didLike?: Maybe<Scalars['Boolean']['output']>;
  imageUrl: Scalars['String']['output'];
  interactId: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  styling: DiscoverPageGenericItemStyling;
  totalLikes?: Maybe<Scalars['Int']['output']>;
};

export type DiscoverPageGenericItemStyling = {
  __typename?: 'DiscoverPageGenericItemStyling';
  cornerRadius: Scalars['Int']['output'];
  withColorOverlay: Scalars['Boolean']['output'];
};

export type DiscoverPageGrid1 = {
  __typename?: 'DiscoverPageGrid1';
  bigProduct: DiscoverPageProduct;
  listInfo?: Maybe<ListData>;
  smallProducts: Array<DiscoverPageProduct>;
  title: Array<StyledText>;
};

export type DiscoverPageLayout1 = {
  __typename?: 'DiscoverPageLayout1';
  bottomLeft: DiscoverPageLayout1BottomLeft;
  right: EqualGridProduct;
  sectionDetails: Positioning;
  topLeft: HorizontalListProduct;
};

export type DiscoverPageLayout1BottomLeft = {
  __typename?: 'DiscoverPageLayout1BottomLeft';
  backgroundImage: Scalars['String']['output'];
  bigProduct: DiscoverPageProduct;
  listInfo?: Maybe<ListData>;
  smallProducts: Array<DiscoverPageProduct>;
};

export type DiscoverPageLayout2 = {
  __typename?: 'DiscoverPageLayout2';
  bottomLeft: HorizontalListProduct;
  right: DiscoverPageGrid1;
  sectionDetails: Positioning;
  topLeft: HorizontalListProduct;
};

export type DiscoverPageLayout3 = {
  __typename?: 'DiscoverPageLayout3';
  bottomLeft: HorizontalListProduct;
  right: EqualGridProduct;
  sectionDetails: Positioning;
  topLeft: HorizontalListProduct;
};

export type DiscoverPageProduct = {
  __typename?: 'DiscoverPageProduct';
  didLike?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['TedoooID']['output'];
  imageUrl: Scalars['String']['output'];
  price?: Maybe<Price>;
  totalLikes?: Maybe<Scalars['Int']['output']>;
};

export type DiscoverPageResult = {
  __typename?: 'DiscoverPageResult';
  ads1: Array<Ad1>;
  backgroundColor?: Maybe<Scalars['String']['output']>;
  backgroundImage?: Maybe<Scalars['String']['output']>;
  categories: Array<DiscoverPageCategoriesItem>;
  customGridsProducts: Array<CustomGridProduct>;
  equalGridsProducts: Array<EqualGridProduct>;
  equalGridsShops: Array<EqualGridShop>;
  feedItems: Array<FeedItem2>;
  genericEqualGrids: Array<GenericEqualGrid>;
  genericHorizontalLists: Array<GenericHorizontalList>;
  horizontalListsProducts: Array<HorizontalListProduct>;
  horizontalListsShops: Array<HorizontalListShop>;
  horizontalListsVisions: Array<HorizontalListVision>;
  horizontalListsVisionsRequests: Array<HorizontalListVisionRequest>;
  horizontalMojos: Array<HorizontalListMojo>;
  lottie?: Maybe<LottieData>;
  mojos: Array<MojoItem>;
  paginationToken?: Maybe<Scalars['String']['output']>;
  shopsOfTheDay: Array<ShopOfTheDay>;
  staticItems: Array<DiscoverPageStaticItem>;
  topButtons: Array<DiscoverPageTopButton>;
};

export type DiscoverPageResultWeb = {
  __typename?: 'DiscoverPageResultWeb';
  categories: Array<DiscoverPageCategoriesItem>;
  equalGridsProducts: Array<EqualGridProduct>;
  horizontalListsProducts: Array<HorizontalListProduct>;
  horizontalListsVisions: Array<HorizontalListVision>;
  layout1: Array<DiscoverPageLayout1>;
  layout2: Array<DiscoverPageLayout2>;
  layout3: Array<DiscoverPageLayout3>;
  paginationToken?: Maybe<Scalars['String']['output']>;
  staticItems: Array<DiscoverPageStaticItem>;
  visions: Array<DiscoverPageVisions>;
};

export enum DiscoverPageSectionType {
  Layout_1 = 'LAYOUT_1',
  Layout_2 = 'LAYOUT_2',
  Layout_3 = 'LAYOUT_3'
}

export type DiscoverPageShop = {
  __typename?: 'DiscoverPageShop';
  avatar?: Maybe<Scalars['String']['output']>;
  hasPortfolio: Scalars['Boolean']['output'];
  id: Scalars['TedoooID']['output'];
  images: Array<Scalars['String']['output']>;
  isFollowing: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  rating: Scalars['Float']['output'];
  shopName: Scalars['String']['output'];
  userId: Scalars['TedoooID']['output'];
  username: Scalars['String']['output'];
};

export type DiscoverPageStaticItem = {
  __typename?: 'DiscoverPageStaticItem';
  extras?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  sectionDetails: Positioning;
};

export type DiscoverPageTag = {
  __typename?: 'DiscoverPageTag';
  icon?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  variant: TagVariant;
};

export type DiscoverPageTopButton = {
  __typename?: 'DiscoverPageTopButton';
  image: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type DiscoverPageUser = {
  __typename?: 'DiscoverPageUser';
  avatar?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type DiscoverPageVision = {
  __typename?: 'DiscoverPageVision';
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isMockupEnabled: Scalars['Boolean']['output'];
  mockupDetails?: Maybe<MockupDetails>;
  prompt?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  user: DiscoverPageUser;
};

export type DiscoverPageVisionRequest = {
  __typename?: 'DiscoverPageVisionRequest';
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
};

export type DiscoverPageVisions = {
  __typename?: 'DiscoverPageVisions';
  id: Scalars['String']['output'];
  listInfo?: Maybe<ListData>;
  sectionDetails: Positioning;
  sectionType: DiscoverPageSectionType;
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
  visions: Array<DiscoverPageVision>;
};

export enum DiscoverScreen {
  BuyerRequests = 'buyerRequests',
  Categories = 'categories',
  Discover = 'discover',
  Mojos = 'mojos',
  Products = 'products',
  Shops = 'shops',
  Visions = 'visions'
}

export type DisplayItem = {
  __typename?: 'DisplayItem';
  imageItem?: Maybe<ImageItem>;
  textItem?: Maybe<TextItem>;
};

export type EqualGridProduct = {
  __typename?: 'EqualGridProduct';
  id: Scalars['String']['output'];
  listInfo?: Maybe<ListData>;
  products: Array<DiscoverPageProduct>;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
};

export type EqualGridShop = {
  __typename?: 'EqualGridShop';
  id: Scalars['String']['output'];
  listInfo?: Maybe<ListData>;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  shops: Array<DiscoverPageShop>;
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
};

export type FavoritesPopupDetails = {
  __typename?: 'FavoritesPopupDetails';
  images: Array<Scalars['String']['output']>;
};

export enum FeatureFlag {
  RequestToBuy = 'REQUEST_TO_BUY',
  RequirePayment = 'REQUIRE_PAYMENT',
  Smartlook = 'SMARTLOOK'
}

export type FeedItem = {
  __typename?: 'FeedItem';
  avatar?: Maybe<Scalars['String']['output']>;
  backgroundIndex?: Maybe<Scalars['Int']['output']>;
  comments: Scalars['Int']['output'];
  composition: Scalars['Int']['output'];
  date: Scalars['Long']['output'];
  didLike: Scalars['Boolean']['output'];
  didRepost: Scalars['Boolean']['output'];
  editable: Scalars['Boolean']['output'];
  editedDate?: Maybe<Scalars['Long']['output']>;
  following: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  hideDate: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  isShoppingPost: Scalars['Boolean']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  lastComment?: Maybe<LastComment>;
  likes: Scalars['Int']['output'];
  postCategory?: Maybe<Scalars['String']['output']>;
  premium: Scalars['Boolean']['output'];
  productDetails?: Maybe<FeedPostProductDetails>;
  ratio: Scalars['Float']['output'];
  reason: Scalars['Int']['output'];
  reasonExtra: Scalars['String']['output'];
  repostDetails?: Maybe<FeedRepostPostDetails>;
  repostId?: Maybe<Scalars['String']['output']>;
  reposts: Scalars['Int']['output'];
  sectionDetails: Positioning;
  shopId?: Maybe<Scalars['String']['output']>;
  shopImage?: Maybe<Scalars['String']['output']>;
  shopName?: Maybe<Scalars['String']['output']>;
  tags: Array<FeedTag>;
  text: Scalars['String']['output'];
  totalFollowers: Scalars['Int']['output'];
  totalFollowing: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type FeedItem2 = {
  __typename?: 'FeedItem2';
  avatar?: Maybe<Scalars['String']['output']>;
  backgroundIndex?: Maybe<Scalars['Int']['output']>;
  comments: Scalars['Int']['output'];
  composition: Scalars['Int']['output'];
  date: Scalars['Long']['output'];
  didLike: Scalars['Boolean']['output'];
  didRepost: Scalars['Boolean']['output'];
  editable: Scalars['Boolean']['output'];
  editedDate?: Maybe<Scalars['Long']['output']>;
  following: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  hideDate: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  isShoppingPost: Scalars['Boolean']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  lastComment?: Maybe<LastComment>;
  likes: Scalars['Int']['output'];
  postCategory?: Maybe<Scalars['String']['output']>;
  premium: Scalars['Boolean']['output'];
  productDetails?: Maybe<FeedPostProductDetails>;
  ratio: Scalars['Float']['output'];
  reason: Scalars['Int']['output'];
  reasonExtra: Scalars['String']['output'];
  repostDetails?: Maybe<FeedRepostPostDetails>;
  repostId?: Maybe<Scalars['String']['output']>;
  reposts: Scalars['Int']['output'];
  shopId?: Maybe<Scalars['String']['output']>;
  shopImage?: Maybe<Scalars['String']['output']>;
  shopName?: Maybe<Scalars['String']['output']>;
  tags: Array<FeedTag>;
  text: Scalars['String']['output'];
  totalFollowers: Scalars['Int']['output'];
  totalFollowing: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
  externalPost:ExternalPost;
};

export type ExternalPost = {
  source: Scalars['String']['output'];
  niche?:Maybe<ExternalPostNiche>;
}

export type ExternalPostNiche = {
  link: Scalars['String']['output'] | null;
  name: Scalars['String']['output'] | null;
}

export type FeedPostProductDetails = {
  __typename?: 'FeedPostProductDetails';
  price: Price;
  productId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FeedRepostPostDetails = {
  __typename?: 'FeedRepostPostDetails';
  avatar?: Maybe<Scalars['String']['output']>;
  backgroundIndex?: Maybe<Scalars['Int']['output']>;
  comments: Scalars['Int']['output'];
  composition: Scalars['Int']['output'];
  date: Scalars['Long']['output'];
  fullName: Scalars['String']['output'];
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  isShoppingPost: Scalars['Boolean']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  lastComment?: Maybe<LastComment>;
  likes: Scalars['Int']['output'];
  postCategory?: Maybe<Scalars['String']['output']>;
  premium: Scalars['Boolean']['output'];
  productDetails?: Maybe<FeedPostProductDetails>;
  ratio: Scalars['Float']['output'];
  repostId?: Maybe<Scalars['String']['output']>;
  reposts: Scalars['Int']['output'];
  shopId?: Maybe<Scalars['String']['output']>;
  shopImage?: Maybe<Scalars['String']['output']>;
  shopName?: Maybe<Scalars['String']['output']>;
  tags: Array<FeedTag>;
  text: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type FeedTag = {
  __typename?: 'FeedTag';
  id: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type FindOneDiscount = {
  __typename?: 'FindOneDiscount';
  item?: Maybe<ShopDiscount>;
};

export type FindOneResponseShop = {
  __typename?: 'FindOneResponseShop';
  item?: Maybe<Shop>;
};

export type FindOneSale = {
  __typename?: 'FindOneSale';
  item?: Maybe<Sale>;
};

export type FindResponseCommentItem = {
  __typename?: 'FindResponseCommentItem';
  items: Array<CommentItem>;
  paginationToken?: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type FindResponseLikeItem = {
  __typename?: 'FindResponseLikeItem';
  items: Array<LikeItem>;
  paginationToken?: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type FixedPrice = {
  __typename?: 'FixedPrice';
  additionalItem: Scalars['Int']['output'];
  firstItem: Scalars['Int']['output'];
};

export enum Fonts {
  Body14 = 'body14',
  Body16 = 'body16',
  ButtonMedium = 'buttonMedium',
  ButtonSmall = 'buttonSmall',
  Heading1 = 'heading1',
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  Heading4 = 'heading4',
  Heading5 = 'heading5',
  Subheading = 'subheading',
  Subheading14 = 'subheading14'
}

export type FullShippingAddress = {
  __typename?: 'FullShippingAddress';
  fullName: Scalars['String']['output'];
  shippingAddress: ShippingAddress;
};

export type GenerateVisionResponse = {
  __typename?: 'GenerateVisionResponse';
  generationId: Scalars['String']['output'];
  visions: Array<GeneratedVision>;
};

export type GeneratedVision = {
  __typename?: 'GeneratedVision';
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
};

export type GenericAd = {
  __typename?: 'GenericAd';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<GenericAdButton>;
  interactId: Scalars['String']['output'];
  isHidable: Scalars['Boolean']['output'];
  leftTag?: Maybe<GenericAdTag>;
  link?: Maybe<Scalars['String']['output']>;
  rightTag?: Maybe<GenericAdTag>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GenericAdButton = {
  __typename?: 'GenericAdButton';
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
  variant: ButtonVariant;
};

export type GenericAdPro = {
  __typename?: 'GenericAdPro';
  buttonText: Scalars['String']['output'];
  interactId: Scalars['String']['output'];
  points: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GenericAdShopOfTheWeek = {
  __typename?: 'GenericAdShopOfTheWeek';
  buttonX: Position;
  buttonY: Position;
  isFollowing: Scalars['Boolean']['output'];
  shopId: Scalars['String']['output'];
  shopImage: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type GenericAdTag = {
  __typename?: 'GenericAdTag';
  background: TagVariant;
  icon?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type GenericEqualGrid = {
  __typename?: 'GenericEqualGrid';
  id: Scalars['String']['output'];
  items: Array<DiscoverPageGenericItem>;
  link?: Maybe<Scalars['String']['output']>;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
};

export type GenericHorizontalList = {
  __typename?: 'GenericHorizontalList';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  items: Array<DiscoverPageGenericItem>;
  link?: Maybe<Scalars['String']['output']>;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  shouldDisplayPro: Scalars['Boolean']['output'];
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
};

export type GenericShopPage = {
  __typename?: 'GenericShopPage';
  page: TedoooPageResponse;
};

export type GetAchievementByIdResponse = {
  __typename?: 'GetAchievementByIdResponse';
  achievement: Achievement;
  subtext?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<AchievementGroupTag>;
};

export type GetChannelDescriptionResponse = {
  __typename?: 'GetChannelDescriptionResponse';
  description: Scalars['String']['output'];
};

export type GetChannelSuggestionsResponse = {
  __typename?: 'GetChannelSuggestionsResponse';
  channels: Array<ChatChannel>;
};

export type GetCurrencyResponse = {
  __typename?: 'GetCurrencyResponse';
  currency: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
};

export type GetCustomerPortalResult = {
  __typename?: 'GetCustomerPortalResult';
  url: Scalars['String']['output'];
};

export type GetDigitalFileUrlsResponse = {
  __typename?: 'GetDigitalFileUrlsResponse';
  email?: Maybe<Scalars['String']['output']>;
  files: Array<GetDigitalFileUrlsResponseItem>;
};

export type GetDigitalFileUrlsResponseItem = {
  __typename?: 'GetDigitalFileUrlsResponseItem';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GetDiscountsResponse = {
  __typename?: 'GetDiscountsResponse';
  discounts: Array<ShopDiscount>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type GetDiscoverPageResponse = {
  __typename?: 'GetDiscoverPageResponse';
  page: TedoooPageResponse;
  topButtons: Array<DiscoverPageTopButton>;
};

export type FilteredFeedResponse = {
  nicheInteractId: Scalars['String']['output'];
  page: TedoooPageResponse;
  isFollowing: Scalars['Boolean']['output'];
};

export type GetFileUploadUrlResponse = {
  __typename?: 'GetFileUploadUrlResponse';
  headers: Array<GetFileUploadUrlResponseHeader>;
  signedUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GetFileUploadUrlResponseHeader = {
  __typename?: 'GetFileUploadUrlResponseHeader';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GetMockupsForVisionResponse = {
  __typename?: 'GetMockupsForVisionResponse';
  colors: Array<Scalars['String']['output']>;
  mockupType: Scalars['String']['output'];
  models: Array<Model3D>;
  typeIconText: Scalars['String']['output'];
  typeIconUrl: Scalars['String']['output'];
};

export type GetMojoCommentsResponse = {
  __typename?: 'GetMojoCommentsResponse';
  comments: Array<MojoComment>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type GetMojoLinkResponse = {
  __typename?: 'GetMojoLinkResponse';
  url: Scalars['String']['output'];
};

export type GetMojoWhoLikedCommentUser = {
  __typename?: 'GetMojoWhoLikedCommentUser';
  avatar?: Maybe<Scalars['String']['output']>;
  following: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isPremium: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shopId?: Maybe<Scalars['String']['output']>;
  shopImage?: Maybe<Scalars['String']['output']>;
  shopName?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type GetMyAchievementsResponse = {
  __typename?: 'GetMyAchievementsResponse';
  groups: Array<AchievementGroup>;
};

export type GetOnBoardingCategoriesResponse = {
  __typename?: 'GetOnBoardingCategoriesResponse';
  categories: Array<OnBoardingCategory>;
};

export type GetOnBoardingVideosResponse = {
  __typename?: 'GetOnBoardingVideosResponse';
  videos: Array<OnboardingVideo>;
};

export type GetOnboardingGoalsResponse = {
  __typename?: 'GetOnboardingGoalsResponse';
  goals: Array<OnboardingGoal>;
};

export type GetOneTimePaymentInformationResponse = {
  __typename?: 'GetOneTimePaymentInformationResponse';
  link: Scalars['String']['output'];
  paymentMethodImages: Array<Scalars['String']['output']>;
};

export type GetOneTimePaymentLinkInformationResponse = {
  __typename?: 'GetOneTimePaymentLinkInformationResponse';
  avatar?: Maybe<Scalars['String']['output']>;
  currencyCode: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  isPaypalConnected: Scalars['Boolean']['output'];
  stripePaymentOption: Array<StripePaymentOption>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type GetPaymentCheckoutResponse = {
  __typename?: 'GetPaymentCheckoutResponse';
  paypal?: Maybe<Scalars['Boolean']['output']>;
  stripe?: Maybe<StripeOption>;
};

export type GetPaymentOptionsResponse = {
  __typename?: 'GetPaymentOptionsResponse';
  paypal?: Maybe<PaypalOption>;
  stripe?: Maybe<StripeOption>;
};

export type GetPortfolioOptionsResponse = {
  __typename?: 'GetPortfolioOptionsResponse';
  options: Array<PortfolioGridItem>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type GetProductBannerResponse = {
  __typename?: 'GetProductBannerResponse';
  banner?: Maybe<ProductBanner>;
};

export type GetProductByIdResponse = {
  __typename?: 'GetProductByIdResponse';
  item?: Maybe<ProductItemV2>;
  shopId?: Maybe<Scalars['String']['output']>;
};

export type GetProductsForShopResponse = {
  __typename?: 'GetProductsForShopResponse';
  products: Array<ProductItemV2>;
  sections: Array<ProductSectionItem>;
};

export type GetShippingProfilesResponse = {
  __typename?: 'GetShippingProfilesResponse';
  profiles: Array<ShippingProfile>;
};

export type GetStylePageDataResponse = {
  __typename?: 'GetStylePageDataResponse';
  items: Array<StylePageItem>;
};

export type GetSuggestedMockupsForVisionResponse = {
  __typename?: 'GetSuggestedMockupsForVisionResponse';
  mockups: Array<SuggestedMockupForVisionItem>;
};

export type GetUploadUrlResponse = {
  __typename?: 'GetUploadUrlResponse';
  image?: Maybe<GetUploadUrlResponseUrl>;
  video?: Maybe<GetUploadUrlResponseUrl>;
};

export type GetUploadUrlResponseUrl = {
  __typename?: 'GetUploadUrlResponseUrl';
  signedUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GetUserAchievementsResponse = {
  __typename?: 'GetUserAchievementsResponse';
  achievements: Array<NoStatusAchievement>;
};

export type GetVacationDetailsResponse = {
  __typename?: 'GetVacationDetailsResponse';
  autoReplyMessage?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  shopMessage?: Maybe<Scalars['String']['output']>;
};

export type GetVisionCategories = {
  __typename?: 'GetVisionCategories';
  categories: Array<Scalars['String']['output']>;
};

export type GetVisionPaymentOptionResponse = {
  __typename?: 'GetVisionPaymentOptionResponse';
  paypal: Scalars['Boolean']['output'];
  stripe?: Maybe<StripeOption>;
};

export type GetVisionPortfolioResponse = {
  __typename?: 'GetVisionPortfolioResponse';
  items: Array<VisionPortfolioItem>;
  paginationToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type GoalsUpdatedResponse = {
  __typename?: 'GoalsUpdatedResponse';
  activeTips: Array<OnboardingTip>;
  link: Scalars['String']['output'];
};

export type Gradient = {
  __typename?: 'Gradient';
  angle: Scalars['Int']['output'];
  colors: Array<GradientColor>;
};

export type GradientColor = {
  __typename?: 'GradientColor';
  color: Scalars['String']['output'];
  startPercent: Scalars['Float']['output'];
};

export type HeaderIcon = {
  __typename?: 'HeaderIcon';
  size: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type HeaderText = {
  __typename?: 'HeaderText';
  color: Scalars['String']['output'];
  font: Fonts;
  text: Scalars['String']['output'];
};

export type HorizontalListMojo = {
  __typename?: 'HorizontalListMojo';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  listInfo?: Maybe<ListData>;
  mojos: Array<MojoItem>;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  shouldDisplayPro: Scalars['Boolean']['output'];
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
};

export type HorizontalListProduct = {
  __typename?: 'HorizontalListProduct';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  listInfo?: Maybe<ListData>;
  products: Array<DiscoverPageProduct>;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  shouldDisplayPro: Scalars['Boolean']['output'];
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
};

export type HorizontalListShop = {
  __typename?: 'HorizontalListShop';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  listInfo?: Maybe<ListData>;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  shops: Array<DiscoverPageShop>;
  shouldDisplayPro: Scalars['Boolean']['output'];
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
};

export type HorizontalListVision = {
  __typename?: 'HorizontalListVision';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  listInfo?: Maybe<ListData>;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  shouldDisplayPro: Scalars['Boolean']['output'];
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
  visions: Array<DiscoverPageVision>;
};

export type HorizontalListVisionRequest = {
  __typename?: 'HorizontalListVisionRequest';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  listInfo?: Maybe<ListData>;
  rows: Scalars['Int']['output'];
  sectionDetails: Positioning;
  shouldDisplayPro: Scalars['Boolean']['output'];
  tags: Array<DiscoverPageTag>;
  title: Array<StyledText>;
  visions: Array<DiscoverPageVisionRequest>;
};

export type HorizontalPadding = {
  __typename?: 'HorizontalPadding';
  left: Scalars['Int']['output'];
  right: Scalars['Int']['output'];
};

export type IdResponse = {
  __typename?: 'IdResponse';
  id: Scalars['String']['output'];
};

export type IdableResponse = {
  __typename?: 'IdableResponse';
  id: Scalars['String']['output'];
};

export type ImageItem = {
  __typename?: 'ImageItem';
  color: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type InspirationInfo = {
  __typename?: 'InspirationInfo';
  externalLink?: Maybe<Scalars['String']['output']>;
  image: Scalars['String']['output'];
  innerLink: Scalars['String']['output'];
  interactId: Scalars['String']['output'];
  ratio: Scalars['Float']['output'];
};

export type InstantCheckoutResponse = {
  __typename?: 'InstantCheckoutResponse';
  itemPrice: Price;
  payments: Array<GetPaymentOptionsResponse>;
  productOwnerName: Scalars['String']['output'];
};

export enum InteractionType {
  ExternalLink = 'EXTERNAL_LINK',
  Shared = 'SHARED'
}

export type Label = {
  __typename?: 'Label';
  backgroundColor: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
  texts: Array<LabelText>;
};

export type LabelText = {
  __typename?: 'LabelText';
  text: Scalars['String']['output'];
};

export type LastComment = {
  __typename?: 'LastComment';
  avatar?: Maybe<Scalars['String']['output']>;
  date: Scalars['Long']['output'];
  didLike: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isVisionSeller: Scalars['Boolean']['output'];
  likes: Scalars['Int']['output'];
  premium: Scalars['Boolean']['output'];
  replies: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type LikeItem = {
  __typename?: 'LikeItem';
  avatar?: Maybe<Scalars['String']['output']>;
  date: Scalars['Long']['output'];
  fullName: Scalars['String']['output'];
  shopName?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type ListData = {
  __typename?: 'ListData';
  initialPaginationToken?: Maybe<Scalars['String']['output']>;
  isFilterable: Scalars['Boolean']['output'];
  listId: ListItem;
  screen: DiscoverScreen;
  title: Scalars['String']['output'];
};

export enum ListItem {
  AllCategories = 'ALL_CATEGORIES',
  EditorsPick = 'EDITORS_PICK',
  Following = 'FOLLOWING',
  FollowingShops = 'FOLLOWING_SHOPS',
  FromId = 'FROM_ID',
  GroupFeed = 'GROUP_FEED',
  Halloween = 'HALLOWEEN',
  Holidays = 'HOLIDAYS',
  Joy = 'JOY',
  MainFeed = 'MAIN_FEED',
  MainVisions = 'MAIN_VISIONS',
  MostSaved = 'MOST_SAVED',
  MyShops = 'MY_SHOPS',
  MyVisions = 'MY_VISIONS',
  PendingShops = 'PENDING_SHOPS',
  PendingVisions = 'PENDING_VISIONS',
  ProductItem = 'PRODUCT_ITEM',
  RecentlySaved = 'RECENTLY_SAVED',
  RecentlyViewed = 'RECENTLY_VIEWED',
  SearchProducts = 'SEARCH_PRODUCTS',
  SearchVisions = 'SEARCH_VISIONS',
  ShopSavedProducts = 'SHOP_SAVED_PRODUCTS',
  SimilarShops = 'SIMILAR_SHOPS',
  SuggestedShops = 'SUGGESTED_SHOPS',
  UserFeed = 'USER_FEED',
  UserShops = 'USER_SHOPS',
  VisionBuyerRequests = 'VISION_BUYER_REQUESTS'
}

export type LottieData = {
  __typename?: 'LottieData';
  loopAmount: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type MinMaxInts = {
  __typename?: 'MinMaxInts';
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export enum MinimumRateOptions {
  Five = 'five',
  Four = 'four',
  Three = 'three'
}

export type MockupDetails = {
  __typename?: 'MockupDetails';
  backgroundColor: Scalars['String']['output'];
  image: Scalars['String']['output'];
  model3D: Model3D;
};

export type MockupDetailsInput = {
  backgroundColor: Scalars['String']['input'];
  id: Scalars['String']['input'];
  image: Scalars['String']['input'];
};

export type Model3D = {
  __typename?: 'Model3D';
  extraLayerUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isColorable: Scalars['Boolean']['output'];
  location: Rect;
  rotation: Scalars['Float']['output'];
  scale: Scalars['Float']['output'];
  startAngle: Point3D;
  startLocation: Point3D;
  thumbnailUrl: Scalars['String']['output'];
  urlGlb: Scalars['String']['output'];
  /** Link to download the model */
  urlObj: Scalars['String']['output'];
};

export type Mojo = {
  __typename?: 'Mojo';
  comments: Scalars['Int']['output'];
  date: Scalars['Long']['output'];
  didLike: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  likes: Scalars['Int']['output'];
  product?: Maybe<MojoProduct>;
  ratio: Scalars['Float']['output'];
  text?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
  user: MojoUser;
};

export type MojoComment = {
  __typename?: 'MojoComment';
  avatar?: Maybe<Scalars['String']['output']>;
  date: Scalars['Long']['output'];
  didLike: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  likes: Scalars['Int']['output'];
  replies: Scalars['Int']['output'];
  taggedUsers: Array<TaggedUser>;
  text?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type MojoItem = {
  __typename?: 'MojoItem';
  avatar?: Maybe<Scalars['String']['output']>;
  comments: Scalars['Int']['output'];
  date: Scalars['Long']['output'];
  didLike: Scalars['Boolean']['output'];
  following: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  likes: Scalars['Int']['output'];
  premium: Scalars['Boolean']['output'];
  productDetails?: Maybe<MojoPostProductDetails>;
  ratio: Scalars['Float']['output'];
  sectionDetails: Positioning;
  text?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
  videoUrl: Scalars['String']['output'];
};

export type MojoPostProductDetails = {
  __typename?: 'MojoPostProductDetails';
  image: Scalars['String']['output'];
  price: Price;
  productId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MojoProduct = {
  __typename?: 'MojoProduct';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  price: Price;
};

export type MojoUser = {
  __typename?: 'MojoUser';
  avatar?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isFollowing: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type MoreBadge = {
  __typename?: 'MoreBadge';
  icon?: Maybe<Scalars['String']['output']>;
  static?: Maybe<MoreStaticBadge>;
  text?: Maybe<Scalars['String']['output']>;
  variant: BadgeVariant;
};

export type MorePage = {
  __typename?: 'MorePage';
  backgroundColor: Gradient;
  displayAchievements: Scalars['Boolean']['output'];
  items: Array<MorePageItem>;
  time: Scalars['Long']['output'];
};

export type MorePageDivider = {
  __typename?: 'MorePageDivider';
  color: Scalars['String']['output'];
  height: Scalars['Int']['output'];
};

export type MorePageHeader = {
  __typename?: 'MorePageHeader';
  badge?: Maybe<MoreBadge>;
  tag?: Maybe<MorePageTag>;
  text: Scalars['String']['output'];
};

export type MorePageHorizontalList = {
  __typename?: 'MorePageHorizontalList';
  contentInset?: Maybe<HorizontalPadding>;
  innerItemSpacing: Scalars['Int']['output'];
  items: Array<MorePageHorizontalListItem>;
};

export type MorePageHorizontalListItem = {
  __typename?: 'MorePageHorizontalListItem';
  badge?: Maybe<MoreBadge>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  tag?: Maybe<MorePageTag>;
  text: Scalars['String']['output'];
};

export type MorePageItem = {
  __typename?: 'MorePageItem';
  horizontalPadding?: Maybe<HorizontalPadding>;
  id: Scalars['String']['output'];
  item: MorePageItemInner;
};

export type MorePageItemInner = MorePageDivider | MorePageHeader | MorePageHorizontalList | MorePageSection | MorePageShop | MorePageSpacer | MorePageStaticItem;

export type MorePageSection = {
  __typename?: 'MorePageSection';
  badge?: Maybe<MoreBadge>;
  icon?: Maybe<Scalars['String']['output']>;
  items: Array<MorePageSubItem>;
  link?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<MorePageTag>;
  text: Scalars['String']['output'];
};

export type MorePageShop = {
  __typename?: 'MorePageShop';
  avatar?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  shopId: Scalars['String']['output'];
  subtext: Scalars['String']['output'];
  title: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type MorePageSpacer = {
  __typename?: 'MorePageSpacer';
  height: Scalars['Int']['output'];
};

export type MorePageStaticItem = {
  __typename?: 'MorePageStaticItem';
  extras?: Maybe<Scalars['String']['output']>;
  key: MorePageStaticItemKey;
};

export enum MorePageStaticItemKey {
  AdminSection = 'ADMIN_SECTION',
  Logout = 'LOGOUT',
  SelfUserSection = 'SELF_USER_SECTION',
  TipLocation = 'TIP_LOCATION'
}

export type MorePageSubItem = {
  __typename?: 'MorePageSubItem';
  badge?: Maybe<MoreBadge>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  tag?: Maybe<MorePageTag>;
  text: Scalars['String']['output'];
};

export type MorePageTag = {
  __typename?: 'MorePageTag';
  image?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  variant: TagVariant;
};

export enum MoreStaticBadge {
  ChannelsUnread = 'CHANNELS_UNREAD',
  ChatUnread = 'CHAT_UNREAD',
  GroupsUnread = 'GROUPS_UNREAD',
  OrdersUnread = 'ORDERS_UNREAD'
}

export type Mutation = {
  __typename?: 'Mutation';
  Decline: SuccessResponse;
  EditCustomOffer: SuccessResponse;
  SendCustomOffer: IdableResponse;
  /** Return error if not pro */
  UpdateCustomOfferAcceptance: SuccessResponse;
  acceptTransaction: SuccessResponse;
  addProductToSection: SuccessResponse;
  /** Add a product to cart */
  addToCart: IdResponse;
  /** Admin only request to approve vision sellers */
  approveVisionSeller: SuccessResponse;
  batchUpdateMockupForVision: SuccessResponse;
  changeVisionVisibility: SuccessResponse;
  chatGroupPostsRead: SuccessResponse;
  createCouponCode: IdResponse;
  createPaymentIntentStripe: CreatePaymentIntentResult;
  createPaymentIntentStripeForProduct: CreatePaymentIntentResult;
  createPaypalCheckout: PaypalOption;
  /** Start PayPal checkout flow for a vision offer */
  createPaypalCheckoutForVisionOffer: PaypalOption;
  createPortfolioItem: IdResponse;
  createProductSection: CreateProductSectionResponse;
  createSale: IdResponse;
  /** Start Stripe checkout flow for a vision offer */
  createStripeCheckoutForVisionOffer: CreatePaymentIntentResult;
  deactivateCouponCode: SuccessResponse;
  deactivateSale: SuccessResponse;
  declineTransaction: SuccessResponse;
  declineVisionOffer: SuccessResponse;
  /** Clears a cart completely for a shop */
  deleteCart: SuccessResponse;
  deleteComment: SuccessResponse;
  deleteCouponCode: SuccessResponse;
  deleteMojo: SuccessResponse;
  deleteMojoComment: SuccessResponse;
  deletePortfolioItem: SuccessResponse;
  deleteProductSection: SuccessResponse;
  deleteSale: SuccessResponse;
  deleteVisionRequest: SuccessResponse;
  didSetTedoooFrame: SuccessResponse;
  editCustomizationByCartItemId: SuccessResponse;
  editPortfolioImage: SuccessResponse;
  editSection: SuccessResponse;
  /** Edits an offer */
  editVisionOffer: SuccessResponse;
  editVisionRequest: RequestToBuyResponse;
  finishOnboarding: GoalsUpdatedResponse;
  generateCheckoutIdForOneTimePayment: IdResponse;
  generateCheckoutIdForProduct: IdResponse;
  generateVision: GenerateVisionResponse;
  hideByInteractId: SuccessResponse;
  hideVisionRequest: SuccessResponse;
  interactedWith: SuccessResponse;
  joinChatChannel: SuccessResponse;
  leaveChatChannel: SuccessResponse;
  likeMojoComment: SuccessResponse;
  manageProductSections: SuccessResponse;
  postComment: IdableResponse;
  postImpression: SuccessResponse;
  postMojo: IdableResponse;
  postMojoComment: IdableResponse;
  purchaseCreditsAndroid: SuccessResponse;
  purchaseCreditsIos: SuccessResponse;
  /** Remove a product from cart - when quantity is null - remove all */
  removeFromCart: SuccessResponse;
  removeProductFromSection: SuccessResponse;
  /** Removes all unavailable items from cart */
  removeUnavailableFromCart: SuccessResponse;
  /** A seller wants to renew an expired offer */
  renewVisionOffer: SuccessResponse;
  /** A buyer wants to renew an expired offer */
  renewVisionRequest: RequestToBuyResponse;
  reorderSection: SuccessResponse;
  report: SuccessResponse;
  reportMessage: SuccessResponse;
  reportMojo: SuccessResponse;
  requestToBecomeVisionSeller: SuccessResponse;
  requestToBuyVision: RequestToBuyResponse;
  seenAchievementDrawer: SuccessResponse;
  sendDigitalFilesEmail: SuccessResponse;
  sendVisionOffer: SendOfferResponse;
  setLike: SuccessResponse;
  setLikeMojo: SuccessResponse;
  setLikeProduct: SuccessResponse;
  setUserTimezone: SuccessResponse;
  startConversationWithSupportAgent: StartConversationWithSupportAgentResponse;
  startSubscriptionFlow: SubscriptionFlowResult;
  startVisionCheckoutSession: StartCheckoutSessionResponse;
  stopShowingVisionRequestBanner: SuccessResponse;
  submitStylePageData: SuccessResponse;
  unexpireMessage: SuccessResponse;
  updateAppleReceiptData: SuccessResponse;
  updateCouponCode: SuccessResponse;
  updateMockupForVision: SuccessResponse;
  updateSale: SuccessResponse;
  updateVacationDetails: SuccessResponse;
  viewedProduct: SuccessResponse;
  /** A seller wants to withdraw his offer */
  withdrawVisionOffer: SuccessResponse;
};


export type MutationDeclineArgs = {
  id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};


export type MutationEditCustomOfferArgs = {
  offer: SendCustomOfferInput;
  offerId: Scalars['String']['input'];
};


export type MutationSendCustomOfferArgs = {
  buyerId: Scalars['String']['input'];
  offer: SendCustomOfferInput;
};


export type MutationUpdateCustomOfferAcceptanceArgs = {
  accept: Scalars['Boolean']['input'];
};


export type MutationAcceptTransactionArgs = {
  transactionId: Scalars['String']['input'];
};


export type MutationAddProductToSectionArgs = {
  productIds: Array<Scalars['String']['input']>;
  sectionId: Scalars['String']['input'];
};


export type MutationAddToCartArgs = {
  request: AddToCartRequest;
};


export type MutationApproveVisionSellerArgs = {
  id: Scalars['String']['input'];
  status: ApproveVisionSellerStatus;
};


export type MutationBatchUpdateMockupForVisionArgs = {
  requests: Array<BatchMockupDetailsInput>;
};


export type MutationChangeVisionVisibilityArgs = {
  generationId: Scalars['String']['input'];
  isPublic: Scalars['Boolean']['input'];
};


export type MutationChatGroupPostsReadArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationCreateCouponCodeArgs = {
  couponCode: CouponCodeInput;
  shopId: Scalars['String']['input'];
};


export type MutationCreatePaymentIntentStripeArgs = {
  checkoutId: Scalars['String']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  shippingRateId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePaymentIntentStripeForProductArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
};


export type MutationCreatePaypalCheckoutArgs = {
  checkoutId: Scalars['String']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  shippingRateId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePaypalCheckoutForVisionOfferArgs = {
  offerId: Scalars['String']['input'];
  shippingRateId: Scalars['String']['input'];
};


export type MutationCreatePortfolioItemArgs = {
  id: Scalars['String']['input'];
  lateCreation: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  realImage: Scalars['String']['input'];
};


export type MutationCreateProductSectionArgs = {
  productIds: Array<Scalars['String']['input']>;
  sectionName: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type MutationCreateSaleArgs = {
  sale: SaleInput;
  shopId: Scalars['String']['input'];
};


export type MutationCreateStripeCheckoutForVisionOfferArgs = {
  offerId: Scalars['String']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  shippingRateId: Scalars['String']['input'];
};


export type MutationDeactivateCouponCodeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeactivateSaleArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeclineTransactionArgs = {
  transactionId: Scalars['String']['input'];
};


export type MutationDeclineVisionOfferArgs = {
  offerId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};


export type MutationDeleteCartArgs = {
  shopId: Scalars['String']['input'];
};


export type MutationDeleteCommentArgs = {
  interactId: Scalars['String']['input'];
};


export type MutationDeleteCouponCodeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMojoArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMojoCommentArgs = {
  commentId: Scalars['String']['input'];
};


export type MutationDeletePortfolioItemArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteProductSectionArgs = {
  sectionId: Scalars['String']['input'];
};


export type MutationDeleteSaleArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVisionRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type MutationEditCustomizationByCartItemIdArgs = {
  cartItemId: Scalars['String']['input'];
  customization: CustomizationInput;
};


export type MutationEditPortfolioImageArgs = {
  id: Scalars['String']['input'];
  newNotes?: InputMaybe<Scalars['String']['input']>;
  realImage: Scalars['String']['input'];
};


export type MutationEditSectionArgs = {
  deleteProductIds: Array<Scalars['String']['input']>;
  sectionId: Scalars['String']['input'];
  sectionName: Scalars['String']['input'];
};


export type MutationEditVisionOfferArgs = {
  offerId: Scalars['String']['input'];
  request: SendOfferRequest;
};


export type MutationEditVisionRequestArgs = {
  request: RequestToBuyRequest;
  requestId: Scalars['String']['input'];
};


export type MutationFinishOnboardingArgs = {
  channelIds: Array<Scalars['String']['input']>;
  groupsToJoin: Array<Scalars['String']['input']>;
  mainGoal: Scalars['String']['input'];
  shopsToFollow: Array<Scalars['String']['input']>;
  subGoals: Array<Scalars['String']['input']>;
};


export type MutationGenerateCheckoutIdForOneTimePaymentArgs = {
  description: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  priceAmount: Scalars['Long']['input'];
  receiverId: Scalars['String']['input'];
};


export type MutationGenerateCheckoutIdForProductArgs = {
  customization?: InputMaybe<CustomizationInput>;
  productId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationGenerateVisionArgs = {
  generateMockupWithType?: InputMaybe<Scalars['String']['input']>;
  generateWideVision?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};


export type MutationHideByInteractIdArgs = {
  interactId: Scalars['String']['input'];
};


export type MutationHideVisionRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type MutationInteractedWithArgs = {
  interactId: Scalars['String']['input'];
  interactionType: InteractionType;
};


export type MutationJoinChatChannelArgs = {
  id: Scalars['String']['input'];
};


export type MutationLeaveChatChannelArgs = {
  id: Scalars['String']['input'];
};


export type MutationLikeMojoCommentArgs = {
  commentId: Scalars['String']['input'];
  like: Scalars['Boolean']['input'];
};


export type MutationManageProductSectionsArgs = {
  additionSectionIds: Array<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  removalSectionIds: Array<Scalars['String']['input']>;
};


export type MutationPostCommentArgs = {
  image?: InputMaybe<Scalars['String']['input']>;
  interactId: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPostImpressionArgs = {
  postId: Scalars['String']['input'];
};


export type MutationPostMojoArgs = {
  request: NewMojoRequest;
};


export type MutationPostMojoCommentArgs = {
  image?: InputMaybe<Scalars['String']['input']>;
  mojoId: Scalars['String']['input'];
  replyToId?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPurchaseCreditsAndroidArgs = {
  offerId: Scalars['String']['input'];
  paymentToken: Scalars['String']['input'];
};


export type MutationPurchaseCreditsIosArgs = {
  receiptData: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};


export type MutationRemoveFromCartArgs = {
  cartItemId: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRemoveProductFromSectionArgs = {
  productId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
};


export type MutationRemoveUnavailableFromCartArgs = {
  addToWishlist: Scalars['Boolean']['input'];
  shopId: Scalars['String']['input'];
};


export type MutationRenewVisionOfferArgs = {
  offerId: Scalars['String']['input'];
};


export type MutationRenewVisionRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type MutationReorderSectionArgs = {
  itemId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
  to: Scalars['Int']['input'];
};


export type MutationReportArgs = {
  extra?: InputMaybe<Scalars['String']['input']>;
  interactId: Scalars['String']['input'];
  option: Scalars['String']['input'];
};


export type MutationReportMessageArgs = {
  extra?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['String']['input'];
  option: Scalars['String']['input'];
};


export type MutationReportMojoArgs = {
  mojoId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestToBuyVisionArgs = {
  request: RequestToBuyRequest;
};


export type MutationSeenAchievementDrawerArgs = {
  id: Scalars['String']['input'];
};


export type MutationSendDigitalFilesEmailArgs = {
  email: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};


export type MutationSendVisionOfferArgs = {
  request: SendOfferRequest;
};


export type MutationSetLikeArgs = {
  interactId: Scalars['String']['input'];
  like: Scalars['Boolean']['input'];
};


export type MutationSetLikeMojoArgs = {
  id: Scalars['String']['input'];
  like: Scalars['Boolean']['input'];
};


export type MutationSetLikeProductArgs = {
  like: Scalars['Boolean']['input'];
  productId: Scalars['TedoooID']['input'];
};


export type MutationSetUserTimezoneArgs = {
  timezoneDiffMin: Scalars['Int']['input'];
};


export type MutationStartSubscriptionFlowArgs = {
  subscriptionType: SubscriptionType;
};


export type MutationStartVisionCheckoutSessionArgs = {
  offerId: Scalars['String']['input'];
};


export type MutationSubmitStylePageDataArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationUnexpireMessageArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateAppleReceiptDataArgs = {
  receipt: Scalars['String']['input'];
};


export type MutationUpdateCouponCodeArgs = {
  couponCode: CouponCodeInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateMockupForVisionArgs = {
  mockupDetails?: InputMaybe<MockupDetailsInput>;
  visionId: Scalars['String']['input'];
};


export type MutationUpdateSaleArgs = {
  id: Scalars['String']['input'];
  sale: SaleInput;
};


export type MutationUpdateVacationDetailsArgs = {
  request?: InputMaybe<UpdateVacationRequest>;
};


export type MutationViewedProductArgs = {
  productId: Scalars['TedoooID']['input'];
};


export type MutationWithdrawVisionOfferArgs = {
  offerId: Scalars['String']['input'];
};

export type MyRequestsResponse = {
  __typename?: 'MyRequestsResponse';
  paginationToken?: Maybe<Scalars['String']['output']>;
  requests: Array<VisionRequest>;
};

export type NewMojoRequest = {
  durationMs: Scalars['Int']['input'];
  productId?: InputMaybe<Scalars['String']['input']>;
  ratio: Scalars['Float']['input'];
  tags: Array<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  thumbnail: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type NoStatusAchievement = {
  __typename?: 'NoStatusAchievement';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  isRare: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type OfferTag = {
  __typename?: 'OfferTag';
  text: Scalars['String']['output'];
  variant: TagVariant;
};

export type OffersForRequestResponse = {
  __typename?: 'OffersForRequestResponse';
  offers: Array<VisionOffer>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type OnBoardingCategory = {
  __typename?: 'OnBoardingCategory';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type OnboardingGoal = {
  __typename?: 'OnboardingGoal';
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  link?: Maybe<OnboardingGoalLink>;
  text: Scalars['String']['output'];
};

export type OnboardingGoalLink = {
  __typename?: 'OnboardingGoalLink';
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type OnboardingTip = {
  __typename?: 'OnboardingTip';
  location: OnboardingTipLocation;
  text: Scalars['String']['output'];
};

export enum OnboardingTipLocation {
  CreateVision = 'CreateVision',
  EnterChannels = 'EnterChannels',
  FeedGroups = 'FeedGroups',
  SearchExplore = 'SearchExplore'
}

export type OnboardingVideo = {
  __typename?: 'OnboardingVideo';
  step: OnboardingVideoStep;
  videoUrl: Scalars['String']['output'];
};

export enum OnboardingVideoStep {
  Chat = 'Chat',
  Craft = 'Craft',
  Shop = 'Shop',
  Social = 'Social'
}

export type Order = {
  __typename?: 'Order';
  date: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  itemsImages: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  partnerInfo: PartnerInfo;
  price: Price;
  sellerId: Scalars['String']['output'];
  status: OrderStatus;
};

export type OrderHistoryResponse = {
  __typename?: 'OrderHistoryResponse';
  orders: Array<Order>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export enum OrderStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Interested = 'Interested',
  Offered = 'Offered',
  PaymentReceived = 'PaymentReceived',
  Requested = 'Requested',
  Shipped = 'Shipped'
}

export type Padding = {
  __typename?: 'Padding';
  bottom: Scalars['Int']['output'];
  left: Scalars['Int']['output'];
  right: Scalars['Int']['output'];
  top: Scalars['Int']['output'];
};

export type PaginatedCategoriesResult = {
  __typename?: 'PaginatedCategoriesResult';
  categories: Array<CategoryItem>;
  paginationToken?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Long']['output']>;
};

export type PaginatedItemsResult = {
  __typename?: 'PaginatedItemsResult';
  paginationToken?: Maybe<Scalars['String']['output']>;
  products: Array<ProductItem>;
  totalCount?: Maybe<Scalars['Long']['output']>;
};

export type PaginatedMojoResponse = {
  __typename?: 'PaginatedMojoResponse';
  data: Array<Mojo>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type PaginatedShopsResult = {
  __typename?: 'PaginatedShopsResult';
  paginationToken?: Maybe<Scalars['String']['output']>;
  shops: Array<ShopItem>;
  totalCount?: Maybe<Scalars['Long']['output']>;
};

export type PaginatedVisionResult = {
  __typename?: 'PaginatedVisionResult';
  paginationToken?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Long']['output']>;
  visions: Array<DiscoverPageVision>;
};

export type PaginatedWhoLikedCommentResponse = {
  __typename?: 'PaginatedWhoLikedCommentResponse';
  data: Array<GetMojoWhoLikedCommentUser>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type PartnerInfo = {
  __typename?: 'PartnerInfo';
  avatar?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type PaypalOption = {
  __typename?: 'PaypalOption';
  checkoutId?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
};

export type Point = {
  __typename?: 'Point';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type Point3D = {
  __typename?: 'Point3D';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
  z: Scalars['Float']['output'];
};

export type PopupDetails = AchievementAchievedPopupDetails | FavoritesPopupDetails | VisionPopupDetails | VisionRequestNewOffersPopupDetails;

export type PortfolioGridItem = PortfolioHeader | PortfolioOption;

export type PortfolioHeader = {
  __typename?: 'PortfolioHeader';
  text: Scalars['String']['output'];
};

export type PortfolioOption = {
  __typename?: 'PortfolioOption';
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  optionType: Scalars['String']['output'];
};

export type PortfolioVision = {
  __typename?: 'PortfolioVision';
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
};

export type Position = {
  __typename?: 'Position';
  origin: ButtonOrigin;
  value: Scalars['Float']['output'];
};

export type Positioning = {
  __typename?: 'Positioning';
  bottomSpacing: Scalars['Int']['output'];
  leftSpacing: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  rightSpacing: Scalars['Int']['output'];
  topSpacing: Scalars['Int']['output'];
  withBottomSeparator: Scalars['Boolean']['output'];
  withTopSeparator: Scalars['Boolean']['output'];
};

export type Price = {
  __typename?: 'Price';
  currency: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  price: Scalars['Long']['output'];
};

export type ProcessingTime = {
  __typename?: 'ProcessingTime';
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type ProductBanner = {
  __typename?: 'ProductBanner';
  backgroundColor: Scalars['String']['output'];
  borderColor: Scalars['String']['output'];
  borderWidth: Scalars['Float']['output'];
  description: Array<StyledText>;
  gradient?: Maybe<Gradient>;
  id: Scalars['String']['output'];
  rightImage: Scalars['String']['output'];
  title: Array<StyledText>;
};

export type ProductItem = {
  __typename?: 'ProductItem';
  convertedFrom?: Maybe<Price>;
  currency: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  description: Scalars['String']['output'];
  didLike: Scalars['Boolean']['output'];
  id: Scalars['TedoooID']['output'];
  imageUrl: Scalars['String']['output'];
  isDigital: Scalars['Boolean']['output'];
  labels: Array<Label>;
  originalPrice?: Maybe<Scalars['Long']['output']>;
  price: Scalars['Long']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Field no longer supported */
  saveDate: Scalars['Long']['output'];
  selectedCategory: Array<Scalars['String']['output']>;
  shopId: Scalars['TedoooID']['output'];
  shopName: Scalars['String']['output'];
  tariff?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalLikes: Scalars['Int']['output'];
  totalReviews: Scalars['Int']['output'];
  userId: Scalars['TedoooID']['output'];
  variants: Array<Scalars['String']['output']>;
};

export type ProductItemV2 = {
  __typename?: 'ProductItemV2';
  aiImage?: Maybe<Scalars['String']['output']>;
  convertedFrom?: Maybe<Price>;
  description: Scalars['String']['output'];
  didLike: Scalars['Boolean']['output'];
  hasDigitalFiles: Scalars['Boolean']['output'];
  hasInstantCheckout: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isDigital: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  keywords: Array<Scalars['String']['output']>;
  labels: Array<Label>;
  originalPrice?: Maybe<Scalars['Long']['output']>;
  price: Price;
  quantity?: Maybe<Scalars['Int']['output']>;
  selectedCategory: Array<Scalars['String']['output']>;
  shippingInformation?: Maybe<ProductShippingInformation>;
  tariff?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalLikes: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
  vacationInfo?: Maybe<VacationInfo>;
  variants: Array<Scalars['String']['output']>;
};

export type ProductSectionItem = {
  __typename?: 'ProductSectionItem';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  productIds: Array<Scalars['String']['output']>;
};

export type ProductShippingInformation = {
  __typename?: 'ProductShippingInformation';
  countries: Array<Scalars['String']['output']>;
  dimensions: Dimensions;
  originCountry: Scalars['String']['output'];
  profileId: Scalars['String']['output'];
  returnPolicy: ReturnsPolicy;
  workingDays: MinMaxInts;
};

export enum ProductType {
  Digital = 'Digital',
  Physical = 'Physical'
}

export type Query = {
  __typename?: 'Query';
  canShipToDefaultAddress: CanShipToAddressResponse;
  checkCouponCode: CheckoutCouponCodeResponse;
  checkCustomOfferAcceptance: CheckCustomOfferAcceptanceResponse;
  checkLinkStatus: CheckLinkStatusResponse;
  checkVerificationStatus: CheckVerificationStatusResponse;
  decodeProductCsv: DecodeProductCsvResponse;
  fetchMorePage: MorePage;
  getAcceptedOffers: AcceptedVisionOfferPaginatedResponse;
  getAchievementById: GetAchievementByIdResponse;
  getAppConfig: AppConfigResponse;
  getAutocomplete: AutocompleteResponse;
  /** Does not require token */
  getAvailableRatesForCheckout: CheckoutResponse;
  getBuyerRequestsCount: BuyerRequestsTabCountResponse;
  /** Called when tapping the cart button */
  getCart: CartResponse;
  /** Called initially to get the badge count */
  getCartCount: CartCountResponse;
  getCategories: PaginatedCategoriesResult;
  getChannelDescription: GetChannelDescriptionResponse;
  getChannelSuggestions: GetChannelSuggestionsResponse;
  getChatUserInformation: ChatUserInformation;
  getCheckoutIdStatus: CheckoutIdStatusResponse;
  getCommentsForId: FindResponseCommentItem;
  getCustomOfferById: CustomOffer;
  getCustomerPortal: GetCustomerPortalResult;
  getDigitalFileUrlsForOrder: GetDigitalFileUrlsResponse;
  getDiscountById: FindOneDiscount;
  getDiscountShareDetails: DiscountShareDetails;
  getDiscountsForShop: GetDiscountsResponse;
  getDiscoverPage: GetDiscoverPageResponse;
  getDiscoverPageData: DiscoverPageResult;
  getDiscoverPageDataWeb: DiscoverPageResultWeb;
  getDiscoverShopPage: GenericShopPage;
  getFeedPage: TedoooPageResponse;
  getFilteredFeedPage: TedoooPageResponse;
  getFilteredFeedPage2: FilteredFeedResponse;
  getGenerateVisionLoadingImages: GetGenerateVisionLoadingImagesResponse;
  getGenericPage: TedoooPageResponse;
  getGenericShopPage: GenericShopPage;
  getInstantCheckout: InstantCheckoutResponse;
  getInstantCheckoutForCheckoutId: CheckoutResponse;
  getLikesForId: FindResponseLikeItem;
  getMockupsForVision: GetMockupsForVisionResponse;
  getMojoComments: GetMojoCommentsResponse;
  getMojoLink: GetMojoLinkResponse;
  getMojos: PaginatedMojoResponse;
  getMyAchievements: GetMyAchievementsResponse;
  /** Get requests that I wish people would craft for me */
  getMyRequests: MyRequestsResponse;
  getMyVisionPortfolio: GetVisionPortfolioResponse;
  getOffersForRequest: OffersForRequestResponse;
  getOnBoardingCategories: GetOnBoardingCategoriesResponse;
  getOnBoardingVideos: GetOnBoardingVideosResponse;
  getOnboardingGoals: GetOnboardingGoalsResponse;
  getOneTimePaymentInformation: GetOneTimePaymentInformationResponse;
  /** Does not require token */
  getOneTimePaymentLinkInformation: GetOneTimePaymentLinkInformationResponse;
  getOrderHistory: OrderHistoryResponse;
  getPortfolioOptions: GetPortfolioOptionsResponse;
  getProductBanner: GetProductBannerResponse;
  getProductById: GetProductByIdResponse;
  getProductCsvProgress: DecodeProductCsvProgressResponse;
  getProductsByListId: PaginatedItemsResult;
  getProductsForShop: GetProductsForShopResponse;
  getRecommendedShippingPriceForDimensions: ShippingRate;
  getReferralDetails: ReferralDetailsReward;
  getRequestLink: RequestLinkResponse;
  getSaleForShop: FindOneSale;
  /** @deprecated Field no longer supported */
  getSavedItemsForShop: PaginatedItemsResult;
  getSavedItemsForUser: PaginatedItemsResult;
  /** Get requests that I can send offers to */
  getSellerRequests: SellerRequestsResponse;
  getSellerRequestsCounts: SellerRequestsTabCountResponse;
  getShareLink: ShareLinkResponse;
  /** Return all the shipping profiles for the current logged-in user */
  getShippingProfiles: GetShippingProfilesResponse;
  /** Get best value rate an address profile id combination */
  getShippingRateForAddressProfileId: ShippingEstimationResponse;
  /** Get best value rate for checkout id user id combination */
  getShippingRateForCheckout: ShippingEstimationResponse;
  getShippingRatesForVisionOffer: ShippingRatesForVisionOfferResponse;
  getShopById: FindOneResponseShop;
  getShopsByListId: PaginatedShopsResult;
  getSignedUrl: GetUploadUrlResponse;
  getSmartTags: SmartTagsResponse;
  getSpecificInspirationPage: SpecificInspirationPage;
  getStylePageData: GetStylePageDataResponse;
  getSuggestedMockupsForVision: GetSuggestedMockupsForVisionResponse;
  getTransactionHistory: TransactionHistoryResponse;
  getTransactionHistoryCounts: TransactionHistoryCounts;
  getUploadUrl: GetFileUploadUrlResponse;
  getUserAchievements: GetUserAchievementsResponse;
  getUsers: UserResponse;
  getVacationDetails: GetVacationDetailsResponse;
  getVisionById: VisionByIdResponse;
  /** Gets the categories for the visions */
  getVisionCategories: GetVisionCategories;
  getVisionCreditOffers: VisionCreditOfferResponse;
  /** Get the currency to display for visions for the logged-in user */
  getVisionCurrency: GetCurrencyResponse;
  getVisionInformationForUser: VisionInformationForUserResponse;
  getVisionLink: VisionLinkResponse;
  /** Returns a vision offer */
  getVisionOfferById: VisionOffer;
  getVisionOfferForRequest: VisionOffer;
  getVisionOffers: SellerOfferResponse;
  getVisionPortfolioByUserId: GetVisionPortfolioResponse;
  /** Returns a vision request */
  getVisionRequestById: VisionRequest;
  /** Returns the request details for the offer with the offer ID */
  getVisionRequestForOffer: VisionRequest;
  getVisionWelcomeOffer: VisionWelcomeOfferResponse;
  getVisionsByListId: PaginatedVisionResult;
  getWhoLikedComment: PaginatedWhoLikedCommentResponse;
  noReply: SuccessResponse;
  queryCouponCodeAvailability: SuccessResponse;
  queryReferralCode: CheckReferralCodeResponse;
  searchGroups: SearchGroupsResponse;
  searchProducts: SearchProductsResponse;
  shouldPresentWideOffer: ShouldPresentWideOfferResponse;
};


export type QueryCanShipToDefaultAddressArgs = {
  checkoutId: Scalars['String']['input'];
};


export type QueryCheckCouponCodeArgs = {
  cartPrice: Scalars['Long']['input'];
  couponCode: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type QueryCheckLinkStatusArgs = {
  link: Scalars['String']['input'];
};


export type QueryCheckVerificationStatusArgs = {
  userId: Scalars['String']['input'];
};


export type QueryDecodeProductCsvArgs = {
  csvData: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
};


export type QueryGetAcceptedOffersArgs = {
  isBuyer: Scalars['Boolean']['input'];
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAchievementByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAutocompleteArgs = {
  input?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAvailableRatesForCheckoutArgs = {
  checkoutId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  shippingAddress?: InputMaybe<ShippingAddressFullInput>;
};


export type QueryGetCategoriesArgs = {
  listId: ListItem;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetChannelDescriptionArgs = {
  channelId: Scalars['String']['input'];
};


export type QueryGetChatUserInformationArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetCheckoutIdStatusArgs = {
  checkoutId: Scalars['String']['input'];
};


export type QueryGetCommentsForIdArgs = {
  interactId: Scalars['String']['input'];
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCustomOfferByIdArgs = {
  offerId: Scalars['String']['input'];
};


export type QueryGetDigitalFileUrlsForOrderArgs = {
  orderId: Scalars['String']['input'];
};


export type QueryGetDiscountByIdArgs = {
  discountId: Scalars['String']['input'];
};


export type QueryGetDiscountShareDetailsArgs = {
  discountId: Scalars['String']['input'];
};


export type QueryGetDiscountsForShopArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  shopId: Scalars['String']['input'];
};


export type QueryGetDiscoverPageArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  screenWidth: Scalars['Int']['input'];
};


export type QueryGetDiscoverPageDataArgs = {
  listId?: InputMaybe<ListItem>;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  screenHeight: Scalars['Int']['input'];
  screenWidth: Scalars['Int']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetDiscoverPageDataWebArgs = {
  listId?: InputMaybe<ListItem>;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDiscoverShopPageArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  screenWidth: Scalars['Int']['input'];
};


export type QueryGetFeedPageArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  screenWidth: Scalars['Int']['input'];
};


export type QueryGetFilteredFeedPageArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  screenWidth: Scalars['Int']['input'];
};


export type QueryGetGenericPageArgs = {
  paginationToken: Scalars['String']['input'];
  screenWidth: Scalars['Int']['input'];
};


export type QueryGetGenericShopPageArgs = {
  paginationToken: Scalars['String']['input'];
  screenWidth: Scalars['Int']['input'];
};


export type QueryGetInstantCheckoutArgs = {
  productId: Scalars['String']['input'];
};


export type QueryGetInstantCheckoutForCheckoutIdArgs = {
  checkoutId: Scalars['String']['input'];
};


export type QueryGetLikesForIdArgs = {
  interactId: Scalars['String']['input'];
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMockupsForVisionArgs = {
  mockupType?: InputMaybe<Scalars['String']['input']>;
  visionId: Scalars['String']['input'];
};


export type QueryGetMojoCommentsArgs = {
  id: Scalars['String']['input'];
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  repliesFor?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMojoLinkArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMojosArgs = {
  listId: Scalars['String']['input'];
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyRequestsArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyVisionPortfolioArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOffersForRequestArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['String']['input'];
  sort?: InputMaybe<VisionSorts>;
};


export type QueryGetOnBoardingVideosArgs = {
  version: Scalars['Int']['input'];
};


export type QueryGetOneTimePaymentLinkInformationArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrderHistoryArgs = {
  fetchMode: TransactionFetchMode;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPortfolioOptionsArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetProductBannerArgs = {
  productId: Scalars['String']['input'];
};


export type QueryGetProductByIdArgs = {
  productId: Scalars['String']['input'];
};


export type QueryGetProductCsvProgressArgs = {
  requestId: Scalars['String']['input'];
};


export type QueryGetProductsByListIdArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  listId: ListItem;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetProductsForShopArgs = {
  shopId: Scalars['TedoooID']['input'];
};


export type QueryGetRecommendedShippingPriceForDimensionsArgs = {
  dimensions?: InputMaybe<DimensionsInput>;
  requestId: Scalars['String']['input'];
  shippingProfileId: Scalars['String']['input'];
};


export type QueryGetRequestLinkArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSaleForShopArgs = {
  shopId: Scalars['String']['input'];
};


export type QueryGetSavedItemsForUserArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxTime?: InputMaybe<Scalars['Long']['input']>;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetSellerRequestsArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetShareLinkArgs = {
  interactId: Scalars['String']['input'];
};


export type QueryGetShippingRateForAddressProfileIdArgs = {
  country: Scalars['String']['input'];
  dimensions: DimensionsInput;
  profileId: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetShippingRateForCheckoutArgs = {
  checkoutId: Scalars['String']['input'];
};


export type QueryGetShippingRatesForVisionOfferArgs = {
  offerId: Scalars['String']['input'];
};


export type QueryGetShopByIdArgs = {
  shopId: Scalars['String']['input'];
};


export type QueryGetShopsByListIdArgs = {
  filter?: InputMaybe<ShopFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  listId: ListItem;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetSignedUrlArgs = {
  feature?: InputMaybe<SignedUrlFeature>;
  imageSizeBytes?: InputMaybe<Scalars['Int']['input']>;
  videoSizeBytes?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetSmartTagsArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetSpecificInspirationPageArgs = {
  inspirationId: Scalars['String']['input'];
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  screenWidth: Scalars['Int']['input'];
};


export type QueryGetSuggestedMockupsForVisionArgs = {
  visionId: Scalars['String']['input'];
};


export type QueryGetTransactionHistoryArgs = {
  fetchMode: TransactionFetchMode;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTransactionHistoryCountsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUploadUrlArgs = {
  extension: Scalars['String']['input'];
  feature: UploadUrlFeature;
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Long']['input'];
};


export type QueryGetUserAchievementsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUsersArgs = {
  input: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetVisionByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetVisionLinkArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetVisionOfferByIdArgs = {
  offerId: Scalars['String']['input'];
};


export type QueryGetVisionOfferForRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type QueryGetVisionOffersArgs = {
  listId: SellerTab;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetVisionPortfolioByUserIdArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryGetVisionRequestByIdArgs = {
  requestId: Scalars['String']['input'];
};


export type QueryGetVisionRequestForOfferArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetVisionsByListIdArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  listId: ListItem;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetWhoLikedCommentArgs = {
  commentId: Scalars['String']['input'];
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryQueryCouponCodeAvailabilityArgs = {
  couponCode: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type QueryQueryReferralCodeArgs = {
  code: Scalars['String']['input'];
};


export type QuerySearchGroupsArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchProductsArgs = {
  filter?: InputMaybe<SearchProductsFilter>;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SearchProductsSort>;
  text: Scalars['String']['input'];
};


export type QueryShouldPresentWideOfferArgs = {
  prompt: Scalars['String']['input'];
};

export type Rect = {
  __typename?: 'Rect';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
};

export type ReferralCodeReward = {
  __typename?: 'ReferralCodeReward';
  icon: Scalars['String']['output'];
  subtext?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type ReferralDetailsReward = {
  __typename?: 'ReferralDetailsReward';
  code: Scalars['String']['output'];
  howItWorks: Scalars['String']['output'];
  rewards: Array<ReferralCodeReward>;
};

export type RequestLinkResponse = {
  __typename?: 'RequestLinkResponse';
  link: Scalars['String']['output'];
};

export type RequestToBuyRequest = {
  category: Scalars['String']['input'];
  images: Array<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  shippingAddressId: Scalars['String']['input'];
  visionId: Scalars['String']['input'];
};

export type RequestToBuyResponse = {
  __typename?: 'RequestToBuyResponse';
  id: Scalars['String']['output'];
};

export type ReturnsPolicy = {
  __typename?: 'ReturnsPolicy';
  description: Scalars['String']['output'];
  policy: ReturnsPolicyOptions;
  title: Scalars['String']['output'];
};

export enum ReturnsPolicyOptions {
  NoReturns = 'NoReturns',
  ReturnExchange30d = 'ReturnExchange30d',
  ReturnExchange90d = 'ReturnExchange90d',
  ReturnOnly30d = 'ReturnOnly30d'
}

export type Sale = {
  __typename?: 'Sale';
  amount: DiscountAmount;
  endTime?: Maybe<Scalars['Long']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  startTime: Scalars['Long']['output'];
};

export type SaleInput = {
  amount: DiscountAmountInput;
  endTime?: InputMaybe<Scalars['Long']['input']>;
  name: Scalars['String']['input'];
  startTime: Scalars['Long']['input'];
};

export enum ScreenType {
  Feed = 'Feed',
  Me = 'Me',
  More = 'More',
  Offers = 'Offers',
  Product = 'Product',
  SearchResult = 'SearchResult',
  Shop = 'Shop'
}

export type SearchGroupsResponse = {
  __typename?: 'SearchGroupsResponse';
  groups: Array<SearchGroupsResponseGroup>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type SearchGroupsResponseGroup = {
  __typename?: 'SearchGroupsResponseGroup';
  avatar?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  groupName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isMember: Scalars['Boolean']['output'];
  isPopular: Scalars['Boolean']['output'];
  participants: Scalars['Int']['output'];
};

export type SearchProductsFilter = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<ProductType>;
  minStars?: InputMaybe<Scalars['Int']['input']>;
  shipsFrom?: InputMaybe<Scalars['String']['input']>;
  shipsTo?: InputMaybe<Scalars['String']['input']>;
};

export type SearchProductsResponse = {
  __typename?: 'SearchProductsResponse';
  paginationToken?: Maybe<Scalars['String']['output']>;
  products: Array<SearchProductsResponseItem>;
  total: Scalars['Int']['output'];
};

export type SearchProductsResponseItem = {
  __typename?: 'SearchProductsResponseItem';
  description?: Maybe<Scalars['String']['output']>;
  didLike: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  labels: Array<SearchProductsResponseLabel>;
  likes: Scalars['Int']['output'];
  originalPrice?: Maybe<Scalars['Long']['output']>;
  price: Price;
  rating?: Maybe<Scalars['Float']['output']>;
  shopId: Scalars['String']['output'];
  shopName: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totalReviews: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

export type SearchProductsResponseLabel = {
  __typename?: 'SearchProductsResponseLabel';
  backgroundColor: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
  texts: Array<SearchProductsResponseLabelItem>;
};

export type SearchProductsResponseLabelItem = {
  __typename?: 'SearchProductsResponseLabelItem';
  style: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type SearchProductsSort = {
  ascending: Scalars['Boolean']['input'];
  field: SearchProductsSortField;
};

export enum SearchProductsSortField {
  Price = 'Price',
  Rating = 'Rating'
}

export type SellerOfferResponse = {
  __typename?: 'SellerOfferResponse';
  offers: Array<VisionOffer>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type SellerRequestsResponse = {
  __typename?: 'SellerRequestsResponse';
  paginationToken?: Maybe<Scalars['String']['output']>;
  requests: Array<VisionRequest>;
};

export type SellerRequestsTabCountResponse = {
  __typename?: 'SellerRequestsTabCountResponse';
  acceptedCount: Scalars['Int']['output'];
  expiredCount: Scalars['Int']['output'];
  pendingCount: Scalars['Int']['output'];
  potentialCount: Scalars['Int']['output'];
};

export enum SellerTab {
  Expired = 'Expired',
  Pending = 'Pending'
}

export type SendCustomOfferInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dimensions?: InputMaybe<DimensionsInput>;
  image?: InputMaybe<Scalars['String']['input']>;
  isDigital: Scalars['Boolean']['input'];
  price: Scalars['Int']['input'];
  returnsPolicy: ReturnsPolicyOptions;
  shippingProfileId?: InputMaybe<Scalars['String']['input']>;
  tariff?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type SendOfferRequest = {
  dimensions: DimensionsInput;
  note?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Int']['input'];
  requestId: Scalars['String']['input'];
  returnsPolicy: ReturnsPolicyOptions;
  shippingProfileId: Scalars['String']['input'];
  tariff?: InputMaybe<Scalars['String']['input']>;
};

export type SendOfferResponse = {
  __typename?: 'SendOfferResponse';
  offerId: Scalars['String']['output'];
};

export type ShareLinkResponse = {
  __typename?: 'ShareLinkResponse';
  shareLink: Scalars['String']['output'];
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  address?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type ShippingAddressFullInput = {
  address: ShippingAddressInput;
  phoneNumber: Scalars['String']['input'];
};

export type ShippingAddressInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type ShippingEstimationResponse = {
  __typename?: 'ShippingEstimationResponse';
  price: Price;
  shippingServiceName: Scalars['String']['output'];
};

export enum ShippingFeature {
  BestValue = 'BestValue',
  Cheapest = 'Cheapest',
  Fastest = 'Fastest'
}

export enum ShippingPriceOption {
  Calculated = 'Calculated',
  Fixed = 'Fixed'
}

export type ShippingProfile = {
  __typename?: 'ShippingProfile';
  destinationDetails: Array<ShippingProfileDestinationDetails>;
  destinations: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isFixed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  origin: ShippingAddress;
  shippingPriceOption: ShippingPriceOption;
  workingDays: MinMaxInts;
};

export type ShippingProfileDestinationDetails = {
  __typename?: 'ShippingProfileDestinationDetails';
  countries: CountriesType;
  deliveryTime?: Maybe<ProcessingTime>;
  freeDomestic: Scalars['Boolean']['output'];
  freeInternational: Scalars['Boolean']['output'];
  price?: Maybe<FixedPrice>;
  services: Array<Scalars['String']['output']>;
};

export type ShippingRate = {
  __typename?: 'ShippingRate';
  arrivalDate: Scalars['Long']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Price;
  rateId: Scalars['String']['output'];
  tags?: Maybe<ShippingFeature>;
};

export type ShippingRatesForVisionOfferResponse = {
  __typename?: 'ShippingRatesForVisionOfferResponse';
  paymentOptions: Array<GetVisionPaymentOptionResponse>;
  rates: Array<ShippingRate>;
  shippingAddress: FullShippingAddress;
};

export type Shop = {
  __typename?: 'Shop';
  currency: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type ShopDiscount = CouponCode | Sale;

export type ShopFilters = {
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  minimumRate?: InputMaybe<MinimumRateOptions>;
  sort?: InputMaybe<Sort>;
};

export type ShopItem = {
  __typename?: 'ShopItem';
  avatar?: Maybe<Scalars['String']['output']>;
  didSendMessage: Scalars['Boolean']['output'];
  id: Scalars['TedoooID']['output'];
  images: Array<Scalars['String']['output']>;
  isFollowing: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  userId: Scalars['TedoooID']['output'];
  username: Scalars['String']['output'];
};

export type ShopOfTheDay = {
  __typename?: 'ShopOfTheDay';
  footerBackgroundColor: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isFollowing: Scalars['Boolean']['output'];
  sectionDetails: Positioning;
  shopId: Scalars['TedoooID']['output'];
  tags: Array<DiscoverPageTag>;
  text?: Maybe<StyledText>;
  title?: Maybe<StyledText>;
  userId: Scalars['TedoooID']['output'];
};

export type ShouldPresentWideOfferResponse = {
  __typename?: 'ShouldPresentWideOfferResponse';
  detectedMockupType?: Maybe<Scalars['String']['output']>;
  wideOffer?: Maybe<WideOfferInformation>;
};

export enum SignedUrlFeature {
  Mojo = 'MOJO',
  MojoComment = 'MOJO_COMMENT'
}

export type SmartTagsResponse = {
  __typename?: 'SmartTagsResponse';
  tags: Array<Scalars['String']['output']>;
};

export enum Sort {
  MostReviewed = 'mostReviewed',
  Rating = 'rating',
  Recommended = 'recommended'
}

export type SpecificInspirationPage = {
  __typename?: 'SpecificInspirationPage';
  info?: Maybe<InspirationInfo>;
  page: TedoooPageResponse;
};

export type StartCheckoutSessionResponse = {
  __typename?: 'StartCheckoutSessionResponse';
  url: Scalars['String']['output'];
};

export type StartConversationWithSupportAgentResponse = {
  __typename?: 'StartConversationWithSupportAgentResponse';
  avatar?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type StripeOption = {
  __typename?: 'StripeOption';
  buttonText: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrls: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type StripePaymentOption = {
  __typename?: 'StripePaymentOption';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
};

export type StylePageItem = {
  __typename?: 'StylePageItem';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  ratio: Scalars['Float']['output'];
};

export type StyledText = {
  __typename?: 'StyledText';
  color: Scalars['String']['output'];
  font: Fonts;
  hasArrow: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
};

export type SubscriptionFlowResult = {
  __typename?: 'SubscriptionFlowResult';
  url: Scalars['String']['output'];
};

export enum SubscriptionType {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean']['output'];
};

export type SuggestedMockupForVisionItem = {
  __typename?: 'SuggestedMockupForVisionItem';
  mockupType: Scalars['String']['output'];
  model: Model3D;
};

export enum TagVariant {
  Bordered = 'BORDERED',
  Danger = 'DANGER',
  Info = 'INFO',
  Pro = 'PRO',
  Purple = 'PURPLE',
  Success = 'SUCCESS',
  Transparent = 'TRANSPARENT',
  Vision = 'VISION',
  Warning = 'WARNING'
}

export type TaggedUser = {
  __typename?: 'TaggedUser';
  id: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type TedoooPageAd = {
  __typename?: 'TedoooPageAd';
  backgroundColor: Scalars['String']['output'];
  button?: Maybe<ButtonStyle>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  ratio: Scalars['Float']['output'];
};

export type TedoooPageButtons = {
  __typename?: 'TedoooPageButtons';
  buttons: Array<DiscoverPageTopButton>;
};

export type TedoooPageCategoriesItem = {
  __typename?: 'TedoooPageCategoriesItem';
  categories: Array<CategoryItem>;
  rows: Scalars['Int']['output'];
};

export type TedoooPageCustomGridGenericItem = {
  __typename?: 'TedoooPageCustomGridGenericItem';
  attachments: Array<TedoooPageCustomGridGenericItemAttachment>;
  items: Array<TedoooPageCustomGridGenericItemInner>;
  ratio: Scalars['Float']['output'];
  widthPercent: Scalars['Float']['output'];
};

export type TedoooPageCustomGridGenericItemAttachment = TedoooPageCustomGridGenericItemAttachmentTag | TedoooPageCustomGridGenericItemAttachmentTitle;

export type TedoooPageCustomGridGenericItemAttachmentTag = {
  __typename?: 'TedoooPageCustomGridGenericItemAttachmentTag';
  locationX: Scalars['Float']['output'];
  locationY: Scalars['Float']['output'];
  tag: GenericAdTag;
  xOrigin: ButtonOrigin;
  yOrigin: ButtonOrigin;
};

export type TedoooPageCustomGridGenericItemAttachmentTitle = {
  __typename?: 'TedoooPageCustomGridGenericItemAttachmentTitle';
  padding: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type TedoooPageCustomGridGenericItemInner = {
  __typename?: 'TedoooPageCustomGridGenericItemInner';
  endPoint: Point;
  item: DiscoverPageGenericItem;
  padding: Padding;
  startPoint: Point;
};

export type TedoooPageCustomGridItem = {
  __typename?: 'TedoooPageCustomGridItem';
  colSpan: Scalars['Int']['output'];
  didLike?: Maybe<Scalars['Boolean']['output']>;
  image: Scalars['String']['output'];
  interactId: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  ratio: Scalars['Float']['output'];
};

export type TedoooPageCustomGridSettings = {
  __typename?: 'TedoooPageCustomGridSettings';
  numColumns: Scalars['Int']['output'];
  verticalSpacing: Scalars['Int']['output'];
};

export type TedoooPageDiscoverPeekItem = {
  __typename?: 'TedoooPageDiscoverPeekItem';
  background: Gradient;
  images: Array<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type TedoooPageDivider = {
  __typename?: 'TedoooPageDivider';
  botSpacing: Scalars['Int']['output'];
  color: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  topSpacing: Scalars['Int']['output'];
};

export type TedoooPageFilter = {
  __typename?: 'TedoooPageFilter';
  icon?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  paginationToken: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export enum TedoooPageFilterDesign {
  FilteredFeedCategories = 'FILTERED_FEED_CATEGORIES',
  FilteredFeedSubcategories = 'FILTERED_FEED_SUBCATEGORIES',
  TransparentBordered = 'TRANSPARENT_BORDERED'
}

export type TedoooPageFilters = {
  __typename?: 'TedoooPageFilters';
  barType: TedoooPageFilterDesign;
  inset: HorizontalPadding;
  items: Array<TedoooPageFilter>;
};

export type TedoooPageGenericEqualGrid = {
  __typename?: 'TedoooPageGenericEqualGrid';
  items: Array<DiscoverPageGenericItem>;
  rows: Scalars['Int']['output'];
};

export type TedoooPageGenericHorizontalList = {
  __typename?: 'TedoooPageGenericHorizontalList';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  items: Array<DiscoverPageGenericItem>;
  link?: Maybe<Scalars['String']['output']>;
  rows: Scalars['Int']['output'];
  shouldDisplayPro: Scalars['Boolean']['output'];
};

export type TedoooPageGroupSuggestion = {
  __typename?: 'TedoooPageGroupSuggestion';
  groupId: Scalars['String']['output'];
  groupImage?: Maybe<Scalars['String']['output']>;
  groupName: Scalars['String']['output'];
  groupTotalMembers: Scalars['Int']['output'];
};

export type TedoooPageHeader = {
  __typename?: 'TedoooPageHeader';
  horizontalSpacing: Scalars['Int']['output'];
  items: Array<TedoooPageHeaderInner>;
  link?: Maybe<Scalars['String']['output']>;
  rightImages: Array<Scalars['String']['output']>;
  rightTag?: Maybe<GenericAdTag>;
};

export type TedoooPageHeaderInner = HeaderIcon | HeaderText;

export type TedoooPageHorizontalList = {
  __typename?: 'TedoooPageHorizontalList';
  contentInset: HorizontalPadding;
  height: Scalars['Int']['output'];
  items: Array<TedoooPageItem>;
  spaceBetween: Scalars['Int']['output'];
};

export type TedoooPageHorizontalListAds = {
  __typename?: 'TedoooPageHorizontalListAds';
  background?: Maybe<Gradient>;
  inset: HorizontalPadding;
  items: Array<AdHorizontalListItem>;
};

export type TedoooPageHorizontalListAds2 = {
  __typename?: 'TedoooPageHorizontalListAds2';
  inset: HorizontalPadding;
  items: Array<AdHorizontalListItem2>;
  ratio: Scalars['Float']['output'];
  withIndicator: Scalars['Boolean']['output'];
};

export type TedoooPageHorizontalListDiscoverPeek = {
  __typename?: 'TedoooPageHorizontalListDiscoverPeek';
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  inset: HorizontalPadding;
  items: Array<TedoooPageDiscoverPeekItem>;
  rows: Scalars['Int']['output'];
};

export type TedoooPageHorizontalListGroupSuggestions = {
  __typename?: 'TedoooPageHorizontalListGroupSuggestions';
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  inset: HorizontalPadding;
  items: Array<TedoooPageGroupSuggestion>;
  spaceBetween: Scalars['Int']['output'];
};

export type TedoooPageHorizontalListProducts = {
  __typename?: 'TedoooPageHorizontalListProducts';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  inset: HorizontalPadding;
  items: Array<TedoooPageProduct>;
  rows: Scalars['Int']['output'];
  shouldDisplayPro: Scalars['Boolean']['output'];
};

export type TedoooPageHorizontalListShops = {
  __typename?: 'TedoooPageHorizontalListShops';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  inset: HorizontalPadding;
  items: Array<TedoooPageShop>;
  rows: Scalars['Int']['output'];
  shouldDisplayPro: Scalars['Boolean']['output'];
};

export type TedoooPageHorizontalMojoItem = {
  __typename?: 'TedoooPageHorizontalMojoItem';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  inset: HorizontalPadding;
  items: Array<TedoooPageMojoItem>;
  rows: Scalars['Int']['output'];
  shouldDisplayPro: Scalars['Boolean']['output'];
};

export type TedoooPageHorizontalVisionRequest = {
  __typename?: 'TedoooPageHorizontalVisionRequest';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  inset: HorizontalPadding;
  items: Array<DiscoverPageVisionRequest>;
  link?: Maybe<Scalars['String']['output']>;
  rows: Scalars['Int']['output'];
  shouldDisplayPro: Scalars['Boolean']['output'];
  title: Array<StyledText>;
};

export type TedoooPageHorizontalVisions = {
  __typename?: 'TedoooPageHorizontalVisions';
  background?: Maybe<Gradient>;
  boxHeight: Scalars['Int']['output'];
  boxWidth: Scalars['Int']['output'];
  inset: HorizontalPadding;
  items: Array<DiscoverPageVision>;
  rows: Scalars['Int']['output'];
  shouldDisplayPro: Scalars['Boolean']['output'];
};

export type TedoooPageItem = {
  __typename?: 'TedoooPageItem';
  item: TedoooPageItemInner;
  width: Scalars['Int']['output'];
};

export type TedoooPageItemInner = TedoooPageCustomGridGenericItem | TedoooPageTitledGenericItem;

export type TedoooPageLikeCommentActionSection = {
  __typename?: 'TedoooPageLikeCommentActionSection';
  comments: Scalars['Int']['output'];
  didLike: Scalars['Boolean']['output'];
  interactId: Scalars['String']['output'];
  likes: Scalars['Int']['output'];
};

export type TedoooPageLikeCommentSection = {
  __typename?: 'TedoooPageLikeCommentSection';
  comments: Scalars['Int']['output'];
  didLike: Scalars['Boolean']['output'];
  hideWhenEmpty: Scalars['Boolean']['output'];
  interactId: Scalars['String']['output'];
  likes: Scalars['Int']['output'];
};

export type TedoooPageMojoItem = {
  __typename?: 'TedoooPageMojoItem';
  avatar?: Maybe<Scalars['String']['output']>;
  comments: Scalars['Int']['output'];
  date: Scalars['Long']['output'];
  didLike: Scalars['Boolean']['output'];
  following: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
  likes: Scalars['Int']['output'];
  premium: Scalars['Boolean']['output'];
  productDetails?: Maybe<MojoPostProductDetails>;
  ratio: Scalars['Float']['output'];
  text?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
  videoUrl: Scalars['String']['output'];
};

export type TedoooPageProduct = {
  __typename?: 'TedoooPageProduct';
  didLike?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  price?: Maybe<Price>;
  totalLikes?: Maybe<Scalars['Int']['output']>;
};

export type TedoooPageResponse = {
  __typename?: 'TedoooPageResponse';
  customGridSettings: TedoooPageCustomGridSettings;
  items: Array<TedoooPageResponseItem>;
  paginationToken?: Maybe<Scalars['String']['output']>;
  impressionSource?: Maybe<Scalars['String']['output']>;
};

export type TedoooPageResponseItem = {
  __typename?: 'TedoooPageResponseItem';
  groupId?: Maybe<Scalars['String']['output']>;
  horizontalPadding: HorizontalPadding;
  id: Scalars['String']['output'];
  item: TedoooPageResponseItemInner;
};

export type TedoooPageResponseItemInner = ChannelList | FeedItem2 | TedoooPageAd | TedoooPageButtons | TedoooPageCategoriesItem | TedoooPageCustomGridGenericItem | TedoooPageCustomGridItem | TedoooPageDivider | TedoooPageFilters | TedoooPageGenericEqualGrid | TedoooPageGenericHorizontalList | TedoooPageHeader | TedoooPageHorizontalList | TedoooPageHorizontalListAds | TedoooPageHorizontalListAds2 | TedoooPageHorizontalListDiscoverPeek | TedoooPageHorizontalListGroupSuggestions | TedoooPageHorizontalListProducts | TedoooPageHorizontalListShops | TedoooPageHorizontalMojoItem | TedoooPageHorizontalVisionRequest | TedoooPageHorizontalVisions | TedoooPageLikeCommentActionSection | TedoooPageLikeCommentSection | TedoooPageMojoItem | TedoooPageSeeAllHeader | TedoooPageShop | TedoooPageShopOfTheDay | TedoooPageSpace | TedoooPageStatic | TedoooPageTextSection;

export type TedoooPageSeeAllHeader = {
  __typename?: 'TedoooPageSeeAllHeader';
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type TedoooPageShop = {
  __typename?: 'TedoooPageShop';
  avatar?: Maybe<Scalars['String']['output']>;
  hasPortfolio: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  isFollowing: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  rating: Scalars['Float']['output'];
  shopName: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type TedoooPageShopOfTheDay = {
  __typename?: 'TedoooPageShopOfTheDay';
  footerBackgroundColor: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isFollowing: Scalars['Boolean']['output'];
  shopId: Scalars['String']['output'];
  text?: Maybe<StyledText>;
  title?: Maybe<StyledText>;
  userId: Scalars['String']['output'];
};

export type TedoooPageSpace = {
  __typename?: 'TedoooPageSpace';
  height: Scalars['Int']['output'];
};

export type TedoooPageStatic = {
  __typename?: 'TedoooPageStatic';
  extras?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
};

export type TedoooPageTextSection = {
  __typename?: 'TedoooPageTextSection';
  expandDrawerTitle?: Maybe<Scalars['String']['output']>;
  maxLines?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
};

export type TedoooPageTitledGenericItem = {
  __typename?: 'TedoooPageTitledGenericItem';
  item: DiscoverPageGenericItem;
  text: Scalars['String']['output'];
};

export type TextItem = {
  __typename?: 'TextItem';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  font: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  strikethrough: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
  underline: Scalars['Boolean']['output'];
};

export type TipButton = {
  __typename?: 'TipButton';
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
  variant: ButtonVariant;
};

export enum TipType {
  Error = 'Error',
  Info = 'Info',
  Neutral = 'Neutral',
  Purple = 'Purple',
  Success = 'Success',
  Warning = 'Warning'
}

export type Transaction = {
  __typename?: 'Transaction';
  date: Scalars['Long']['output'];
  description: Scalars['String']['output'];
  lastUpdate: Scalars['Long']['output'];
  paidVia: Scalars['String']['output'];
  partnerInfo: PartnerInfo;
  price: Price;
  receiverId: Scalars['String']['output'];
  status: TransactionStatus;
  transactionId: Scalars['String']['output'];
  transactionNumber: Scalars['String']['output'];
};

export enum TransactionFetchMode {
  All = 'All',
  Received = 'Received',
  Sent = 'Sent'
}

export type TransactionHistoryCounts = {
  __typename?: 'TransactionHistoryCounts';
  orders: Scalars['Int']['output'];
  transactions: Scalars['Int']['output'];
};

export type TransactionHistoryResponse = {
  __typename?: 'TransactionHistoryResponse';
  paginationToken?: Maybe<Scalars['String']['output']>;
  transactions: Array<Transaction>;
};

export enum TransactionStatus {
  Approved = 'Approved',
  Declined = 'Declined',
  Expired = 'Expired',
  Pending = 'Pending'
}

export type UpdateVacationRequest = {
  autoReplyMessage?: InputMaybe<Scalars['String']['input']>;
  shopMessage?: InputMaybe<Scalars['String']['input']>;
};

export enum UploadUrlFeature {
  DigitalItems = 'DIGITAL_ITEMS',
  ItemCustomization = 'ITEM_CUSTOMIZATION',
  Visions = 'VISIONS',
  VisionRequest = 'VISION_REQUEST'
}

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPremium: Scalars['Boolean']['output'];
  shopName?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  isVisionSeller: Scalars['Boolean']['output'];
};

export type UserAchievementStatus = {
  __typename?: 'UserAchievementStatus';
  peekImages: Array<Scalars['String']['output']>;
  rareCount: Scalars['Int']['output'];
  totalAchievements: Scalars['Int']['output'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  users: Array<User>;
};

export type VacationInfo = {
  __typename?: 'VacationInfo';
  date: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export enum VerificationStatus {
  Support = 'SUPPORT',
  Unverified = 'UNVERIFIED'
}

export type Vision = {
  __typename?: 'Vision';
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isMockupEnabled: Scalars['Boolean']['output'];
  /** This field is only relevant if is mock up is true */
  mockupDetails?: Maybe<MockupDetails>;
  prompt: Scalars['String']['output'];
  user: VisionUser;
};

export type VisionByIdResponse = {
  __typename?: 'VisionByIdResponse';
  vision: Vision;
};

export type VisionCreditOfferMobile = {
  __typename?: 'VisionCreditOfferMobile';
  credits: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  tag?: Maybe<OfferTag>;
};

export type VisionCreditOfferResponse = {
  __typename?: 'VisionCreditOfferResponse';
  mobile: Array<VisionCreditOfferMobile>;
  web: Array<VisionCreditOfferWeb>;
};

export type VisionCreditOfferWeb = {
  __typename?: 'VisionCreditOfferWeb';
  credits: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  id: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  tag?: Maybe<OfferTag>;
};

export type VisionInformationForUserResponse = {
  __typename?: 'VisionInformationForUserResponse';
  first3Requests: Array<Scalars['String']['output']>;
  first3Seller: Array<Scalars['String']['output']>;
  myRequestCount: Scalars['Int']['output'];
  visionsLeft: Scalars['Int']['output'];
};

export type VisionLinkResponse = {
  __typename?: 'VisionLinkResponse';
  link: Scalars['String']['output'];
};

export type VisionOffer = {
  __typename?: 'VisionOffer';
  bestMatch: Scalars['Boolean']['output'];
  buyerNotes: Scalars['String']['output'];
  category: Scalars['String']['output'];
  date: Scalars['Long']['output'];
  deliveryTime: MinMaxInts;
  dimensions: Dimensions;
  hasPortfolio: Scalars['Boolean']['output'];
  imageUrl: Scalars['String']['output'];
  mockupImage?: Maybe<Scalars['String']['output']>;
  offerId: Scalars['String']['output'];
  orderId?: Maybe<Scalars['String']['output']>;
  partnerInfo: VisionUser;
  price: Price;
  returnPolicy: ReturnsPolicy;
  sellerNotes: Scalars['String']['output'];
  shippingAddress: FullShippingAddress;
  shippingPrice: Price;
  shippingProfileId: Scalars['String']['output'];
  tariff?: Maybe<Scalars['String']['output']>;
};

export type VisionPopupDetails = {
  __typename?: 'VisionPopupDetails';
  videoUrl: Scalars['String']['output'];
};

export type VisionPortfolioItem = {
  __typename?: 'VisionPortfolioItem';
  aiImage: Scalars['String']['output'];
  category?: Maybe<Scalars['String']['output']>;
  craftingTime?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isDeletable: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  productId?: Maybe<Scalars['String']['output']>;
  realImage: Scalars['String']['output'];
};

export type VisionRequest = {
  __typename?: 'VisionRequest';
  category: Scalars['String']['output'];
  date: Scalars['Long']['output'];
  didView: Scalars['Boolean']['output'];
  expiryDate: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  mockupImage?: Maybe<Scalars['String']['output']>;
  notes: Scalars['String']['output'];
  orderId?: Maybe<Scalars['String']['output']>;
  originalPrompt: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  shippingAddress: FullShippingAddress;
  shippingAddressId: Scalars['String']['output'];
  totalOffers: Scalars['Int']['output'];
  user: VisionUser;
  visionId: Scalars['String']['output'];
  visionImageUrl: Scalars['String']['output'];
};

export type VisionRequestNewOffersPopupDetails = {
  __typename?: 'VisionRequestNewOffersPopupDetails';
  requestId: Scalars['String']['output'];
  totalCount: Scalars['Int']['output'];
  visionImage: Scalars['String']['output'];
};

export enum VisionRequestStatus {
  Approved = 'Approved',
  None = 'None',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export enum VisionSorts {
  Date = 'Date',
  DeliveryTime = 'DeliveryTime',
  Price = 'Price'
}

export type VisionUser = {
  __typename?: 'VisionUser';
  avatar?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPro: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type VisionWelcomeOfferResponse = {
  __typename?: 'VisionWelcomeOfferResponse';
  credits: Scalars['Int']['output'];
  currencyCode: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type WideOfferInformation = {
  __typename?: 'WideOfferInformation';
  normalImage: Scalars['String']['output'];
  wideImage: Scalars['String']['output'];
};

export type GetGenerateVisionLoadingImagesResponse = {
  __typename?: 'getGenerateVisionLoadingImagesResponse';
  images: Array<AiRealImagePair>;
  serverTime: Scalars['Long']['output'];
};

export enum Join__Graph {
  Achievements = 'ACHIEVEMENTS',
  Checkouts = 'CHECKOUTS',
  Discover = 'DISCOVER',
  Feed = 'FEED',
  Main = 'MAIN',
  Mojos = 'MOJOS',
  Orders = 'ORDERS',
  Payments = 'PAYMENTS',
  Products = 'PRODUCTS',
  Search = 'SEARCH',
  Shop = 'SHOP',
  Visions = 'VISIONS'
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}
