import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { SearchResultReducer } from './SearchResultReducer';
import { ISearchResultState, IStore2, PageOptions } from 'types';
import { getUrlFilterParams } from 'utils';
import {
  getFeedItems,
  parseGroupsSearchResults,
  parseProductsSearchResults,
  parseShopsSearchResults,
  parseUsersSearchResults,
} from '../apiParser';
import { onFollowUserToggle } from 'store/profile/ProfileActions';
import { MenuSlicer } from '../menu/MenuActions';
import { onProductLikeToggle } from '../specificProduct/SpecificProductActions';
import { updateProductLikes } from '../specificProduct/helper';

const SHOPS_OFFSET = 20;

const GROUPS_OFFSET = 10;

const PRODUCTS_OFFSET = 20;

const USERS_OFFSET = 20;

const DEFAULT_STATE = { list: null, offset: 0, totalCount: 0 };

const initialState: ISearchResultState = {
  userId: null,
  peek: null,
  shops: DEFAULT_STATE,
  groups: DEFAULT_STATE,
  products: DEFAULT_STATE,
  users: DEFAULT_STATE,
  hasMore: true,
  isTabLoading: false,
  productIndexToScroll: 0,
  postsTotalCount: 0,
  filters: getUrlFilterParams(),
};

const reducer = new SearchResultReducer();

export const getShops = createAsyncThunk(
  'async/getShops',
  async (input: { page: PageOptions; offset?: number; id?: string | null }, { getState }: any) => {
    const state: IStore2 = getState();

    const { page, offset, id } = input;

    const { token = '' } = state.user;

    const { shops, filters } = state.searchResult;

    const stateOffset = shops.offset;

    const nextOffset = offset !== undefined ? offset : stateOffset;

    if (page === PageOptions.similarShops && id) {
      return reducer.getSimilarShops(token, nextOffset, filters, id);
    }

    return reducer.getStores(token, nextOffset, filters, page);
  },
);

export const getGroups = createAsyncThunk('async/getGroups', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token = '' } = state.user;

  const { groups, filters } = state.searchResult;

  const { search } = filters;

  return reducer.getGroups(token, groups.offset, search);
});

export const getUsers = createAsyncThunk(
  'async/getSearchResultsUsers',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { users, filters } = state.searchResult;

    const { search } = filters;

    return reducer.getUsers(token, users.offset, search);
  },
);

export const getSearchResultsPosts = createAsyncThunk(
  'async/getSearchResultsPosts',
  async (input: { offset: number }, { getState }: any) => {
    const state: IStore2 = getState();

    const { offset } = input;

    const { token = '' } = state.user;

    const { filters } = state.searchResult;

    const { search } = filters;

    return reducer.getPosts(token, offset, search);
  },
);

export const getSearchResultsProducts = createAsyncThunk(
  'async/getSearchResultsProducts',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { products, filters } = state.searchResult;

    const { search } = filters;

    return reducer.getProducts(products.offset, search, token);
  },
);

export const getSearchResults = createAsyncThunk(
  'async/getSearchResults',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { filters } = state.searchResult;

    return reducer.getAllSearchResults(filters, token);
  },
);

export const requestJoinGroup = createAsyncThunk(
  'async/requestJoinGroup',
  async (input: { groupId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { groupId } = input;

    const { token = '' } = state.user;

    return reducer.requestJoinGroup(groupId, token);
  },
);

export const superAdminDeleteProduct = createAsyncThunk(
  'async/superAdminDeleteProduct',
  async (input: { productId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { productId } = input;

    const { token = '' } = state.user;

    return reducer.superAdminDeleteProduct(productId, token);
  },
);

export const superAdminMarkAsCrystalShop = createAsyncThunk(
  'async/superAdminMarkAsCrystalShop',
  async (input: { shopId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { shopId } = input;

    return reducer.superAdminMarkAsCrystalShop(token, shopId);
  },
);

export const SearchResultSlicer = createSlice({
  name: 'searchResult',
  initialState,
  reducers: {
    reset: (state) => {
      state.userId = null;
      state.peek = null;
      state.shops = DEFAULT_STATE;
      state.groups = DEFAULT_STATE;
      state.postsTotalCount = 0;
      state.users = DEFAULT_STATE;
      state.products = DEFAULT_STATE;
      state.hasMore = true;
      state.filters = getUrlFilterParams();
    },
    filter: (state, action) => {
      const { countries, minRate, companyTypes, sortBy, tab, search } = action.payload;
      state.filters.companyTypes = companyTypes;
      state.filters.countries = countries;
      state.filters.minRate = minRate;
      state.filters.sortBy = sortBy;
      state.shops = DEFAULT_STATE;
      state.filters.tab = tab;
      state.filters.search = search;
    },
    updateProductIndexToScroll: (state, action) => {
      const { index } = action.payload;
      state.productIndexToScroll = index;
    },
    updateTab: (state, action) => {
      const { tab } = action.payload;
      state.filters.tab = tab;
    },
    updateSearchResults: (state, action) => {
      const { data, total, userId } = action.payload;

      const parsedData = parseShopsSearchResults(data);
      state.shops.totalCount = total;
      state.shops.list = parsedData;
      state.shops.offset += SHOPS_OFFSET;
      state.userId = userId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(MenuSlicer.actions.onSubmit, (state, action) => {
      const { selectedOption } = action.payload;
      state.filters.search = selectedOption;
    });
    builder.addCase(getShops.fulfilled, (state, action: any) => {
      const { total, data } = action.payload;
      state.isTabLoading = false;

      const parsedData = parseShopsSearchResults(data);
      state.shops.totalCount = total;
      state.shops.offset += SHOPS_OFFSET;

      const currentShops = state.shops.list || [];
      state.shops.list = [...currentShops, ...parsedData];
    });
    builder.addCase(getGroups.fulfilled, (state, action: any) => {
      const { total, items } = action.payload;
      state.isTabLoading = false;

      const parsedData = parseGroupsSearchResults(items);
      state.groups.totalCount = total;
      state.groups.offset += GROUPS_OFFSET;

      const currentGroups = state.groups.list || [];
      state.groups.list = [...currentGroups, ...parsedData];
    });

    builder.addCase(getUsers.fulfilled, (state, action: any) => {
      const { total, data } = action.payload;
      state.isTabLoading = false;

      const parsedData = parseUsersSearchResults(data);

      if (total) {
        state.users.totalCount = total;
      }
      state.users.offset += USERS_OFFSET;

      const currentUsers = state.users.list || [];
      state.users.list = [...currentUsers, ...parsedData];
    });

    builder.addCase(getSearchResultsPosts.fulfilled, (state, action) => {
      const { total } = action.payload;

      if (total) {
        state.postsTotalCount = total;
      }
      state.isTabLoading = false;
    });

    builder.addCase(getSearchResults.fulfilled, (state, action) => {
      const { shops, groups, posts, products, users } = action.payload;

      const parsedShops = parseShopsSearchResults(shops);

      const parsedGroups = parseGroupsSearchResults(groups);

      const parsedPosts = getFeedItems(posts, '');

      const parsedProducts = parseProductsSearchResults(products);

      const parsedUsers = parseUsersSearchResults(users);
      state.peek = {
        products: parsedProducts,
        posts: parsedPosts,
        groups: parsedGroups,
        shops: parsedShops,
        users: parsedUsers,
      };
    });
    builder.addCase(getSearchResultsProducts.fulfilled, (state, action: any) => {
      const { total, data } = action.payload;

      const parsedData = parseProductsSearchResults(data || []);

      state.isTabLoading = false;

      if (total) {
        state.products.totalCount = total;
      }
      state.products.offset += PRODUCTS_OFFSET;

      const currentProducts = state.products.list || [];
      state.products.list = [...currentProducts, ...parsedData];
    });
    builder.addCase(superAdminDeleteProduct.fulfilled, (state, action) => {
      const { productId } = action.meta.arg;

      if (state.products.list) {
        state.products.list = state.products.list.filter((product) => product.id !== productId);
      }
    });

    builder.addCase(superAdminMarkAsCrystalShop.fulfilled, (state, action) => {
      const { shopId } = action.meta.arg;

      if (state.shops.list) {
        const shopToMark = state.shops.list.find((shop) => shop.id === shopId);

        if (shopToMark) {
          shopToMark.isMarkedAsCrystalShop = true;
        }
      }
    });
    builder.addMatcher(
      isAnyOf(requestJoinGroup.pending, requestJoinGroup.rejected),
      (state, action) => {
        const { groupId } = action.meta.arg;

        if (state.groups.list) {
          const group = state.groups.list.find((item) => item.id === groupId);

          if (group) {
            group.isMember = !group.isMember;
          }
        }
        if (state.peek) {
          const { groups } = state.peek;

          const group = groups.find((item) => item.id === groupId);

          if (group) {
            group.isMember = !group.isMember;
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(onProductLikeToggle.pending, onProductLikeToggle.rejected),
      (state, action) => {
        const { productId } = action.meta.arg;

        if (state.products.list) {
          const product = state.products.list.find((item) => item.id === productId);
          updateProductLikes(product);
        }
        if (state.peek) {
          const { products } = state.peek;

          const product = products.find((item) => item.id === productId);
          updateProductLikes(product);
        }
      },
    );

    builder.addMatcher(
      isAnyOf(onFollowUserToggle.pending, onFollowUserToggle.rejected),
      (state, action: any) => {
        const { userId, isFollowing } = action.meta.arg;

        const { shops, users } = state;

        if (shops.list) {
          const shopIndex = shops.list.findIndex((shop) => shop.userId === userId);

          if (shopIndex !== -1) {
            shops.list[shopIndex].isFollowing = !isFollowing;
          }
        }
        if (users.list) {
          const userIndex = users.list.findIndex((user) => user.id === userId);

          if (userIndex !== -1) {
            users.list[userIndex].isFollowing = !isFollowing;
          }
        }
      },
    );

    builder.addMatcher(
      isAnyOf(
        getSearchResultsPosts.pending,
        getGroups.pending,
        getShops.pending,
        getSearchResultsProducts.pending,
        getUsers.pending,
      ),
      (state) => {
        state.isTabLoading = true;
      },
    );
  },
});
