import {
  IGetHasSuggestionsResponse,
  IGetInterestSectionResponse,
  IGetShopsResponse,
  IGroupSuggestionResponse,
  IOnboardingApi,
  Method,
} from 'types';
import { fetcher } from 'api';
import { graphQlRequest } from 'api/graphQl';
import { GET_ONBOARDING_CHANNELS } from './OnboardingQueries';
import { ChannelListItem } from 'graphql/generated/graphql';

export class OnboardingApi implements IOnboardingApi {
  getOnboardingInterests(token: string): Promise<IGetInterestSectionResponse> {
    return fetcher('onboarding/labels/0/0', Method.GET, token);
  }

  getHasSuggestions(token: string): Promise<IGetHasSuggestionsResponse> {
    return fetcher('onboarding/path', Method.GET, token);
  }

  async getChannelsSuggestions(interests: string[], token: string): Promise<ChannelListItem[]> {
    const variables = {
      interests,
    };

    const res = await graphQlRequest(token).query({
      query: GET_ONBOARDING_CHANNELS,
      variables,
    });

    return res.data.getChannelSuggestions.channels;
  }

  getShopSuggestions(
    interests: string[],
    birthDate: number | null,
    token: string,
  ): Promise<IGetShopsResponse> {
    const body = {
      interests,
      birthDate: birthDate || 0,
    };

    return fetcher(`suggestions/v2/businesses`, Method.POST, token, body);
  }

  getGroupSuggestions(
    interests: string[],
    birthDate: number | null,
    token: string,
  ): Promise<IGroupSuggestionResponse> {
    const body = {
      interests,
      birthDate: birthDate || 0,
    };

    return fetcher(`suggestions/groups`, Method.POST, token, body);
  }

  updateOnboardingData(
    interests: string[],
    channelIds: string[],
    businesses: string[],
    groups: string[],
    token: string,
  ): Promise<void> {
    const body = {
      interests,
      channelIds,
      groups,
      businesses,
    };

    return fetcher(`onboarding/finish`, Method.POST, token, body);
  }
}
