import { FC } from 'react';
import './userDetailsBadges.scss';
import classNames from 'classnames';

import PremiumTag from 'components/premiumTag/PremiumTag';
import VisionSellerBadge from 'components/visionSellerBadge/VisionSellerBadge';

export type BadgeSize = 'small' | 'medium' | 'large';
interface IUserDetailsBadges {
  size: BadgeSize;
  isPremium: boolean;
  isVisionSeller: boolean;
  rowReverse?: boolean;
}

const UserDetailsBadges: FC<IUserDetailsBadges> = ({
  size,
  isPremium,
  isVisionSeller,
  rowReverse = false,
}) => {
  const reverse = rowReverse ? 'reverse' : '';

  return (
    <div className={classNames('user-details-badges-container', reverse)}>
      {isVisionSeller && <VisionSellerBadge size={size} />}
      {isPremium && <PremiumTag size={size} />}
    </div>
  );
};

export default UserDetailsBadges;
