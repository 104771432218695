import { gql } from '@apollo/client';

export const GET_FEED_QUERY = gql`
  query GetDiscoverPageData($paginationToken: String, $listId: ListItem!) {
    getDiscoverPageData(
      screenHeight: 0
      screenWidth: 0
      paginationToken: $paginationToken
      listId: $listId
      version: 6
    ) {
      paginationToken
      feedItems {
        id
        userId
        username
        avatar
        fullName
        shopName
        shopId
        shopImage
        images
        comments
        date
        text
        likes
        reposts
        repostId
        groupId
        groupName
        backgroundIndex
        composition
        ratio
        didLike
        didRepost
        reason
        reasonExtra
        following
        premium
        isVisionSeller
        totalFollowers
        totalFollowing
        editable
        editedDate
        isShoppingPost
        hideDate
        lastComment {
          id
          text
          image
          date
          userId
          username
          fullName
          avatar
          replies
          premium
          didLike
          likes
          isVisionSeller
        }
        productDetails {
          title
          productId
          price {
            price
            currencyCode
            currency
          }
        }
        repostDetails {
          id
          userId
          username
          avatar
          fullName
          shopName
          shopId
          shopImage
          images
          comments
          date
          text
          likes
          reposts
          repostId
          groupId
          groupName
          backgroundIndex
          composition
          ratio
          premium
          isShoppingPost
          productDetails {
            title
            productId
            price {
              price
              currencyCode
              currency
            }
          }
          tags {
            id
            value
          }
        }
        tags {
          id
          value
        }
        sectionDetails {
          order
          topSpacing
        }
      }
      staticItems {
        id
        key
        extras
      }
      mojos {
        id
        userId
        username
        avatar
        fullName
        videoUrl
        thumbnailUrl
        date
        text
        likes
        ratio
        didLike
        following
        premium
        comments
        sectionDetails {
          order
          topSpacing
        }
        productDetails {
          title
          productId
          image
          price {
            price
            currencyCode
            currency
          }
        }
      }
    }
  }
`;

export const GET_MAIN_FEED_QUERY = gql`
  query GetFeedPage(
    $paginationToken: String
    $screenWidth: Int!
    $userLocation: WorldLocationInput
  ) {
    getFeedPage(
      paginationToken: $paginationToken
      screenWidth: $screenWidth
      userLocation: $userLocation
    ) {
      paginationToken
      customGridSettings {
        numColumns
        verticalSpacing
        gridHorizontalSpacing {
          left
          right
        }
      }
      impressionSource
      items {
        id
        horizontalPadding {
          left
          right
        }
        groupId
        item {
          __typename
          ... on FeedItem2 {
            id
            userId
            username
            avatar
            fullName
            shopName
            shopId
            shopImage
            images
            comments
            date
            text
            likes
            reposts
            repostId
            groupId
            groupName
            backgroundIndex
            composition
            ratio
            didLike
            didRepost
            reason
            reasonExtra
            following
            premium
            isVisionSeller
            totalFollowers
            totalFollowing
            editable
            editedDate
            isShoppingPost
            hideDate
            lastComment {
              id
              text
              image
              date
              userId
              username
              fullName
              avatar
              replies
              premium
              didLike
              likes
              isVisionSeller
            }
            productDetails {
              title
              productId
              price {
                price
                currencyCode
                currency
              }
            }
            repostDetails {
              id
              userId
              username
              avatar
              fullName
              shopName
              shopId
              shopImage
              images
              comments
              date
              text
              likes
              reposts
              repostId
              groupId
              groupName
              backgroundIndex
              composition
              ratio
              premium
              isShoppingPost
              productDetails {
                title
                productId
                price {
                  price
                  currencyCode
                  currency
                }
              }
              tags {
                id
                value
              }
            }
            tags {
              id
              value
            }
            postCategory
            externalPost {
              source
              niche {
                link
                name
              }
            }
          }

          ... on TedoooPageSpace {
            height
          }

          ... on TedoooPageDivider {
            topSpacing
            botSpacing
            height
            color
          }

          ... on TedoooPageStatic {
            key
            extras
          }
        }
      }
    }
  }
`;

export const GET_FILTERED_FEED_PAGE_QUERY = gql`
  query GetFilteredFeedPage2($paginationToken: String, $screenWidth: Int!) {
    getFilteredFeedPage2(paginationToken: $paginationToken, screenWidth: $screenWidth) {
      nicheInteractId
      isFollowing
      page {
        paginationToken
        customGridSettings {
          numColumns
          verticalSpacing
          gridHorizontalSpacing {
            left
            right
          }
        }
        impressionSource
        items {
          id
          horizontalPadding {
            left
            right
          }
          groupId
          item {
            __typename
            ... on FeedItem2 {
              id
              userId
              username
              avatar
              fullName
              shopName
              shopId
              shopImage
              images
              comments
              date
              text
              likes
              reposts
              repostId
              groupId
              groupName
              backgroundIndex
              composition
              ratio
              didLike
              didRepost
              reason
              reasonExtra
              following
              premium
              isVisionSeller
              totalFollowers
              totalFollowing
              editable
              editedDate
              isShoppingPost
              hideDate
              lastComment {
                id
                text
                image
                date
                userId
                username
                fullName
                avatar
                replies
                premium
                didLike
                likes
                isVisionSeller
              }
              productDetails {
                title
                productId
                price {
                  price
                  currencyCode
                  currency
                }
              }
              repostDetails {
                id
                userId
                username
                avatar
                fullName
                shopName
                shopId
                shopImage
                images
                comments
                date
                text
                likes
                reposts
                repostId
                groupId
                groupName
                backgroundIndex
                composition
                ratio
                premium
                isShoppingPost
                productDetails {
                  title
                  productId
                  price {
                    price
                    currencyCode
                    currency
                  }
                }
                tags {
                  id
                  value
                }
              }
              tags {
                id
                value
              }
              postCategory
              externalPost {
                source
                niche {
                  link
                  name
                }
              }
            }

            ... on TedoooPageSpace {
              height
            }

            ... on TedoooPageDivider {
              topSpacing
              botSpacing
              height
              color
            }

            ... on TedoooPageStatic {
              key
              extras
            }
          }
        }
      }
    }
  }
`;
