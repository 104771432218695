import { FC } from 'react';
import classNames from 'classnames';
import { Button, Dropdown, PreviewImage, Tag } from 'tedooo-web-design-system/ui';
import { FilledHeartIcon, MoreHorizontal, Star } from 'tedooo-web-design-system/icons';

import { CommentMenuOptions, ICommentProps } from 'types';
import './comment.scss';
import { Avatar } from 'components/avatar/Avatar';
import { Span } from 'components/helpers';
import { getShortRelativeTime } from 'utils';
import ExpandableText from 'components/expandableText/ExpandableText';
import CommentReactions from './commentReaction/CommentReactions';
import { CommentOptionMenu } from './helper';
import { translations } from 'translations/en';
import { ROUTES } from 'constant';
import { useRequireToken } from 'hooks/useRequireToken';
import UserDetailsBadges from 'components/post/header/userDetails/badges/UserDetailsBadges';

const MAX_TEXT_CHARS = 120;

const AVATAR_SIZE = 40;

const Comment: FC<ICommentProps> = ({
  avatar,
  isPostOwner,
  username,
  fullName,
  text,
  date,
  image,
  id,
  onReplyClick,
  isCommentOwner,
  isPremium,
  isLiked,
  totalLikes,
  isReply,
  isDeleted,
  onCommentDelete,
  userId,
  onUserClick,
  taggedUsers,
  onCommentLike,
  onCommentLikesClick,
  isVisionSeller,
}) => {
  const formattedDate = getShortRelativeTime(date);

  const shouldDisplayOptionMenu = !isDeleted && isCommentOwner;

  const shouldDisplayImage = !isDeleted && image;

  const onOptionClick = (key: string) => {
    if (key === CommentMenuOptions.Delete) {
      onCommentDelete(id);
    }
  };

  const onNameClick = (e: any) => {
    e.stopPropagation();
    onUserClick(userId);
  };

  const formattedOptions = CommentOptionMenu.map((item) => ({
    ...item,
  }));

  const onImageClick = (e: any) => {
    e.stopPropagation();
  };

  const onReplyUserClick = useRequireToken(() => {
    const tag = { username, userId };
    onReplyClick(id, tag);
  });

  const onLikeComment = useRequireToken(() => {
    onCommentLike(id, !isLiked);
  });

  const onShowCommentsLikesClick = (e: any) => {
    onCommentLikesClick(id);
    e.stopPropagation();
  };

  const getPopupContainer = (parentNode: HTMLElement) => parentNode.parentElement || document.body;

  const shouldShowTagsContainer = isPostOwner || isPremium;

  const mentions = taggedUsers.map((user) => ({
    username: user.username,
    link: `/${ROUTES.USER}/${user.userId}`,
  }));

  return (
    <div
      className={classNames('comment-wrapper', {
        reply: isReply,
        deleted: isDeleted,
      })}
    >
      <Avatar src={avatar} size={AVATAR_SIZE} onClick={onNameClick} />
      <div className="comment-inner-wrapper">
        <div className="comment-with-dropdown">
          <div className="comment-inner-container">
            {shouldShowTagsContainer && (
              <div className="tags-container">
                {isPostOwner && (
                  <Tag
                    color="blue"
                    size="small"
                    text={translations.COMMON.AUTHOR}
                    icon={<Star />}
                  />
                )}
                <UserDetailsBadges
                  size="small"
                  isPremium={isPremium}
                  isVisionSeller={isVisionSeller}
                  rowReverse
                />
              </div>
            )}
            <div className="header">
              <Span text={fullName} className="full-name" onClick={onNameClick} />
              <div className="dot" />
              <Span text={`@${username}`} className="username" />
            </div>
            {text && (
              <ExpandableText
                isCollapsable={false}
                isTextClickable={false}
                text={text}
                maxChars={MAX_TEXT_CHARS}
                mentions={mentions}
              />
            )}
            {totalLikes > 0 && (
              <div className="comment-likes-container" onClick={onShowCommentsLikesClick}>
                <Span text={totalLikes} /> <FilledHeartIcon />
              </div>
            )}
          </div>
          {shouldDisplayOptionMenu && (
            <div className="dropdown-container" onClick={onImageClick}>
              <Dropdown
                size="small"
                placement="bottomRight"
                options={formattedOptions}
                clickable
                onChange={onOptionClick}
                getPopupContainer={getPopupContainer}
              >
                <Button
                  type="secondary"
                  size="small"
                  borderRadius="large"
                  prefixIcon={<MoreHorizontal />}
                />
              </Dropdown>
            </div>
          )}
        </div>
        {shouldDisplayImage && (
          <div onClick={onImageClick} className="comment-image-container">
            <PreviewImage src={image} shouldDisplayHoverText={false} />
          </div>
        )}
        <CommentReactions
          timePosted={formattedDate}
          onReplyClick={onReplyUserClick}
          isLiked={isLiked}
          onLikeClick={onLikeComment}
        />
      </div>
    </div>
  );
};

export default Comment;
