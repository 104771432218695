import { ApolloClient, ApolloLink, createHttpLink, gql, InMemoryCache } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'js-sha256';

const httpLink = createHttpLink({
  uri: `https://backend.tedooo.com/graphql`,
});

const linkChain = createPersistedQueryLink({ sha256 }).concat(httpLink);

const authLink = (token?: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: any) => ({
      headers: {
        ...(headers || {}),
        ...(token ? { Authorization: token } : {}),
      },
    }));

    return forward(operation);
  });

export const graphQlRequest = (token?: string) => {
  return new ApolloClient({
    link: ApolloLink.from([authLink(token), linkChain]),
    cache: new InMemoryCache(),
  });
};

export const GET_SIGNED_URL_QUERY = gql`
  query GetUploadUrl(
    $feature: UploadUrlFeature!
    $fileSize: Long!
    $fileName: String!
    $extension: String!
  ) {
    getUploadUrl(
      feature: $feature
      fileSize: $fileSize
      fileName: $fileName
      extension: $extension
    ) {
      signedUrl
      url
      headers {
        key
        value
      }
    }
  }
`;
