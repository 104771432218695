import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ProfileCard from 'components/profileCard/ProfileCard';
import './profileSideBar.scss';
import { IProfileSideBar, PremiumStatus } from 'types';
import ShopSuggestionsSection from 'pages/profilePage/shopSuggestionsSection/ShopSuggestionsSection';
import { ROUTES } from 'constant';
import SideBarFooter from './sideBarFooter/SideBarFooter';
import { isEmptyArray } from 'utils';
import CompleteShopBox from 'components/completeShopBox/CompleteShopBox';
import TedoooProBox from 'components/tedoooPro/tedoooProBox/TedoooProBox';
import FavoritesSection from './favoritesSection/FavoritesSection';
import ShopOfTheDayBanner from 'pages/discoverPage/staticComponents/shopOfTheDayBanner/ShopOfTheDayBanner';
import VisionsSection from './visionsSection/VisionsSection';
import TedoooProBoxLegacy from 'components/tedoooPro/tedoooProBox/TedoooProBoxLegacy';

const ProfileSideBar: FC<IProfileSideBar> = ({
  totalFollowers,
  totalFollowing,
  coverPhoto,
  extraInformation,
  shouldShowVisions,
  name,
  shopOfTheDay,
  avatar,
  onUserClick,
  suggestedShops,
  premiumStatus,
  isPremium,
  shouldShowDraft,
  savedProducts,
  totalCompletedDraftSteps,
  onFollow,
  showPopup,
  onHideDraftFromFeed,
  onSaveProduct,
  shouldDisplayLegacyPremium,
}) => {
  const navigate = useNavigate();

  const onShopClick = (shopId: string) => navigate(`/${ROUTES.SHOP}/${shopId}`);

  const shouldShowTedoooProBox = !isPremium && premiumStatus === PremiumStatus.TryFreeMonth;

  const renderTedoooProBox = () => {
    if (shouldDisplayLegacyPremium) {
      return <TedoooProBoxLegacy showPopup={showPopup} />;
    }

    return <TedoooProBox showPopup={showPopup} />;
  };

  return (
    <div className="profile-side-bar">
      <div className="section">
        <ProfileCard
          onFollowToggle={() => {}}
          shouldShowFollowButton
          onViewProfileClick={onUserClick}
          isFollowing
          avatar={avatar}
          coverPhoto={coverPhoto}
          shopName={extraInformation}
          username={name}
          totalFollowing={totalFollowing}
          totalFollowers={totalFollowers}
          isReadOnly={false}
          showPopup={showPopup}
          isUserProfileCard
          isPremium={false}
          isVisionSeller={false}
        />
      </div>
      {shouldShowDraft && (
        <CompleteShopBox
          step={totalCompletedDraftSteps}
          shouldShowLater
          onLaterClick={onHideDraftFromFeed}
          size="small"
        />
      )}
      {shopOfTheDay && (
        <ShopOfTheDayBanner
          userId={shopOfTheDay.userId}
          shopId={shopOfTheDay.shopId}
          titles={shopOfTheDay.titles}
          footerBackgroundColor={shopOfTheDay.footerBackgroundColor}
          image={shopOfTheDay.image}
          isFollowing={shopOfTheDay.isFollowing}
          onFollowUserToggle={onFollow}
          onViewShopClick={onShopClick}
        />
      )}
      {shouldShowVisions && <VisionsSection />}
      {!isEmptyArray(savedProducts) && savedProducts && (
        <FavoritesSection products={savedProducts} onSaveProduct={onSaveProduct} />
      )}
      {shouldShowTedoooProBox && renderTedoooProBox()}
      {suggestedShops && !isEmptyArray(suggestedShops) && (
        <ShopSuggestionsSection
          shops={suggestedShops.slice(0, 3)}
          onFollowToggle={onFollow}
          onShopClick={onShopClick}
        />
      )}
      <SideBarFooter />
    </div>
  );
};

export default ProfileSideBar;
