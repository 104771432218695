import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getCommentItem, getCommentsItems, getLikesItems, getSpecificPost } from '../apiParser';
import {
  addComment,
  addReply,
  deleteComment,
  getComments,
  getPostLikes,
  getPost,
  getReplies,
  getShopImage,
  onLikeToggle,
  onLikeComment,
  getCommentLikes,
} from './SpecificPostActions';
import { IComment, ISpecificPostState, PostActionType } from 'types';
import {
  addReplyToComment,
  deleteCommentToPost,
  getSelectedComment,
  getSelectedPost,
  getSubPost,
  togglePostLike,
  onRepostToggle,
} from './helper';
import { getSubPostById } from '../post/helper';
import { onFollowUserToggle } from 'store/profile/ProfileActions';
import { toggleRepost } from '../feed/PostActions';

const initialState: ISpecificPostState = {
  actionId: '',
  id: '',
  action: null,
  shopId: '',
  userDetails: {
    avatar: '',
    fullName: '',
    username: '',
    shopName: '',
    date: 0,
    userId: '',
    shopId: null,
    shopImage: '',
    isPremium: false,
    isFollowing: false,
    isVisionSeller: false,
  },
  productDetails: null,
  text: '',
  isFollowing: false,
  isLiked: false,
  isReposted: false,
  repostDetails: null,
  totalComments: 0,
  totalLikes: 0,
  totalReposts: 0,
  images: [],
  mojoDetails: null,
  taggedUsers: [],
  imageComposition: 0,
  likes: {
    list: null,
    hasMore: true,
  },
  comments: {
    list: null,
    hasMore: true,
  },
  selectedPostId: null,
  selectedCommentId: null,
  selectedReplyId: null,
  isEditable: false,
  lastEdited: null,
  isShoppingPost: false,
  coverPhoto: null,
  productImages: [],
  subPosts: [],
  totalFollowers: 0,
  totalFollowing: 0,
  shouldHideDate: false,
  externalPost: null,
};

export const SpecificPostSlicer = createSlice({
  name: 'specificPost',
  initialState,
  reducers: {
    onPostUnselect: (state) => {
      state.selectedPostId = null;
    },
    reset: (state) => {
      state.text = '';
      state.isFollowing = false;
      state.isLiked = false;
      state.isReposted = false;
      state.totalComments = 0;
      state.totalLikes = 0;
      state.totalReposts = 0;
      state.images = [];
      state.mojoDetails = null;
      state.productDetails = null;
      state.backgroundIndex = undefined;
      state.likes = { list: null, hasMore: true };
      state.comments = { list: null, hasMore: true };
      state.selectedPostId = null;
      state.productImages = [];
      state.coverPhoto = null;
      state.repostDetails = null;
      state.actionId = '';
      state.action = null;
      state.taggedUsers = [];
    },
    onLikesClose: (state, action) => {
      const { commentId, replyId } = action.payload;
      let selectedComment;
      if (commentId) {
        selectedComment = getSelectedComment(state, commentId, replyId);
        if (selectedComment) {
          selectedComment.likes = { list: null, hasMore: true };
        }
        state.likes = { list: null, hasMore: true };
      }
    },
    updateAction: (state, action) => {
      const { username } = action.payload;
      state.action = { username, type: PostActionType.Repost };
    },
    toggleCommentReplies: (state, action) => {
      const { commentId, replyId } = action.payload;

      const post = getSelectedPost(state);

      const comment = getSelectedComment(post, commentId, replyId);

      if (comment) {
        comment.isRepliesExpanded = !comment.isRepliesExpanded;
      }
    },
    onSelectedPostChange: (state, action) => {
      const { postId } = action.payload;
      state.selectedPostId = postId;
    },
    onSelectedCommentChange: (state, action) => {
      const { commentId, replyId } = action.payload;
      state.selectedCommentId = commentId;
      state.selectedReplyId = replyId;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(deleteComment.pending, (state) => {
      const { selectedReplyId, selectedCommentId, subPosts, selectedPostId, id } = state;

      const post = getSelectedPost(state);
      deleteCommentToPost(post, selectedCommentId, selectedReplyId);

      if (selectedPostId === id) {
        deleteCommentToPost(state, selectedCommentId, selectedReplyId);
      }
      if (!selectedPostId) {
        const subPost = getSubPostById(subPosts, id);

        if (subPost) {
          subPost.totalComments -= 1;
        }
      }
    });
    builder.addCase(getShopImage.fulfilled, (state, action) => {
      const { coverPhoto, productImages } = action.payload;
      state.coverPhoto = coverPhoto || null;
      state.productImages = productImages;
    });

    builder.addCase(getComments.fulfilled, (state, action) => {
      const { data, hasMore } = action.payload;

      const post = getSelectedPost(state);

      if (post) {
        const prevComments = post.comments.list || [];

        const comments = getCommentsItems(data);
        post.comments.hasMore = hasMore;
        post.comments.list = [...prevComments, ...comments];
      }

      const selectedSubPost = state.subPosts.find((subPost) => subPost.id === state.id);

      if (selectedSubPost) {
        const prevComments = selectedSubPost.comments.list || [];

        const comments = getCommentsItems(data);

        selectedSubPost.comments.hasMore = hasMore;
        selectedSubPost.comments.list = [...prevComments, ...comments];
      }
    });

    builder.addCase(getPostLikes.fulfilled, (state, action) => {
      const { data, hasMore } = action.payload;

      const post = getSelectedPost(state);

      if (post) {
        const prevLikesList = post.likes.list || [];

        const likes = getLikesItems(data);

        post.likes.list = [...prevLikesList, ...likes];
        post.likes.hasMore = hasMore;
      }
    });

    builder.addCase(getCommentLikes.fulfilled, (state, action) => {
      const { data, hasMore } = action.payload;

      const { commentId, replyId } = action.meta.arg;

      const post = getSelectedPost(state);

      const comment = getSelectedComment(post, commentId, replyId);

      if (comment) {
        const prevLikesList = comment.likes.list || [];

        const likes = getLikesItems(data);

        comment.likes.list = [...prevLikesList, ...likes];
        comment.likes.hasMore = hasMore;
      }
    });

    builder.addCase(getReplies.fulfilled, (state, action) => {
      const data = action.payload;

      const commentId = action.meta.arg;

      const post = getSelectedPost(state);

      const { comments } = post;

      const comment = comments.list!.find((item: IComment) => item.id === commentId);

      if (comment) {
        comment.replies = getCommentsItems(data);
        comment.isRepliesExpanded = true;
      }
    });

    builder.addCase(addComment.fulfilled, (state, action) => {
      const data = action.payload;

      const { id, comments, selectedPostId, subPosts } = state;

      const post = getSelectedPost(state);

      if (!selectedPostId) {
        const subPost = getSubPostById(subPosts, id);

        if (subPost) {
          subPost.totalComments += 1;
        }
      }

      const { list: prevList } = post.comments;

      const comment = getCommentItem(data);

      const prevComments = prevList || [];
      post.comments.list = [comment, ...prevComments];
      post.totalComments += 1;

      // update main post if needed
      if (id === selectedPostId) {
        const prevStateComments = comments.list || [];
        comments.list = [comment, ...prevStateComments];
        state.totalComments += 1;
      }
    });

    builder.addCase(getPost.fulfilled, (state, action) => {
      const data = action.payload;

      const { token } = action.meta.arg;

      const { subPosts, postDetails } = getSpecificPost(data, token);

      state.totalFollowers = postDetails.totalFollowers || 0;
      state.totalFollowing = postDetails.totalFollowing || 0;
      state.subPosts = subPosts;
      state.id = postDetails.id;
      state.shopId = postDetails.shopId;
      state.isFollowing = postDetails.isFollowing;
      state.userDetails = postDetails.userDetails;
      state.text = postDetails.text;
      state.images = postDetails.images || null;
      state.mojoDetails = postDetails.mojoDetails;
      state.isLiked = postDetails.isLiked;
      state.isReposted = postDetails.isReposted;
      state.totalComments = postDetails.totalComments;
      state.totalLikes = postDetails.totalLikes;
      state.totalReposts = postDetails.totalReposts;
      state.shopId = postDetails.shopId;
      state.likes = postDetails.likes;
      state.comments = postDetails.comments;
      state.imageComposition = postDetails.imageComposition;
      state.productDetails = postDetails.productDetails;
      state.backgroundIndex = postDetails.backgroundIndex;
      state.isEditable = postDetails.isEditable;
      state.lastEdited = postDetails.lastEdited;
      state.repostDetails = postDetails.repostDetails;
      state.actionId = postDetails.actionId;
      state.taggedUsers = postDetails.taggedUsers;
      state.isShoppingPost = postDetails.isShoppingPost;
      state.externalPost = postDetails.externalPost;
    });

    builder.addCase(addReply.fulfilled, (state, action) => {
      const data = action.payload;

      const { id, selectedPostId, subPosts } = state;

      const { commentId } = action.meta.arg;

      const post = getSelectedPost(state);

      addReplyToComment(post, data, commentId);

      if (id === selectedPostId) {
        addReplyToComment(state, data, commentId);
      }

      if (!selectedPostId) {
        const subPost = getSubPostById(subPosts, id);

        if (subPost) {
          subPost.totalComments += 1;
        }
      }
    });

    builder.addMatcher(
      isAnyOf(onFollowUserToggle.pending, onFollowUserToggle.rejected),
      (state, action) => {
        const { userId, isFollowing } = action.meta.arg;

        const { isFollowing: isFollowingPostAuthor } = state;

        if (userId === state.userDetails?.userId) {
          state.isFollowing = !isFollowingPostAuthor;
        }

        const user = state.likes.list?.find((like) => userId === like.userId);

        if (user) {
          user.isFollowing = !isFollowing;
        }
      },
    );

    builder.addMatcher(isAnyOf(toggleRepost.pending, toggleRepost.rejected), (state) => {
      onRepostToggle(state);
    });

    builder.addMatcher(isAnyOf(onLikeToggle.pending, onLikeToggle.rejected), (state) => {
      const subPost = getSubPost(state);
      togglePostLike(subPost);

      const { id } = state;

      if (!subPost || id === subPost.id) {
        togglePostLike(state);
      }
    });

    builder.addMatcher(isAnyOf(onLikeComment.pending, onLikeComment.rejected), (state, action) => {
      const { commentId, isLiked, replyId } = action.meta.arg;

      const post = getSelectedPost(state);

      const selectedComment = getSelectedComment(post, commentId, replyId);

      if (selectedComment) {
        selectedComment.isLiked = !selectedComment.isLiked;
        if (isLiked) {
          selectedComment.totalLikes += 1;
        } else {
          selectedComment.totalLikes -= 1;
        }
      }
    });
  },
});
