import { FC } from 'react';
import './followChannelCard.scss';
import { Checkbox } from 'tedooo-web-design-system/ui';
import classNames from 'classnames';

import { Image } from 'components/helpers';
import { IFollowChannelCard } from 'types';
import { toShortFormat } from 'utils';
import { translations } from 'translations/en';

const FollowChannelCard: FC<IFollowChannelCard> = ({ channelDetails, onClick }) => {
  const { isSelected, name, subscriberCount, description, image } = channelDetails;

  return (
    <div
      className={classNames('follow-channel-card-container', { selected: isSelected })}
      onClick={onClick}
    >
      <div className="top-container">
        <Image src={image} className={classNames('channel-image')} />
        <div className="top-text-container">
          <span className="shop-name">{name}</span>
          <span className="subscribers-text">
            {`${toShortFormat(subscriberCount)} ${
              translations.ONBOARDING.FOLLOW_CHANNELS_STEPS.SUBSCRIBERS
            }`}
          </span>
        </div>
      </div>
      <div className="bottom-container">
        <div className="text-container">
          <span className="shop-username">{description}</span>
          <span className="shop-username">
            {translations.ONBOARDING.FOLLOW_CHANNELS_STEPS.NOTE}
          </span>
        </div>
        <div
          style={{
            marginTop: 'auto',
          }}
        >
          <Checkbox checked={isSelected} />
        </div>
      </div>
    </div>
  );
};

export default FollowChannelCard;
