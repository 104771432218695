import { FC } from 'react';

import './userDetails.scss';
import { translations } from 'translations/en';
import { Span } from 'components/helpers';
import { IPostSubText } from 'types';
import ExternalPostSubText from './ExternalPostSubText';
import { getShortRelativeTime } from 'utils';

const PostSubText: FC<IPostSubText> = ({
  username,
  fullName,
  shopId,
  isPostEdited,
  date,
  shouldHideDate,
  externalPost,
  onFilterChange,
}) => {
  const subText = shopId ? fullName : `@${username}`;

  const formattedDate = date && !shouldHideDate ? ` · ${getShortRelativeTime(date)}` : '';

  return externalPost ? (
    <ExternalPostSubText
      fullName={fullName}
      shopId={shopId}
      isPostEdited={isPostEdited}
      shouldHideDate={shouldHideDate}
      formattedDate={formattedDate}
      externalPost={externalPost}
      onFilterChange={onFilterChange}
    />
  ) : (
    <div className="sub-text">
      <span className="text" />
      <Span className="text" text={`${subText}${formattedDate}`} />
      {isPostEdited && <span className="text">{translations.FEED.POST.EDITED}</span>}
    </div>
  );
};

export default PostSubText;
