import {
  FeedItemTypeName,
  IComment,
  ICreatePostBody,
  ICreatePostRequest,
  IFeedBubble,
  IFeedPost,
  IFeedState,
  ImageComposition,
  IPostAction,
  IStaticItem,
  ITaggedUser,
  PostActionType,
} from 'types';
import { getSelectedFeed } from '../post/helper';
import {
  getAvatar,
  getDefaultAvatar,
  getFullName,
  getIsPremium,
  getUserId,
  getUsername,
} from '../user/helper';
import {
  getModularFeedItems,
  getMojoFeedItems,
  parseFeedMainPageStaticItems,
  parseStaticItems,
} from '../apiParser';
import {
  DiscoverPageResult,
  FeedItem2,
  TedoooPageResponse,
  TedoooPageResponseItem,
  TedoooPageStatic,
} from '../../graphql/generated/graphql';
import { ALL } from 'pages/feed/feedBubblesCarousel/helper';

export const parseCreatePostRequest = (input: ICreatePostRequest) => {
  const {
    text,
    images,
    imageComposition,
    product,
    ratio,
    backgroundImage,
    backgroundIndex,
    groupId,
    repostDetails,
    taggedUsers,
    isShoppingPost,
  } = input;

  const parsedBackgroundIndex = backgroundIndex !== null ? backgroundIndex : undefined;

  const parsedTaggedUsers = taggedUsers.map((user) => user.username);

  const updatedTaggedUsers = checkTaggedUsers(text, parsedTaggedUsers);

  const options: ICreatePostBody = {
    text,
    images,
    title: product?.title,
    price: undefined,
    productId: product?.id,
    category: 0,
    composition: imageComposition,
    ratio,
    backgroundIndex: parsedBackgroundIndex,
    groupId,
    repostId: repostDetails?.id,
    tags: updatedTaggedUsers,
    isShoppingPost,
  };

  if (product) {
    const price = product.price ? product.price * 100 : product.originalPrice * 100;
    options.ratio = 1.47;
    options.composition = ImageComposition.one;
    options.category = 1;
    options.images = [product.images[0]];
    options.price = {
      price,
      currency: product.currencySymbol,
      currencyCode: product.currencyCode,
    };
  }
  if (backgroundImage) {
    options.images = [backgroundImage];
    options.ratio = 1.47;
    options.composition = ImageComposition.one;
  }

  return options;
};

export const addPostToFeed = (state: IFeedState, input: ICreatePostRequest, id: string) => {
  const {
    text,
    product,
    images,
    imageComposition,
    shopId,
    backgroundIndex,
    backgroundImage,
    taggedUsers,
    repostDetails,
    userDetails,
    isShoppingPost,
  } = input;

  const productDetails = product
    ? {
        id: product.id,
        image: product.images[0],
        title: product.title,
        price: {
          value: product.price,
          currencyCode: product.currencyCode,
          currencySymbol: product.currencySymbol,
        },
      }
    : null;

  let postImages = images;
  if (backgroundImage) {
    postImages = [backgroundImage];
  }

  const parsedBackgroundIndex = backgroundIndex !== null ? backgroundIndex : undefined;

  const feed = getSelectedFeed(state);

  if (feed.list) {
    feed.list.unshift({
      id,
      actionId: id,
      action: null,
      text,
      images: postImages,
      productDetails,
      imageComposition,
      mojoDetails: null,
      isReposted: false,
      isLiked: false,
      totalReposts: 0,
      totalComments: 0,
      totalLikes: 0,
      isCommentsExpanded: false,
      isEditable: false,
      lastComment: null,
      selfComments: null,
      taggedUsers,
      backgroundIndex: parsedBackgroundIndex,
      shopId,
      lastEdited: null,
      isFollowing: true,
      likes: { list: [], hasMore: false },
      comments: { list: [], hasMore: false },
      shouldDisplayHideFromActivity: false,
      repostDetails,
      userDetails,
      isShoppingPost,
      shouldHideDate: false,
      externalPost: null,
    });
  }
};

export const editPostInFeed = (state: IFeedState, input: ICreatePostRequest) => {
  const {
    id = null,
    text,
    product,
    images,
    imageComposition,
    shopId,
    backgroundIndex,
    backgroundImage,
    taggedUsers,
  } = input;

  const feed = getSelectedFeed(state);

  const post = feed.list?.find((item) => item.id === id);

  const productDetails = product
    ? {
        id: product.id,
        title: product.title,
        image: product.images[0],
        price: {
          value: product.price,
          currencyCode: product.currencyCode,
          currencySymbol: product.currencySymbol,
        },
      }
    : null;

  let postImages = images;
  if (backgroundImage) {
    postImages = [backgroundImage];
  }

  const parsedBackgroundIndex = backgroundIndex !== null ? backgroundIndex : undefined;

  const selectedShop = state.createPostDetails.shops.find((shop) => shop.id === shopId);

  if (post) {
    post.text = text;
    post.backgroundIndex = parsedBackgroundIndex;
    post.userDetails.shopId = shopId;
    post.shopId = shopId;
    post.productDetails = productDetails;
    post.imageComposition = imageComposition;
    post.images = postImages;
    post.lastEdited = 1;
    post.userDetails.shopImage = selectedShop?.shopImage || '';
    post.userDetails.shopName = selectedShop?.name || '';
    post.taggedUsers = taggedUsers;
  }
};

export const checkTaggedUsers = (text: string, taggedUsers: string[]) => {
  return taggedUsers.filter((taggedUser) => text.includes(`@${taggedUser}`));
};

export const parsePostAction = (action: IPostAction | null, profileOwnerUsername: string) => {
  if (!action) return null;

  const username = action.type === PostActionType.Mention ? profileOwnerUsername : action.username;

  return {
    username,
    totalUsers: action.totalUsers,
    type: action.type,
  };
};

export const resetFeedProps = (feed: IFeedState) => {
  feed.mapPostIdToSubPosts = {};
  feed.selectedCommentId = null;
  feed.selectedReplyId = null;
  feed.selectedSubPostId = null;
  feed.createPostDetails = {
    shops: [],
    isCatalogLoading: false,
    isLoading: false,
  };
  feed.profile = null;
  feed.selectedPostId = null;
};

export const getUserDetails = (token: string) => {
  const userId = getUserId(token) || '';

  return {
    userId,
    avatar: getAvatar(token) || getDefaultAvatar(userId),
    username: getUsername(token) || '',
    fullName: getFullName(token) || '',
    isPremium: getIsPremium(token),
  };
};

export const toCommentItem = (
  commentId: string,
  text: string,
  taggedUsers: ITaggedUser[],
  image: string | null,
  token: string,
): IComment => {
  const { userId, avatar, username, fullName, isPremium } = getUserDetails(token);

  return {
    id: commentId,
    likes: { list: null, paginationToken: null, hasMore: true },
    totalLikes: 0,
    totalReplies: 0,
    isLiked: false,
    isRepliesExpanded: false,
    replies: [],
    text,
    image,
    taggedUsers,
    date: Date.now(),
    userId,
    username,
    fullName,
    avatar,
    isPremium,
    isDeleted: false,
    isVisionSeller: false,
  };
};
interface IParsedFeedResponse {
  staticItems: IStaticItem[];
  feedItems: IFeedPost[];
}

export const parseDiscoverResponse = (
  data: DiscoverPageResult,
  token?: string,
): IParsedFeedResponse => {
  const staticItems = parseStaticItems(data.staticItems);

  const parsedMojos = getMojoFeedItems(data.mojos);

  const result = getModularFeedItems(data.feedItems, token || '');

  const feedItems = [...result, ...parsedMojos];

  return { feedItems, staticItems };
};

export const parseMainFeedResponse = (
  data: TedoooPageResponse,
  token?: string,
  selectedFeedBubble?: IFeedBubble,
): IParsedFeedResponse => {
  const dataFeedItems = [] as FeedItem2[];

  const dataStaticItems = [] as TedoooPageStatic[];

  const items = data.items as TedoooPageResponseItem[];

  items.forEach(({ item }) => {
    if (item.__typename === FeedItemTypeName.FeedItem2) {
      dataFeedItems.push(item);
    } else if (item.__typename === FeedItemTypeName.TedoooPageStatic) {
      dataStaticItems.push(item);
    }
  });

  const staticItems = parseFeedMainPageStaticItems(dataStaticItems);

  const feedItems = getModularFeedItems(dataFeedItems, token || '', selectedFeedBubble);

  return { feedItems, staticItems };
};

export const getPaginationToken = (
  paginationToken: string | null,
  discussionToken?: string,
  selectedFeedBubble?: IFeedBubble,
): string | null => {
  if (discussionToken) {
    // Use discussionToken if provided for getFilteredPosts
    return discussionToken;
  }

  if (!paginationToken && selectedFeedBubble?.id !== ALL) {
    // If no pagination token exists and a filter bubble is selected - create pagination token for getFilteredPosts
    return `1:${selectedFeedBubble?.id}`;
  }

  // Use the existing pagination token for getPosts
  return paginationToken;
};
