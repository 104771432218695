import { createAsyncThunk } from '@reduxjs/toolkit';

import { FeedReducer } from './FeedReducer';
import { DiscoverListsId, IFeedBubble, IStore2 } from 'types';
import { ALL } from 'pages/feed/feedBubblesCarousel/helper';
import { getPaginationToken } from './helper';

const reducer = new FeedReducer();

export const getUserFeed = createAsyncThunk(
  'async/getUserFeed',
  async (
    input: {
      token?: string;
      userId: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token, userId } = input;

    const paginationToken = state.feed.userFeed.paginationToken || `1:${userId}`;

    return reducer.getPostsByListsId(DiscoverListsId.UserFeed, paginationToken, token);
  },
);

export const getShopFeed = createAsyncThunk(
  'async/getShopFeed',
  async (
    input: {
      token?: string;
      userId: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token, userId } = input;

    const paginationToken = state.feed.shopFeed.paginationToken || `1:${userId}`;

    return reducer.getPostsByListsId(DiscoverListsId.UserFeed, paginationToken, token);
  },
);

export const getMainFeed = createAsyncThunk(
  'async/getFeed',
  async (
    input: {
      token?: string;
      selectedFeedBubble?: IFeedBubble;
      discussionToken?: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token, selectedFeedBubble, discussionToken } = input;

    const paginationToken = getPaginationToken(
      state.feed.mainFeed.paginationToken,
      discussionToken,
      selectedFeedBubble,
    );

    const isFilteredContent = selectedFeedBubble?.id !== ALL;

    if (isFilteredContent) {
      return (await reducer.getFilteredPosts(paginationToken, token)).page;
    }

    return reducer.getPosts(paginationToken, token);
  },
);

export const getUserShops = createAsyncThunk('async/getUserShops', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token } = state.user;

  return reducer.getShops(token || '');
});

export const getUserProfile = createAsyncThunk(
  'async/getUserProfile',
  async (input: { userId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { userId } = input;

    return reducer.getUserProfile(userId, token || '');
  },
);

export const getShopProfile = createAsyncThunk(
  'async/getShopProfile',
  async (input: { shopId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { shopId } = input;

    return reducer.getShopProfile(shopId, token || '');
  },
);
