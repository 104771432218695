import { FC, useCallback } from 'react';

import Comment from '../comment/Comment';
import { IReplies, ITaggedUser } from 'types';
import { useTedoooSelector } from 'store/Store';
import { UserSelector } from 'store/user/UserSelector';
import { translations } from 'translations/en';

const Replies: FC<IReplies> = ({
  replies,
  totalReplies,
  commentId,
  postOwnerId,
  onRepliesClick,
  onReplyClick,
  onCommentDelete,
  onUserClick,
  onCommentLike,
  onCommentLikesClick,
  isRepliesExpanded,
}) => {
  const viewReplyText = isRepliesExpanded
    ? `View ${totalReplies - replies.length} more replies`
    : `View ${totalReplies} replies`;

  const text =
    isRepliesExpanded && totalReplies === replies.length
      ? translations.FEED.POST.HIDE_REPLIES
      : viewReplyText;

  const loggedInUserId = useTedoooSelector(UserSelector.loggedUserIdSelector);

  const onReplyDelete = (replyId: string) => onCommentDelete(commentId, replyId);

  const onReplyUserClick = (id: string, tag: ITaggedUser) => {
    onReplyClick(id, tag);
  };

  const onShowRepliesClick = (e: any) => {
    e.stopPropagation();
    onRepliesClick(commentId);
  };

  const renderReplies = useCallback(() => {
    if (!replies) {
      return null;
    }

    const onLikesClick = (replyId: string) => {
      onCommentLikesClick(commentId, replyId);
    };

    const onLikeComment = (replyId: string, isLiked: boolean) => {
      onCommentLike(commentId, isLiked, replyId);
    };

    return replies.map((reply, key) => {
      const isPostOwner = postOwnerId === reply.userId;

      const isCommentOwner = reply.userId === loggedInUserId;

      return (
        <Comment
          taggedUsers={reply.taggedUsers}
          onUserClick={onUserClick}
          isRepliesExpanded={reply.isRepliesExpanded}
          isCommentOwner={isCommentOwner}
          isPostOwner={isPostOwner}
          isReply
          isDeleted={false}
          onCommentDelete={onReplyDelete}
          onReplyClick={() =>
            onReplyUserClick(reply.id, { username: reply.username, userId: reply.userId })
          }
          replies={[]}
          id={reply.id}
          image={reply.image}
          userId={reply.userId}
          totalReplies={reply.totalReplies}
          key={`${reply.username}-${key}`}
          avatar={reply.avatar}
          username={reply.username}
          fullName={reply.fullName}
          isPremium={reply.isPremium}
          date={reply.date}
          text={reply.text}
          totalLikes={reply.totalLikes}
          isLiked={reply.isLiked}
          onCommentLike={onLikeComment}
          likes={{ list: null, paginationToken: null, hasMore: false }}
          onCommentLikesClick={onLikesClick}
          isVisionSeller={reply.isVisionSeller}
        />
      );
    });
  }, [replies]);

  return (
    <div className="replies-container">
      {totalReplies > 0 && (
        <div className="reply-text" onClick={onShowRepliesClick}>
          <div className="line" />
          <span>{text}</span>
        </div>
      )}
      {isRepliesExpanded && renderReplies()}
    </div>
  );
};

export default Replies;
