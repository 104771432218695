import { FC, useEffect, useState } from 'react';
import './mojo.scss';
import { Button, ClickableImage, Popover } from 'tedooo-web-design-system/ui';
import {
  BellIcon,
  BellOff,
  FilledHeartIcon,
  HeartIcon,
  Share,
  ShoppingCart,
  SquareMessageIcon,
} from 'tedooo-web-design-system/icons';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { IMojoProps, IStore2, ITaggedUser, popupIdsOptions } from 'types';
import { Avatar, Image, Span } from '../helpers';
import MojoVideoPlayer from './videoPlayer/MojoVideoPlayer';
import {
  checkSmallScreen,
  getElementById,
  getShortRelativeTime,
  isEmptyArray,
  toCurrency,
} from 'utils';
import { translations } from 'translations/en';
import { MojosSlicer } from 'store/mojos/MojosSlicer';
import ExpandableText from '../expandableText/ExpandableText';
import MojoComments from './mojoComments/MojoComments';
import { UserSelector } from 'store/user/UserSelector';
import { PopupsSrv } from 'services/PopupsSrv';
import UserDetailsBadges from 'components/post/header/userDetails/badges/UserDetailsBadges';

const Mojo: FC<IMojoProps> = ({
  id,
  video,
  thumbnail,
  totalLikes,
  isLiked,
  isMuted,
  isDisplayed,
  productDetails,
  text,
  loggedInUserAvatar,
  isClickOutsideCommentsEnabled,
  comments,
  totalComments,
  loggedInUserId,
  userDetails,
  canPause,
  onMuteToggle,
  onLikeToggle,
  onUserClick,
  onShareClick,
  onProductClick,
  onVideoEnd,
  onLoadComments,
  onLoadReplies,
  onCommentLikeToggle,
  onCommentDelete,
  onCommentSubmit,
  onReplySubmit,
  onToggleReplies,
  onLikesClick,
  onFollow,
  onClick,
}) => {
  const isMobile = checkSmallScreen();

  const [isFollowing, setIsFollowing] = useState(!!userDetails?.isFollowing);

  const [isCommentsVisible, setIsCommentsVisible] = useState(false);

  useEffect(() => {
    if (!isDisplayed) {
      setIsFollowing(!!userDetails?.isFollowing);
    }
  }, [userDetails?.isFollowing]);

  const followButtonText = userDetails?.isFollowing
    ? translations.COMMON.FOLLOWING
    : translations.COMMON.FOLLOW;

  const muteIcon = isMuted ? <BellOff /> : <BellIcon />;

  const likeIcon = isLiked ? <FilledHeartIcon /> : <HeartIcon />;

  const formattedPrice = productDetails
    ? toCurrency(productDetails.price.value, productDetails.price.currencyCode, 2)
    : '';

  const onMuteClick = (e: any) => {
    e.stopPropagation();
    onMuteToggle();
  };

  const onFollowClick = (e: any) => {
    e.stopPropagation();
    if (userDetails) {
      onFollow(userDetails.userId, userDetails.isFollowing);
    }
  };

  const onVideoFinish = (duration: number, percentage: number) => {
    onVideoEnd(id, duration, percentage);
  };

  const onComment = (
    comment: string,
    taggedUsers: ITaggedUser[],
    attachedImages: Array<string>,
  ) => {
    onCommentSubmit(id, comment, taggedUsers, attachedImages);
  };

  const onReply = (
    commentId: string,
    comment: string,
    taggedUsers: ITaggedUser[],
    attachedImages: Array<string>,
  ) => {
    onReplySubmit(id, commentId, comment, taggedUsers, attachedImages);
  };

  const onLikeCommentToggle = (commentId: string, isCommentLiked: boolean, replyId?: string) => {
    onCommentLikeToggle(id, commentId, isCommentLiked, replyId);
  };

  const onReplyToggle = (commentId: string) => {
    onToggleReplies(id, commentId);
  };

  const onGetReplies = (commentId: string) => {
    onLoadReplies(id, commentId);
  };

  const onCommentsVisibleChange = () => {
    if (isClickOutsideCommentsEnabled) {
      setIsCommentsVisible(!isCommentsVisible);
    }
  };

  const renderComments = () => {
    return (
      <MojoComments
        avatar={loggedInUserAvatar}
        comments={comments.list}
        shouldDisplayEmptyState
        hasMore={!!comments.paginationToken}
        isExpanded
        isUserSingIn={!!loggedInUserId}
        totalComments={totalComments}
        postOwnerId={userDetails?.userId || ''}
        onToggleRepliesClick={onReplyToggle}
        onCommentSubmit={onComment}
        onLoadMore={onLoadComments}
        onUserClick={onUserClick}
        onRepliesLoad={onGetReplies}
        onReply={onReply}
        onCommentLike={onLikeCommentToggle}
        onCommentDelete={onCommentDelete}
        onCommentLikesClick={(commentId) => onLikesClick(id, commentId)}
        onClose={onCommentsVisibleChange}
        onViewMoreCommentsClick={() => {}}
      />
    );
  };

  const getPopoverContainer = () => getElementById('mojos-carousel') as HTMLElement;

  return (
    <div className="mojo-container" onClick={onClick}>
      <div className="text-overlay" />
      <MojoVideoPlayer
        video={video}
        isMuted={isMuted}
        thumbnail={thumbnail}
        canPause={canPause}
        isDisplayed={isDisplayed}
        onVideoEnd={onVideoFinish}
      />
      <div className="details-wrapper">
        <div className="left-side">
          {userDetails && (
            <div className="mojo-user-details-container">
              <UserDetailsBadges
                isPremium={userDetails.isPremium}
                isVisionSeller={userDetails.isVisionSeller}
                size="medium"
              />
              <div className="details-container">
                <Avatar src={userDetails.avatar} size={40} />
                <div className="names-container">
                  <span className="title" onClick={() => onUserClick(userDetails.userId)}>
                    {userDetails.fullName}
                  </span>
                  <span className="description">
                    @{userDetails.username} · {getShortRelativeTime(userDetails.date)}
                  </span>
                </div>
                {!isFollowing && (
                  <Button
                    type="secondary"
                    size="small"
                    borderRadius="large"
                    text={followButtonText}
                    onClick={onFollowClick}
                    className={classNames({ following: userDetails.isFollowing })}
                  />
                )}
              </div>
              {text && <ExpandableText text={text} maxChars={80} isCollapsable isTextClickable />}
            </div>
          )}
        </div>
        <div className="right-side">
          <ClickableImage icon={muteIcon} onClick={onMuteClick} className="mute-button" />
          <div className="action-buttons-container">
            <div className="likes-container">
              <ClickableImage
                icon={likeIcon}
                onClick={onLikeToggle}
                className={classNames({ 'is-liked': isLiked })}
              />
              {totalLikes > 0 && <Span text={totalLikes} />}
            </div>
            <div className="likes-container">
              <Popover
                placement="rightBottom"
                trigger="click"
                showArrow
                visible={isCommentsVisible}
                onVisibleChange={onCommentsVisibleChange}
                overlayClassName="mojo-comments-popover"
                getPopupContainer={getPopoverContainer}
                content={renderComments()}
              >
                <ClickableImage icon={<SquareMessageIcon />} />
              </Popover>
              {totalComments > 0 && <Span text={totalComments} />}
            </div>
            <ClickableImage icon={<Share />} onClick={onShareClick} />
            {productDetails && (
              <>
                <ClickableImage icon={<ShoppingCart />} onClick={onProductClick} />
                <div className="mojo-product-container" onClick={onProductClick}>
                  <Image src={productDetails.image} />
                  <Span text={formattedPrice} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isCommentsVisible && isMobile && renderComments()}
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const loggedInUserAvatar = UserSelector.defaultAvatarSelector(state);

  const loggedInUserId = UserSelector.loggedUserIdSelector(state);

  const displayedPopups = state.popup.ids;

  const isClickOutsideCommentsEnabled =
    isEmptyArray(displayedPopups) ||
    (displayedPopups.length === 1 &&
      PopupsSrv.checkIfDisplayed(popupIdsOptions.mojosPopup, displayedPopups));

  const { isMuted, sessionId } = state.mojos;

  return { isMuted, sessionId, loggedInUserAvatar, isClickOutsideCommentsEnabled, loggedInUserId };
};

const mapDispatchToProps = {
  onMuteToggle: MojosSlicer.actions.toggleMute,
};

export default connect(mapStateToProps, mapDispatchToProps)(Mojo);
