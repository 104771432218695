import { FILTER_PARAMS } from 'constant';

export const getFilterPath = (filterId, filterText, discussionToken) => {
  let path = `/?${FILTER_PARAMS.FILTER}=${encodeURIComponent(filterId)}&${
    FILTER_PARAMS.BUBBLE_FILTER_TEXT
  }=${encodeURIComponent(filterText)}`;

  if (discussionToken) {
    path += `&${FILTER_PARAMS.DISCUSSION_TOKEN}=${encodeURIComponent(discussionToken)}`;
  }

  return path;
};
