import { FC, useEffect } from 'react';
import './followShopsStep.scss';
import { Button } from 'tedooo-web-design-system/ui';

import { translations } from 'translations/en';
import { IFollowChannelStep, IOnboardingChannel } from 'types';
import FollowChannelCard from './FollowChannelCard/FollowChannelCard';
import FollowChannelSkeleton from 'components/skeletons/followChannelsSkeleton/FollowChannelSkeleton';

const FollowChanneslStep: FC<IFollowChannelStep> = ({
  channels,
  onChannelClick,
  onBackClick,
  onContinueClick,
  getChannels,
}) => {
  useEffect(() => {
    (() => {
      if (!channels.length) {
        getChannels();
      }
    })();
  }, []);

  const list = channels?.length ? channels : [null, null, null, null];

  const isButtonDisabled = !channels;

  const itemContent = (channel: IOnboardingChannel | null, index: number) => {
    if (!channel) {
      return <FollowChannelSkeleton key={index} />;
    }

    const onClick = () => onChannelClick({ channelId: channel.id });

    return <FollowChannelCard key={channel.id} channelDetails={channel} onClick={onClick} />;
  };

  return (
    <div className="follow-shops-container step-container">
      <div className="text-container">
        <span className="title">{translations.ONBOARDING.FOLLOW_CHANNELS_STEPS.TITLE}</span>
        <span className="description">
          {translations.ONBOARDING.FOLLOW_CHANNELS_STEPS.DESCRIPTION}
        </span>
      </div>
      <div className="content-container">
        {list.map((channel, index) => itemContent(channel, index))}
      </div>
      <div className="buttons-container">
        <Button
          isFullWidth
          type="primary"
          size="large"
          borderRadius="large"
          disabled={isButtonDisabled}
          text={translations.COMMON.CONTINUE}
          className="continue-button-interests"
          onClick={onContinueClick}
        />
        <Button
          isFullWidth
          type="secondary"
          size="large"
          borderRadius="large"
          text={translations.COMMON.BACK}
          className="back-button-interests"
          onClick={onBackClick}
        />
      </div>
    </div>
  );
};

export default FollowChanneslStep;
